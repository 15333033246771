import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'lodash';
import { Observable } from 'rxjs';
import { isEmpty } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { activateContract } from './../models/activateContract';
import { ContractByIdResponse } from "../models/ContractByIdResponse";
import { ContractNote } from "../models/ContractNote";
import { ContractNoteResponse } from "../models/ContractNoteResponse";
import { ContractNotesById } from "../models/ContractNotesById";
import { ContractResponse } from "../models/ContractResponse";
import { ContractSearchCriteria } from "../models/ContractSearchCriteria";
import { ContractSearchResponse } from "../models/ContractSearchResponse";
import { Pagination } from "../models/Pagination";
import { GlobalFilterService } from "./global-filter.service";

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private readonly Timeout: number = 30000;
  apiRoot: string;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService) {
    this.apiRoot = environment.cmApiRoot;
  }

  public getContracts(
    pagination: Pagination,
    NetworkName?: string,
    ContractTypeIds?: number[],
    ContractStatusId?: number[],
    ContractStartDate?: Date,
    ContractEndDate?: Date,
    PayerName?: string
  ): Observable<ContractSearchResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = pagination.buildSearchParams();

    var searchCriteria = new ContractSearchCriteria();
    if (NetworkName != "") {
      searchCriteria.NetworkName = NetworkName;
    }
    if (ContractTypeIds != null && ContractTypeIds != undefined && ContractTypeIds.length > 0) {
      searchCriteria.ContractTypeIds = ContractTypeIds;
    }
    if (PayerName != null && PayerName != undefined && PayerName != "") {
      searchCriteria.PayerGroupName = [PayerName];
    }

    if (ContractStatusId != null && ContractStatusId != undefined && ContractStatusId.length > 0) {
      searchCriteria.ContractStatusIds = ContractStatusId;
    }


    if (ContractStartDate != null && ContractStartDate != undefined) {
      searchCriteria.DateRangeFrom = ContractStartDate;
    }
    if (ContractEndDate != null && ContractEndDate != undefined) {
      searchCriteria.DateRangeTo = ContractEndDate;
    }

    return this.http.post<ContractSearchResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/Search`, searchCriteria, { params });
  }

  public getContract(
    ContractId?: number
  ): Observable<ContractByIdResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<ContractByIdResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}`, { headers: headers });
  }

  public getContractNotes(
    pagination: Pagination,
    ContractId?: number
  ): Observable<ContractNoteResponse> {
    const params = pagination.buildSearchParams();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<ContractNoteResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contractnotes/${ContractId}`, { params });
  }

  public addContractNotes(
    ContractId: number,
    Note: string
  ): Observable<ContractNote> {
    var body = new ContractNote();
    body.ContractId = ContractId;
    body.note = Note;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<ContractNote>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contractnotes`, body, { headers: headers });
  }

  public updateContractById(
    contract,
    ContractId?: number
  ): Observable<ContractByIdResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<ContractByIdResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts?contractId=${ContractId}`, contract, { headers: headers });

  }

  public activateContractById(
    activateContract,
    ContractId?: number
  ): Observable<ContractByIdResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<ContractByIdResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}`, activateContract, { headers: headers });

  }


  public addContract(
    contract
  ): Observable<ContractResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<ContractResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts`, contract, { headers: headers });

  }

  public createContractFromTemplate(templateId: number, networkName: string): Observable<ContractByIdResponse> {
    return this.http.post<ContractByIdResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/addcontractfromtemplate?templateId=${templateId}&networkName=${networkName}`, null);
  }

}
