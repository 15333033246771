import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContractDocumentService } from 'src/app/services/contractDocumentService';
import { ContractFactorService } from 'src/app/services/contractFactorService';

import { ContractStatus } from '../../../models/ContractStatus';
import { FactorsgpiGridComponent } from './../contract-factors/factorsgpi-grid/factorsgpi-grid.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [FactorsgpiGridComponent]
})
export class FileUploadComponent implements OnInit {
  files: any[] = [];
  uploadDisabled: boolean = true;
  isLoadingResults: boolean = false;
  buttonName = 'Upload';
  maxFileSize: number = 0;
  maxNumberOfFiles: number = 1;
  acceptedFileTypes: string[] = [];
  message: string = "";
  topMessage: string = "";
  topMessageStyle: string = "top-message";
  errorMessage: string = "";
  contractId: number;
  checkForDuplicateNames: boolean = false;
  factorId: number;
  isExclude = false;
  errormessage: string = "";
  validateError: string = "";
  validationErrorMessage: string = "";
  fileUploadComponent: string = "";
  hasFileDragAndDropError: boolean = false;
  gpiCodes: string[] = [];
  progressInterval: any;
  duplicateFileNameUploaded: boolean = false;
  duplicateFileNameMessage: string = "Make certain you are not uploading a file already stored under the same file name."

  isSmallerScreenSize = false;

  constructor(private contractDocumentService: ContractDocumentService,
    private responsive: BreakpointObserver,
    private contractFactorService: ContractFactorService) {

  }

  @ViewChild('fileDropRef', { static: false }) fileInput: ElementRef;
  @Output() EditOnClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() canAddEdit: boolean = true;
  @Input() set data(value) {
    if (value == null || value == undefined) {
      return;
    }

    this.fileUploadComponent = value.fileUploadComponent;
    this.contractId = value.contractId;
    this.maxFileSize = value.maxFileSize;
    this.acceptedFileTypes = value.acceptedFileTypes;
    this.message = value.message;
    this.topMessage = value.topMessage;
    this.maxNumberOfFiles = value.maxNumberOfFiles;
    this.errorMessage = value.errorMessage;
    this.checkForDuplicateNames = value.checkForDuplicateNames;
    this.duplicateFileNameUploaded = false;

    if (this.fileUploadComponent === 'GPIUpload') {
      this.factorId = value.factorId;
      this.isExclude = value.isExclude;
      this.buttonName = 'Import';
    }

    if (this.fileUploadComponent === 'DetailsUpload' || this.fileUploadComponent === 'TemplateDetailsUpload') {
      this.topMessageStyle = "top-message-small";
    }
  }

  ngOnInit() {
    this.responsive.observe('(min-width: 960px) and (max-width: 1470px)')
    .subscribe(result => {

      if (result.matches) {
        this.isSmallerScreenSize = true;
      } else {
        this.isSmallerScreenSize = false;
      }

  });
  }

  onFileDropped($event) {
    if (this.isFileTypeAndUnder20MB($event)) {
      this.prepareFilesList($event);
    }
  }

  isFileTypeAndUnder20MB(files: Array<any>): boolean {
    this.EditOnClick.emit({ status: false, gpi: [] });
    if (files && files.length > this.maxNumberOfFiles) {
      this.hasFileDragAndDropError = true;
      return false;
    }
    for (const item of files) {
      if (!this.acceptedFileTypes.includes(item.type) || item.size > this.maxFileSize) {
        this.hasFileDragAndDropError = true;
        return false;
      }
    }

    if (this.checkForDuplicateNames) {
      var currentFiles = this.files.map(function (item) { return item.name });
      var isDuplicate = currentFiles.some(function (item, idx) {
        return currentFiles.indexOf(item) != idx
      });
      if (isDuplicate) {
        this.hasFileDragAndDropError = true;
        return false;
      }

      var dragAndDropFiles: any[] = []
      for (let file of files) {
        dragAndDropFiles.push(file.name);
      }

      const output = currentFiles.filter(function (obj) {
        return dragAndDropFiles.indexOf(obj) !== -1;
      });

      if (output && output.length > 0) {
        this.hasFileDragAndDropError = true;
        return false;
      }
    }

    if (this.files.length >= this.maxNumberOfFiles) {
      this.hasFileDragAndDropError = true;
      return false;
    }
    this.hasFileDragAndDropError = false;
    return true;
  }

  fileBrowseHandler(files: any) {
    if (this.isFileTypeAndUnder20MB(files)) {
      this.prepareFilesList(files);
    }

  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.fileInput.nativeElement.value = null;
    clearInterval(this.progressInterval);
    if (this.files.length < 1) {
      this.uploadDisabled = true;
      this.duplicateFileNameUploaded = false;
      this.files = [];
      return;
    }
    if (this.files.length > this.maxNumberOfFiles) {
      this.hasFileDragAndDropError = true;
      this.uploadDisabled = true;
      return;
    }
    this.duplicateFileNameUploaded = false;
    this.uploadDisabled = false;
    this.hasFileDragAndDropError = false;

  }


  deleteFileMidstream(index: number) {
    this.files.splice(index, 1);
    if (this.files.length < 1) {
      this.uploadDisabled = true;
      this.files = [];
      return;
    }
    if (this.files.length > this.maxNumberOfFiles) {
      this.uploadDisabled = true;
      return;
    }
  }

  uploadFilesSimulator(index: number) {
    if (this.files.length > 0) {
      setTimeout(() => {
        if (index === this.files.length) {
          return;
        } else {
          this.progressInterval = setInterval(() => {
            if (this.files.length < 1) {
              clearInterval(this.progressInterval);
            }
            if (this.files[index].progress && this.files[index].progress === 100) {
              clearInterval(this.progressInterval);
              this.uploadFilesSimulator(index + 1);
            } else {
              this.files[index].progress += 5;
            }
          }, 200);
        }
      }, 1000);
    }

  }


  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      if (this.files.length > 0 && this.files.length < (this.maxNumberOfFiles + 1)) {
        this.hasFileDragAndDropError = false;
        this.duplicateFileNameUploaded = false;
        this.uploadDisabled = false;
      } else {
        this.uploadDisabled = true;
      }
    }
    this.uploadFilesSimulator(0);
  }


  uploadFiles(files: any[]): void {

    var totalFileCount = files.length;
    var uploadedFileCount = 0;
    this.hasFileDragAndDropError = false;
    clearInterval(this.progressInterval);

    // check if the upload action is grom gpi-grid component
    if (this.fileUploadComponent === 'GPIUpload') {

      this.isLoadingResults = false;
      let file: File = files[0];
      let reader: FileReader = new FileReader();
      //read the complete csv file
      reader.readAsText(file);

      // split the content in the file and add to the correct array
      reader.onload = (e) => {
        let csv: any = reader.result;
        let allTextLines = [];
        allTextLines = csv.split(/\r\n/);

        let arrl = allTextLines.length;
        let rows: string[] = [];
        let start: number = 0;
        const regex = /^[0-9]+$/i;

        if (allTextLines.length > 0) {
          start = allTextLines[0].trim().match(regex) == null ? 1 : 0;
        }
        for (let i = start; i < arrl; i++) {
          if (allTextLines[i].trim()) {
            rows.push(allTextLines[i]);

          }
        }

        this.files = [];
        this.uploadDisabled = true;
        // validate gpis
        // this.factorsgpiGridComponent.validategpicodes(rows);
        // this.factorsgpiGridComponent.saveGpis(this.isExclude, this.contractId, this.factorId);
        // this.factorsgpiGridComponent.loadContractGpis();

        this.EditOnClick.emit({ status: true, gpi: rows });


      }
    }
    else if (this.fileUploadComponent === 'DetailsUpload') {

      this.isLoadingResults = false;
      let file: File = files[0];
      let reader: FileReader = new FileReader();
      //read the complete csv file
      reader.readAsText(file);

      // split the content in the file and add to the correct array
      reader.onload = (e) => {
        let csv: any = reader.result;
        let allTextLines = [];
        allTextLines = csv.split(/\r\n/);

        let arrl = allTextLines.length;
        let rows: string[] = [];
        let start: number = 1;

        if (allTextLines.length > 0) {
          for (let i = start; i < arrl; i++) {
            if (allTextLines[i].trim()) {
              rows.push(allTextLines[i]);
            }
          }
        }

        this.files = [];
        this.uploadDisabled = true;

        this.EditOnClick.emit({ status: true, contractDetails: rows });
      }
    }
    else if (this.fileUploadComponent === 'TemplateDetailsUpload') {

      this.isLoadingResults = false;
      let file: File = files[0];
      let reader: FileReader = new FileReader();
      //read the complete csv file
      reader.readAsText(file);

      // split the content in the file and add to the correct array
      reader.onload = (e) => {
        let csv: any = reader.result;
        let allTextLines = [];
        allTextLines = csv.split(/\r\n/);

        let arrl = allTextLines.length;
        let rows: string[] = [];
        let start: number = 1;

        if (allTextLines.length > 0) {
          for (let i = start; i < arrl; i++) {
            if (allTextLines[i].trim()) {
              rows.push(allTextLines[i]);
            }
          }
        }

        this.files = [];
        this.uploadDisabled = true;

        this.EditOnClick.emit({ status: true, templateDetails: rows });
      }
    }
    // check if the upload action is from Document component
    else if (this.fileUploadComponent === 'DocumentUpload') {
      var stopLoop = false;
      // handle duplicates
      for (let file of files) {
        if (stopLoop) {
          break;
        }
        const formData = new FormData();
        formData.append('formFile', file, file.name);
        this.contractDocumentService.addContractDocument(formData, this.contractId).subscribe(
          (results) => {
            uploadedFileCount++;
            var index = this.findWithAttr(this.files, 'name', file.name);
            this.deleteFileMidstream(index);
            toastr.success('File ' + file.name + ' was uploaded successfully');
            if (uploadedFileCount == totalFileCount) {
              this.files = [];
              this.uploadDisabled = true;
              this.isLoadingResults = false;
              this.EditOnClick.emit("success");
            }
          },
          (err) => {
            this.isLoadingResults = false;
            var isDuplicateError = err.error.ErrorType === 'DuplicateNameException';
            if (this.checkForDuplicateNames && isDuplicateError) {
              this.EditOnClick.emit("error");
              this.duplicateFileNameUploaded = true;
              stopLoop = true;
            }
            if (!isDuplicateError) {
              toastr.error('We are unable to upload a document at this time');
            }
          }
        );
      };
    }


    this.fileInput.nativeElement.value = null;

  }

  findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }



  formatBytes(bytes) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
