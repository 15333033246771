import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ContractRateType } from '../models/ContractRateType';
import { ContractRateTypeResponse } from '../models/ContractRateTypeResponse';
import { SelectItem } from '../models/SelectItem';

@Injectable({
  providedIn: 'root',
})
export class ContractRateTypeService {
  apiRoot: string;
  statuses: SelectItem[] = [];

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.apiRoot = environment.cmApiRoot;
  }

  public getContractRateTypes(): Observable<ContractRateType[]> {

    return this.http.get<ContractRateType[]>(`${this.apiRoot}/api/ContractRateTypes`);
  }


}
