import { AuthorizationService } from 'src/app/services/authorization.service';

export class ManageRateToolbarHelper {
  public showAction: string;
  public canAddEdit: boolean = true;
  public currentToolbar = [];
  private readonly TOOLBAR_ITEMS = [
    {
      feature: 'ContractManagement::AddEditContract',
      item: {
        label: 'Add Rate',
        name: 'addRate',
        tip: 'Add Rate',
        icon: '#inm-icon__plus-square',
        disabled: !this.canAddEdit,
        show: true,
      },
    },
    {
      feature: 'ContractManagement::AddEditContract',
      item: {
        label: 'Delete Rate',
        name: 'deleteRate',
        tip: 'Delete Rate',
        icon: '#inm-icon__trash',
        disabled: !this.canAddEdit,
        show: true,
      },
    },
  ];

  constructor(private authService: AuthorizationService) {
    this.generateToolbarData();
  }

  generateToolbarData(): void {
    for (const toolbar of this.TOOLBAR_ITEMS) {
      if (!toolbar.feature) {
        this.currentToolbar.push(toolbar.item);
        continue;
      }
      if (toolbar.feature && this.authService.hasFeature(toolbar.feature)) {
        this.currentToolbar.push(toolbar.item);
      }
    }
  }

  addEnable() {
    this.TOOLBAR_ITEMS[0].item.disabled = false;
  }

  addDisable() {
    this.TOOLBAR_ITEMS[0].item.disabled = true;
  }

  deleteEnable() {
    this.TOOLBAR_ITEMS[1].item.disabled = false;
  }

  deleteDisable() {
    this.TOOLBAR_ITEMS[1].item.disabled = true;
  }

  enableToolbar() {
    this.addEnable();
    this.deleteEnable();
  }

  disableToolbar() {
    this.addDisable();
    this.deleteDisable();
  }
}
