<h3 mat-dialog-title class="inm-headline">&nbsp;&nbsp;{{data.title}}</h3>
<div mat-dialog-content class="zero-margin-right">
  <!-- <h3 class="inm-headline">{{data.title}}</h3> -->
  <div fxLayout="column" fxLayoutAlign="space-around center">
    <div class="float-child">
      <form [formGroup]="templateForm" [style.fontSize.px]="14" class="content form-content">
        <div>
          <mat-form-field style="width: 392px;" appearance="outline" color="accent" >
            <mat-label>Network Name</mat-label>
            <input maxlength="50" (input)="checkSaveEnabled()" title="Network Name" matInput formControlName="networkNameCtrl" type="string">
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>

  <section class='inm-actions--confirmation'>
    <button class="inm-button--action button-spacing" [disabled]="saveDisabled || isLoadingResults" (click)="saveContractFromTemplate()">Save</button>
    <button class="inm-button button-spacing" [disabled]="isLoadingResults" [mat-dialog-close]="true">Cancel</button>
  </section>
</div>


