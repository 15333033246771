import { Component, Input, ViewChild } from '@angular/core';
import { ManageContractsToolbarHelper } from 'src/app/components/contracts/manage/helper/manage-contracts-toolbar.helper';

import { IToolbarItem } from '../../table-toolbar/table-toolbar.component';
import { FactorsgpiGridComponent } from '../factorsgpi-grid/factorsgpi-grid.component';

@Component({
  selector: 'app-contractfactors',
  templateUrl: './contractfactors.component.html',
  styleUrls: ['./contractfactors.component.less']
})
export class ContractfactorsComponent {

  @ViewChild(FactorsgpiGridComponent) factorsgpiGridComponent:FactorsgpiGridComponent;
  @Input() canAddEdit: boolean = true;

  sendFactorId($event) {
    this.factorsgpiGridComponent.listenToFactorIdChanges($event)
  }
}
