export class ContractContractType {
  contractContractTypeId: number;
  contractId: number;
  contractTypeId: number;
  contractTypeDesc: string;

  constructor(contractId: number, contractTypeId: number, contractTypeDesc: string) {
    this.contractId = contractId;
    this.contractTypeId = contractTypeId;
    this.contractTypeDesc = contractTypeDesc;
  }
}
