<div class="float-container">
  <h1>Industry Contract Template Library</h1>
  <div class="content">
    <div class="float-child" class="full-width">
      <form [formGroup]="filterFormGroup" [style.fontSize.px]="14" class="content">
        <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Network Name</mat-label>
          <input title="Network Name" matInput formControlName="networkNameCtrl" type="string">
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Payer</mat-label>
          <mat-select panelClass="selectbox-panel" formControlName="payerIdCtrl">
            <input #matInputPayerSearch placeholder="Payer" matInput type="text"
              (keyup)="onKeyPayer($any($event.target).value)">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let payer of filteredPayers" [value]="payer">
              {{payer.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Contract Type</mat-label>
          <mat-select formControlName="contractTypesCtrl" multiple>
            <mat-option *ngFor="let contractType of contractTypes" [value]="contractType"
              [selected]="contractTypeList">
              {{contractType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div style="display: flex;">
    <div fxLayout="row" fxLayoutAlign="end end" class="full-width">
        <button mat-raised-button fxFlexAlign="center" fxFlexOffset="2" type="button"
          (click)="onSubmit()" color="accent"
          [disabled]="!atLeastOneValueSelected()">Search</button>
        <button mat-raised-button type="button" fxFlexOffset="2" color="accent"
          fxFlexAlign="center" [disabled]="!resetEnabled"
          (click)="onResetFilter()">Reset</button>
    </div>
  </div>
    <div>
      <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center"
          *ngIf="isLoadingResults">
        </mat-spinner>
      </div>
      <div>
        <section>
          <app-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" [canAddEdit]="canAddEdit"
            (OnClick)="toolbarBtnClick($event)"></app-toolbar>
          <table mat-table matSortDisabled="{{hasNoData}}" [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" multiTemplateDataRows>
            <ng-container matColumnDef="NetworkName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Network Name </th>
              <td mat-cell *matCellDef="let element"
                title="{{element.networkName}}">
                <div *ngIf="viewFeatures[0].accessable; else noLink">
                  <a class="anchor-link" style="cursor: pointer;" routerLink="managetemplates/{{element.industryContractTemplateId}}">
                    {{element.networkName}}
                  </a>
                </div>
                <ng-template #noLink>
                  <span>{{element.networkName}}</span>
                </ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="PayerGroupName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Payer
              </th>
              <td mat-cell *matCellDef="let element"> {{element.payerGroupName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="ContractType">
              <th mat-header-cell *matHeaderCellDef> Contract Type
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngFor = "let type of element.industryContractTemplateContractTypes; let i=index">
                  <span class="list-format" *ngIf="i>0 && i!=element.industryContractTemplateContractTypes.length">,</span>
                  {{type.contractType.name}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="TemplateDetails">
              <th colspan="4" mat-header-cell *matHeaderCellDef> Contract Details
              </th>
              <td colspan="4" mat-cell *matCellDef="let element"><span *ngIf="element.industryContractTemplateDetails == null || element.industryContractTemplateDetails.length == 0">No template details provided</span>
              <table class="simple-table" *ngIf="element.industryContractTemplateDetails != null && element.industryContractTemplateDetails.length != 0">
                    <thead class="simple-thead ">
                      <tr>
                        <th>BIN</th>
                        <th>PCN</th>
                        <th>GROUP #</th>
                        <th>NETWORK ID</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor = "let detail of $any(element.industryContractTemplateDetails | slice:0:3)">
                      <td> {{ detail.bin }} </td>
                      <td> {{ detail.pcn }} </td>
                      <td> {{ detail.groupNumber }} </td>
                      <td> {{ detail.networkId }}</td>
                    </tr>
                    <tr *ngIf="element.industryContractTemplateDetails.length > 3">
                      <td colspan="4"><a class="anchor-link" style="cursor: pointer;" routerLink="managetemplates/{{element.industryContractTemplateId}}">Show More</a></td>
                    </tr>
                  </tbody>
              </table>
              </td>
            </ng-container>
            <ng-container matColumnDef="Actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button class="inm-button" *ngIf="viewFeatures[1].accessable"
                  type="button" matTooltip="Add Contract From Template" (click)="createContractFromTemplateDialog(element)">
                  <svg class="inm-icon" aria-label="Add Contract From Template" >
                    <use xlink:href="#inm-icon__save">Add Contract From Template</use>
                  </svg>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="IndustryContractTemplateId">
              <th mat-header-cell [hidden]="true" *matHeaderCellDef
                mat-sort-header> Template Id </th>
              <td mat-cell [hidden]="true" *matCellDef="let element">
                {{element.industryContractTemplateId}}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator #paginator [length]="totalRecordCount" [pageIndex]="pageIndex"
            [pageSize]="30" [pageSizeOptions]="[5,10,15,30]" showFirstLastButtons
            (page)="onPageChange($event)">
          </mat-paginator>
        </section>
      </div>
    </div>
</div>
