<mat-toolbar>
  <div class="ui-toolbar-group-left" *ngFor="let item of toolbarItemList;let i = index">
    <button mat-button *ngIf="item.show && !item.fileLink && !item.dropdownItems"
      [disabled]="item.disabled" type="button" matTooltip="{{item.tip}}"
      (click)="toolbarBtnClick(item.name, i)">
      <svg class="inm-icon" aria-label="item.tip" *ngIf="item.icon">
        <use [attr.xlink:href]="item.icon">{{item.label}}</use>
      </svg>
      <div *ngIf="!item.icon">
        {{item.label}}
      </div>
    </button>
    <div mat-button *ngIf="item.show && item.dropdownItems">
      <mat-form-field [floatLabel]="">
        <mat-select (selectionChange)="optionWasSelected($event.value)"
          placeholder="Select Action">
          <mat-option *ngFor="let element of item.dropdownItems" [value]="element">
            {{element}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="item.show && item.fileLink && !item.dropdownItems">
      <a mat-button href="'../../../../{{item.fileLink}}" target="_blank"
        matTooltip="{{item.tip}}">
        <svg class="inm-icon" aria-label="Help">
          <use [attr.xlink:href]="'#inm-icon__question-circle'"></use>
        </svg>
      </a>
    </div>
  </div>
</mat-toolbar>
