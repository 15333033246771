<div class="float-container">
    <h1>
        Manage Industry Contract Template
    </h1>
    <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center"
            [hidden]="!isLoadingResults">
        </mat-spinner>
    </div>
    <section [hidden]="isLoadingResults">
        <div class="content">
            <div class="grid-frame" style="width:75%">
              <mat-grid-list cols="6" rowHeight="50px" role="list">
                  <mat-grid-tile role="listitem" [colspan]="1">
                    <div class="grid-content">
                        <h6 class="inm-heading--h6">Network Name:</h6>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile role="listitem" [colspan]="5">
                    <div class="grid-content">
                        <span> {{ template.networkName }} </span>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile role="listitem" [colspan]="1">
                    <div class="grid-content">
                        <h6 class="inm-heading--h6">Payer:</h6>
                    </div>
                  </mat-grid-tile>

                  <mat-grid-tile role="listitem" [colspan]="5">
                    <div class="grid-content">
                        <span>{{ template.payerGroupName }}</span>
                    </div>
                  </mat-grid-tile>
              </mat-grid-list>

              <mat-grid-list cols="12" rowHeight="70px">
                <mat-grid-tile role="listitem" [colspan]="2">
                  <div class="grid-content">
                    <h6 class="inm-heading--h6">Contract Type:</h6>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="10">
                  <div class="grid-content">
                    <mat-chip-list #chipList>
                      <mat-chip *ngFor="let contractTypes of template?.industryContractTemplateContractTypes">
                        {{contractTypes.contractType.name}}&nbsp;
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>

            </div>
            <div class="float-child" class="full-width">
                <section class='inm-actions--confirmation'>
                    <button [disabled]="!canAddEdit" style="cursor:pointer;" class="inm-button--action button-spacing" (click)="editTemplateDialog()">Edit</button>
                    <button [disabled]="!canCreateContract" class="inm-button--action button-spacing" (click)="createContractFromTemplateDialog()">Create Contract</button>
                    <button class="inm-button button-spacing" (click)="cancel()">Cancel</button>

                </section>
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                    <mat-tab label="Template Details">
                        <div class="table-space">
                            <app-toolbar [toolbarItemList]="toolbarHelper.currentToolbar"
                                (OnClick)="toolbarBtnClick($event)"></app-toolbar>
                            <app-contract-details (OnClick)="actionOnClick($event)" #contractDetailsComponent [data]="contractDetailData"
                                [actionItemList]="templateDetailsActionItemsHelper.currentActionItems" (OnSort)="sortChanged($event)"
                                (OnPageChange)="pageChanged($event)"></app-contract-details>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </section>
</div>
