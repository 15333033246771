<div class="amendments-table">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matPaginator multiTemplateDataRows>
    <ng-container matColumnDef="contractId">
      <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header> Contract ID </th>
      <td hidden="true" mat-cell *matCellDef="let element" title="{{element.contractId}}"> {{ element.contractId }}</td>
    </ng-container>
    <ng-container matColumnDef="parentContractId">
      <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header> Parent Contract ID </th>
      <td hidden="true" mat-cell *matCellDef="let element" title="{{element.parentContractId}}"> {{ element.parentContractId }}</td>
    </ng-container>
    <ng-container matColumnDef="amendmentHistoryId">
      <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header> Amendment History ID </th>
      <td hidden="true" mat-cell *matCellDef="let element" title="{{element.amendmentHistoryId}}"> {{ element.amendmentHistoryId }}</td>
    </ng-container>
    <ng-container matColumnDef="networkName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Network Name </th>
      <td mat-cell *matCellDef="let element" title="{{element.networkName}}"> {{ element.networkName }}</td>
    </ng-container>
    <ng-container matColumnDef="contractStatusName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract Status </th>
      <td mat-cell *matCellDef="let element" title="{{element.contractStatusName}}"> {{ element.contractStatusName }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Amendment Note </th>
      <td mat-cell *matCellDef="let element" title="Amendment Note">
        <span *ngIf="element.description !== null && element.description.length >50" title="{{element.description.substr(0,50)}}">{{element.description.substr(0,50)}}
          <a class style="cursor: pointer;" title="View Amendment Note" (click)="viewAmendmentNote(element.description)">...</a>
        </span>
        <span *ngIf="element.description !== null && element.description.length <=50">
          {{element.description}}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="amendmentBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Amended By </th>
      <td mat-cell *matCellDef="let element" title="{{element.amendmentBy}}"> {{ element.amendmentBy }}</td>
    </ng-container>
    <ng-container matColumnDef="amendmentDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Amendment Date </th>
      <td mat-cell *matCellDef="let element" title="{{element.amendmentDate}}"> {{ element.amendmentDate | date: 'MMM d, yyyy h:mm a' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div>
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center" *ngIf="isLoadingResults">
      </mat-spinner>
    </div>
  </div>
</div>

<mat-paginator #paginator [length]="totalItemCount" [pageIndex]="pageIndex" [pageSize]="30"
[pageSizeOptions]="[5,10,15,30]" showFirstLastButtons (page)="onPageChange($event)">
</mat-paginator>