<div class="float-container">
  <h1 tabindex="1">{{ pagetitle }}</h1>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center">
    </mat-spinner>
  </div>
  <section [hidden]="isLoadingResults">
    <div class="content">
      <div class="grid-frame">
        <mat-grid-list cols="12" rowHeight="50px" role="list">
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label some"
                >Network Name:</label
              >
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span> {{ contractByIdResponse.networkName }} </span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">True Up:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span>{{ contractByIdResponse.trueUp }}</span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">Payment Terms:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span>{{ contractByIdResponse.paymentTermDays }}</span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">Payer:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span>{{ contractByIdResponse.payerGroupName }}</span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">True Up Date:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span>{{
                contractByIdResponse.trueUpDate | date : "MMM d, yyyy"
              }}</span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label"
                >Effective Date Range:</label
              >
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span
                >{{
                  contractByIdResponse.effectiveStartDate | date : "MMM d, yyyy"
                }}
                -
                {{
                  contractByIdResponse.effectiveEndDate | date : "MMM d, yyyy"
                }}</span
              >
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">Contract Status:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span>{{ contractByIdResponse.contractStatusDesc }}</span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">Days Supply:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span
                >{{ contractByIdResponse.daysSupply
                }}{{ contractByIdResponse.daysSupply == 84 ? "+" : "" }}</span
              >
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">Transaction Fee:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="3">
            <div class="grid-content">
              <span>{{
                contractByIdResponse.adminOrTransactionFee | currency
              }}</span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">Classification:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile role="listitem" [colspan]="3">
            <div class="grid-content">
              <span>{{ contractByIdResponse.contractClassificationDesc }}</span>
            </div>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1">
            <div class="grid-content">
              <label for="inp1__345" class="inm-label">Contract Type:</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="7">
            <div class="grid-content">
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let contractTypes of contractTypeResponseList"
                >
                  {{ contractTypes.contractTypeDesc }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div class="float-child" style="width: 100%">
        <section class="inm-actions--confirmation">
          <button
            class="inm-button--action button-spacing"
            [disabled]="!canAddEdit"
            (click)="clickActivateContract(contractId)"
          >
            Activate
          </button>
          <button
            class="inm-button--action button-spacing"
            (click)="clickEditContract(contractId)"
            [disabled]="!canAddEdit"
          >
            Edit
          </button>
          <button
            [disabled]="!(contractStatus == 1 && viewFeatures[1].accessable)"
            class="inm-button--action button-spacing"
            (click)="amendContract()"
          >
            Amend
          </button>
          <button class="inm-button button-spacing" (click)="cancel()">
            Cancel
          </button>
        </section>

        <mat-tab-group
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          animationDuration="0ms"
        >
          <mat-tab label="Contract Details" sticky="true">
            <div>
              <app-toolbar
                [toolbarItemList]="toolbarHelper.currentToolbar"
                [canAddEdit]="canAddEdit"
                (OnClick)="toolbarBtnClick($event)"
              ></app-toolbar>
              <app-contract-details
                [data]="contractDetailData"
                [canAddEdit]="canAddEdit"
                #contractDetailsComponent
                (OnClick)="actionOnClick($event)"
                (OnSort)="sortChanged($event)"
                (OnPageChange)="pageChanged($event)"
                [actionItemList]="
                  contractDetailsActionItemsHelper.currentActionItems
                "
              ></app-contract-details>
            </div>
          </mat-tab>
          <mat-tab label="Rates" sticky="true">
            <div>
              <app-rates-grid
                (editRateClicked)="updateContractRate($event)"
                (addRateClicked)="addContractRate($event, true)"
                (deleteRateClicked)="deleteContractRate($event, 'traditional')"
                #traditionalRatesComponent
                [data]="contractRatesTraditionalData"
                [canAddEdit]="viewFeatures[1].accessable && canAddEdit"
              >
              </app-rates-grid>
              <div style="margin-top: 75px"></div>
              <app-rates-grid
                (editRateClicked)="updateContractRate($event)"
                (addRateClicked)="addContractRate($event, false)"
                (deleteRateClicked)="deleteContractRate($event, 'effective')"
                #effectiveRatesComponent
                [data]="contractRatesEffectiveData"
                [canAddEdit]="viewFeatures[1].accessable && canAddEdit"
              ></app-rates-grid>
            </div>
          </mat-tab>
          <mat-tab label="Definitions" sticky="true">
            <div>
              <app-toolbar
                [toolbarItemList]="addDefinitionToolbarHelper.currentToolbar"
                [canAddEdit]="viewFeatures[1].accessable && canAddEdit"
                (OnClick)="addDefinition($event)"
              ></app-toolbar>
              <app-definitions-grid
                [data]="contractDefinitionData"
                [canAddEdit]="viewFeatures[1].accessable && canAddEdit"
                #definitionGridComponent
                (OnEditClick)="updateDefinition($event)"
              ></app-definitions-grid>
            </div>
          </mat-tab>
          <mat-tab label="Include/Exclude">
            <div class="table-space">
              <app-contractfactors
                [canAddEdit]="viewFeatures[1].accessable && canAddEdit"
              ></app-contractfactors>
            </div>
          </mat-tab>
          <mat-tab label="Notes">
            <div class="table-space">
              <app-toolbar
                [toolbarItemList]="toolbarHelper.currentToolbarNote"
                [canAddEdit]="canAddNoteDoc"
                (OnClick)="toolbarBtnClicknote()"
              ></app-toolbar>
              <app-contract-notes-component
                [data]="ContractNotesdata"
              ></app-contract-notes-component>
            </div>
          </mat-tab>
          <mat-tab label="Documents">
            <div class="table-space">
              <app-documents-grid
                class="float-document-list-container"
                [data]="contractDocumentsData"
              ></app-documents-grid>
              <app-file-upload
                class="float-document-container"
                (EditOnClick)="retrieveLatestDocuments($event)"
                [data]="fileUploadData"
                [canAddEdit]="canAddNoteDoc"
              ></app-file-upload>
            </div>
          </mat-tab>
          <mat-tab label="Amendments" *ngIf="hasNoData == false">
            <div class="table-space">
              <app-amendments-grid
                [data]="contractAmendmentData"
                #amendmentGridComponent
              ></app-amendments-grid>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section>
</div>
