
<table mat-table [dataSource]="dataSource" matSort matPaginator (matSortChange)="sortData($event)" multiTemplateDataRows>
  <ng-template #templateDetails>
    <ng-container matColumnDef="industryContractTemplateDetailId">
      <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td hidden="true" mat-cell *matCellDef="let element" title="{{element}}"> {{ element.industryContractTemplateDetailId}}
      </td>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="isContractDetails; else templateDetails" matColumnDef="contractDetailId">
    <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
    <td hidden="true" mat-cell *matCellDef="let element" title="{{element}}"> {{ element.contractDetailId}}
    </td>
  </ng-container>


  <ng-container  matColumnDef="bin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> BIN </th>
      <td mat-cell *matCellDef="let element" title="{{element.bin}}"> {{element.bin}}
      </td>
  </ng-container>

  <ng-container matColumnDef="pcn">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> PCN
    </th>
    <td mat-cell *matCellDef="let element"> {{element.pcn}}
    </td>
</ng-container>

  <ng-container matColumnDef="groupNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> GROUP #
      </th>
      <td mat-cell *matCellDef="let element"> {{element.groupNumber}}
      </td>
  </ng-container>

  <ng-container matColumnDef="networkId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Network ID
      </th>
      <td mat-cell *matCellDef="let element"> {{element.networkId}}
      </td>
  </ng-container>

  <ng-container  matColumnDef="actions">
    <th [hidden]="this.actionItemList.length === 0" mat-header-cell *matHeaderCellDef></th>
    <td [hidden]="this.actionItemList.length === 0" mat-cell *matCellDef="let element">
      <div class="ui-toolbar-group-left" *ngFor="let item of actionItemList;let i = index">
        <button class="inm-button" *ngIf="item.show" [disabled]="!canAddEdit"
          type="button" matTooltip="{{item.tip}}" (click)="actionOnClick(item.name, element)">
          <svg class="inm-icon" aria-label="item.tip" *ngIf="item.icon">
            <use [attr.xlink:href]="item.icon">{{item.label}}</use>
          </svg>
          <div *ngIf="!item.icon">
            {{item.label}}
          </div>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator #paginator [length]="tableCount" [pageIndex]="pageIndex"
[pageSize]="30" [pageSizeOptions]="[5,10,15,30]" showFirstLastButtons
(page)="onPageChange($event)">
</mat-paginator>
