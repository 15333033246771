import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'lodash';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ContractTemplateResponse } from "../models/ContractTemplateResponse";
import { IndustryContractTemplate } from "../models/IndustryContractTemplate";
import { IndustryContractTemplateResponse } from "../models/IndustryContractTemplateResponse";
import { IndustryContractTemplateSearchCriteria } from "../models/IndustryContractTemplateSearchCriteria";
import { Pagination } from "../models/Pagination";

@Injectable({
  providedIn: 'root',
})
export class TemplateLibaryService {
  private readonly Timeout: number = 30000;
  apiRoot: string;

  constructor(private http: HttpClient) {
    this.apiRoot = environment.cmApiRoot;
  }

  public getTemplates(
    pagination: Pagination,
    networkName?: string,
    contractTypeIds?: number[],
    payerName?: string,
    maxDetailRecs?: number
    ): Observable<ContractTemplateResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    var params = pagination.buildSearchParams();
    params = params.append("maxDetailRecs", maxDetailRecs);

    var searchCriteria = new IndustryContractTemplateSearchCriteria();
    searchCriteria.NetworkName = networkName;
    searchCriteria.ContractTypeIds = contractTypeIds;
    searchCriteria.PayerGroupName = payerName;

    return this.http.post<ContractTemplateResponse>(`${this.apiRoot}/api/IndustryContractTemplate/List`,searchCriteria, {params});
  }

  public getTemplateById(id: string): Observable<IndustryContractTemplateResponse> {
    return this.http.get<IndustryContractTemplateResponse>(`${this.apiRoot}/api/IndustryContractTemplate/GetIndustryContractTemplateById?industryContractTemplateId=${id}`)
  }

  public updateTemplateById(template, templateId: number): Observable<IndustryContractTemplate> {
    return this.http.put<IndustryContractTemplate>(`${this.apiRoot}/api/IndustryContractTemplate/update?industrycontracttemplateid=${templateId}`, template);
  }

  public createNewTemplate(template: IndustryContractTemplate): Observable<IndustryContractTemplate> {
    return this.http.post<IndustryContractTemplate>(`${this.apiRoot}/api/industrycontracttemplate/create`, template);
  }

}
