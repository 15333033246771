<h3 mat-dialog-title class="inm-headline">&nbsp;&nbsp;{{pagetitle}}</h3>

<div mat-dialog-content>
  <div style="overflow-y: scroll;" fxLayout="row wrap" fxLayout.sm="column" class="col-3" fxLayoutWrap
    fxLayoutGap="0.5%" fxLayoutAlign="left">
    <div style="width: 700px; height: 150px;">
      {{note}}


    </div>

  </div>

  <div class="float-child" style=" width: 100%;">
    <section class='inm-actions--confirmation'>
      <button mat-dialog-close class="inm-button button-spacing">
        Close</button>
    </section>
  </div>
</div>