import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isEmpty } from "rxjs/operators";
import { environment } from 'src/environments/environment';

import { ContractDefinitionResponse } from '../models/contract-definition-response';
import { ContractDefinition } from '../models/ContractDefinition';
import { ContractPaginationResult } from '../models/ContractPaginationResult';
import { Pagination } from '../models/Pagination';
import { GlobalFilterService } from './global-filter.service';

@Injectable({
  providedIn: 'root'
})
export class ContractDefinitionService {

  private readonly Timeout: number = 30000;
  apiRoot: string;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService) {
    this.apiRoot = environment.cmApiRoot;
  }

  //  Retrieves the set of Definitions for a Contract.
  getContractDefinitionsForContractId(pagination: Pagination, ContractId?: number): Observable<ContractPaginationResult<ContractDefinition>> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = pagination.buildSearchParams();

    return this.http.get<ContractPaginationResult<ContractDefinition>>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/definitions`, { params });
  }

  //  Retrieves a single Definition for a Contract.
  getContractDefinitionForContractId(ContractId?: number, DefinitionId?: number): Observable<ContractDefinition> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<ContractDefinition>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/definitions/${DefinitionId}`, { headers: headers });
  }

  addContractDefinition(contractId: number, contractDefinition: any): Observable<ContractDefinitionResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<ContractDefinitionResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${contractId}/definitions`, contractDefinition, { headers: headers });
  }

  updateContractDefinition(contractId: number, contractDefinitionId: number, contractDefinition): Observable<ContractDefinitionResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.put<ContractDefinitionResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${contractId}/definitions/${contractDefinitionId}`, contractDefinition, { headers: headers });
  }
}
