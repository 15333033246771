<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matPaginator>

  <ng-container matColumnDef="contractNoteId">
    <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header> contractNoteId </th>
    <td hidden="true" mat-cell *matCellDef="let element" title="{{element}}"> {{ element.contractNoteId}}
    </td>
  </ng-container>


  <ng-container matColumnDef="note">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Note </th>
    <td mat-cell *matCellDef="let element" title="{{element}}">
      <a *ngIf="element.note.length >50" style=" cursor: pointer ;" (click)="shownotedialog(element.note)">
        {{element.note.substr(0,50)}}...
      </a>
      <span *ngIf="element.note.length<50">
        {{element.note}}
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By
    </th>
    <td mat-cell *matCellDef="let element"> {{element.createdBy}}
    </td>
  </ng-container>
  <ng-container matColumnDef="createdDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date
    </th>
    <td mat-cell *matCellDef="let element"> {{element.createdDate | date: 'MMM d, yyyy h:mm a' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>
</table>
<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center" *ngIf="isLoadingResults">
    </mat-spinner>
  </div>
</div>
<mat-paginator #paginator [length]="totalItemCount" [pageIndex]="pageIndex" [pageSize]="30"
  [pageSizeOptions]="[5,10,15,30]" showFirstLastButtons (page)="onPageChange($event)">
</mat-paginator>