<h3 mat-dialog-title class="inm-headline">{{pageTitle}}</h3>

<div mat-dialog-content>
  <div style="overflow-y: scroll;" fxLayout="row wrap" fxLayout.sm="column" class="col-3" fxLayoutWrap
    fxLayoutGap="0.5%" fxLayoutAlign="left">
    <div style="height: 150px;">
      {{note}}
    </div>
  </div>

  <div class="float-child" style=" width: 100%;">
    <section class='inm-actions--confirmation'>
      <button class="inm-button button-spacing" [mat-dialog-close]="true">Cancel</button>
    </section>
  </div>
</div>
