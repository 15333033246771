import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { GridPagination } from 'src/app/models/GridPagination';
import { AuthorizationService } from 'src/app/services/authorization.service';

import { ManageContractComponent } from '../../contracts/manage/manage-contract.component';

@Component({
  selector: 'app-definitions-grid',
  templateUrl: './definitions-grid.component.html',
  styleUrls: ['./definitions-grid.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class DefinitionsGridComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [];
  Sort = '';
  sortOrder = '';
  offset = 0;
  limit = 30;
  pageIndex = 0;
  totalItemCount: number;
  isLoadingResults = false;

  public currentToolbar = [];

  title: string;

  private readonly ACTION_ITEMS = [
    {
      feature: 'ContractManagement::AddEditContract',
      item: {
        label: 'Edit Definition',
        name: 'editDefinition',
        tip: 'Edit Definition',
        icon: '#inm-icon__edit',
        disabled: false,
        show: true,
      },
    },
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @Output() OnEditClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() canAddEdit: boolean = true;
  @Input() set data(value) {
    if (value == null || value == undefined) {
      return;
    }

    this.displayedColumns = ['definitionId', 'definitionType', 'mony', 'tbg', 'daw', 'actions'];
    this.dataSource = new MatTableDataSource(value.data);
    this.totalItemCount = value.count;

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };

    this.dataSource.sort = this.sort;
  }

  constructor(private authService: AuthorizationService, private manageContractComponent: ManageContractComponent) {
    this.createActionToolbar();
  }

  ngOnInit(): void {
    this.title = "Definitions";
  }

  renderTableRows() {
    this.table.renderRows();
    this.dataSource._updateChangeSubscription();
  }

  createActionToolbar(): void {
    for (const toolbar of this.ACTION_ITEMS) {
      if (!toolbar.feature) {
        this.currentToolbar.push(toolbar.item);
        continue;
      }
      if (toolbar.feature && this.authService.hasFeature(toolbar.feature)) {
        this.currentToolbar.push(toolbar.item);
      }
    }
  }

  onPageChange(event: PageEvent): void {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * event.pageSize;
    this.loadDefinitions()
  }

  disableSpinner() {
    this.isLoadingResults = false;
  }

  loadDefinitions() {
    this.isLoadingResults = true;
    var pagination = new GridPagination();
    pagination.limit = this.limit;
    pagination.offset = this.offset;
    pagination.sort = this.Sort;
    pagination.sortOrder = this.sortOrder;

    this.OnEditClick.emit({ action: 'Sort', definition: null, pagination: pagination });

    console.log("reached end of load");
    this.isLoadingResults = false;

  }

  sortDataGrid(sort: Sort) {

    this.Sort = sort.active;
    this.sortOrder = sort.direction;
    this.offset = 0;
    this.pageIndex = 0;

    this.loadDefinitions();
  }

  editClick(element) {
    this.OnEditClick.emit({ action: 'Edit', definition: element, pagination: null });
  }
}
