import { Location } from '@angular/common';
import { OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ContractType } from 'src/app/models/ContractType';
import { IndustryContractTemplate } from 'src/app/models/IndustryContractTemplate';
import { IndustryContractTemplateContractTypes } from 'src/app/models/IndustryContractTemplateContractTypes';
import { IndustryContractTemplateDetail } from 'src/app/models/IndustryContractTemplateDetail';
import { IndustryContractTemplateResponse } from 'src/app/models/IndustryContractTemplateResponse';
import { Pagination } from 'src/app/models/Pagination';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ContractTypeService } from 'src/app/services/contractTypeService';
import { TemplateDetailsService } from 'src/app/services/template-details.service';
import { TemplateLibaryService } from 'src/app/services/templateLibraryService';

import { ActionItem, ContractDetailsComponent } from '../../shared/contract-details/contract-details.component';
import { ToolbarAction } from '../../shared/table-toolbar/ToolBarAction';
import { AddContractFromTemplateDialogComponent } from '../add-contract-from-template-dialog/add-contract-from-template-dialog.component';
import { AddEditTemplateDetailsDialogData } from '../add-edit-template-details-dialog/add-edit-template-details-dialog-data';
import { AddEditTemplateDetailsDialogComponent } from '../add-edit-template-details-dialog/add-edit-template-details-dialog.component';
import { AddEditTemplatesDialogData } from '../add-edit-templates-dialog/add-edit-templates-dialog-data';
import { AddEditTemplatesDialogComponent } from '../add-edit-templates-dialog/add-edit-templates-dialog.component';
import { ManageTemplatesToolbarHelper } from './helper/manage-templates-toolbar.helper';
import { TemplateDetailsActionItemsHelper } from './helper/template-details-action-items.helper';

@Component({
  selector: 'app-manage-templates',
  templateUrl: './manage-templates.component.html',
  styleUrls: ['./manage-templates.component.less']
})
export class ManageTemplatesComponent implements OnInit {

  id: string;
  template: IndustryContractTemplateResponse = new IndustryContractTemplateResponse;
  dataSource: MatTableDataSource<IndustryContractTemplateDetail> = new MatTableDataSource<IndustryContractTemplateDetail>();

  isLoadingResults: boolean = true;
  contractDetails: IndustryContractTemplateDetail[] = [];
  contractDetailData: any;
  displayedColumns: string[] = ['bin', 'groupNumber', 'pcn', 'networkId' ];
  contractTypeResponseList: IndustryContractTemplateContractTypes[] = [];

  Sort = '';
  sortOrder = 'asc';
  offset = 0;
  limit = 30;
  pageIndex = 0;

  canAddEdit: boolean = false;
  canCreateContract: boolean = false;

  public readonly toolbarHelper: ManageTemplatesToolbarHelper;
  public readonly templateDetailsActionItemsHelper: TemplateDetailsActionItemsHelper;
  public readonly viewFeatures = [
    { feature: 'ContractManagement::AddEditTemplate', name: 'edit', accessable: false },
    { feature: 'ContractManagement::AddEditContract', name: 'add', accessable: false }
  ];

  constructor(
    private templateService: TemplateLibaryService,
    private templateDetailService: TemplateDetailsService,
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private location: Location,
    public dialog: MatDialog,
    public contractTypeService: ContractTypeService
  ) {
    this.toolbarHelper = new ManageTemplatesToolbarHelper(this.authorizationService);
    this.templateDetailsActionItemsHelper = new TemplateDetailsActionItemsHelper(this.authorizationService);
  }

  public async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    await this.loadAllContractTypes();
    this.loadTemplate();
    this.loadTemplateDetails();
    this.checkFeatures();
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('contractDetailsComponent', { static: false }) contractDetailsComponent: ContractDetailsComponent;

  loadTemplate(): void {
    this.isLoadingResults = true;

    this.templateService.getTemplateById(this.id)
      .subscribe(
        (result) => {
          this.template = result;
          this.dataSource = new MatTableDataSource<IndustryContractTemplateDetail>(result.industryContractTemplateDetails);
          this.cleanData();
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator
          this.isLoadingResults = false;
          this.canAddEdit = this.viewFeatures[0].accessable;
          this.canCreateContract = this.viewFeatures[1].accessable;
        }
      ),
      (err) => {
        toastr.error('We are unable to load Industry Contract Templates at this time');
        this.isLoadingResults = false;
      }
  }

  loadTemplateDetails(): void {
    this.templateDetailService.getTemplateDetails(
        new Pagination(this.limit, this.offset, this.Sort, this.sortOrder),
        parseInt(this.id))
      .subscribe(
        (result) => {
          this.contractDetails = result.items;

          this.limit = result.limit;
          this.offset = result.offset;

          this.dataSource = new MatTableDataSource<IndustryContractTemplateDetail>(result.items);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator

          this.contractDetailData = {
            actions: 'Edit',
            data: this.contractDetails,
            count: result.totalItemCount,
            hasContractDetails: false
          };
        }
      ),
      (err) => {
        toastr.error('We are unable to load Industry Contract Template Details at this time');
      }
  }

  sortChanged(sort: Sort) {
    this.Sort = sort.active;
    this.sortOrder = sort.direction;

    this.loadTemplateDetails();

    if (this.paginator == null || this.paginator == undefined) {
      return;
    }
    this.paginator.firstPage();
  }

  pageChanged(page: PageEvent) {
      this.limit = page.pageSize;
      this.pageIndex = page.pageIndex;
      this.offset = page.pageIndex * page.pageSize;

      this.loadTemplateDetails();
  }


  cleanData() {
    this.template.industryContractTemplateContractTypes.forEach( x => {
      if (x.contractType === null ){
        var type = this.contractTypeService.types.find(c => c.value === x.contractTypeId);
        var contractType = new ContractType(type.value, type.label)
        x.contractType = contractType;
      }
    });
  }

  public async loadAllContractTypes() {
    this.isLoadingResults = true;
    var data = await this.contractTypeService.getContractTypeList().toPromise()
      .then((data) => {
        this.contractTypeService.types = data.contractTypeList.map((a) => ({
          value: a.contractTypeId,
          label: a.name,
        }));
      })
      .catch((error) => {
        toastr.error('We are unable to load Industry Contract Templates at this time');
        this.isLoadingResults = false;
      })
      .finally(() => {this.isLoadingResults = false})

  }


  toolbarBtnClick(event: ToolbarAction): void {
    switch (event.name) {
      case 'addTemplateDetails': {
        this.addNewTemplateDetailsDialog();
        break;
      }
    }
  }

  cancel() {
    this.location.back();
  }

  editTemplateDialog(): void {
    const dataToEdit = new IndustryContractTemplate();
    dataToEdit.networkName = this.template.networkName;
    dataToEdit.payerGroupName = this.template.payerGroupName;
    dataToEdit.industryContractTemplateContractTypes = this.template.industryContractTemplateContractTypes;
    dataToEdit.industryContractTemplateId = this.template.industryContractTemplateId;

    const dialogRef = this.dialog.open(AddEditTemplatesDialogComponent, {
      data: {
        action: 'edit',
        title: 'Edit Industry Contract Template',
        template: dataToEdit,
      },
    });
    dialogRef.afterClosed().subscribe((returnedData: AddEditTemplatesDialogData) => {
      if (returnedData.action === 'close') {
        this.template.networkName = returnedData.template.networkName;
        this.template.payerGroupName = returnedData.template.payerGroupName;
        this.template.industryContractTemplateContractTypes = returnedData.template.industryContractTemplateContractTypes;
        this.cleanData();
      }
    });
  }

  addNewTemplateDetailsDialog() {
    const templateDetailToAdd: IndustryContractTemplateDetail = {
      industryContractTemplateDetailId: 0,
      industryContractTemplateId: this.template.industryContractTemplateId,
      bin: '',
      groupNumber: '',
      pcn: '',
      networkId: ''
    }

    const dialogRef = this.dialog.open(AddEditTemplateDetailsDialogComponent,{
      data: {
        action: 'add',
        title: 'Add Industry Contract Template Details',
        templateDetail: templateDetailToAdd
      }
    });

    dialogRef.afterClosed().subscribe((returnedData: AddEditTemplateDetailsDialogData) => {
      if (returnedData.action === 'close') {
        this.loadTemplateDetails();
      }
    });
  }

  actionOnClick(event: ActionItem) {
    if (event.name == "editTemplateDetails") {
      this.updateTemplateDetail(event.element);
    }
  }

  updateTemplateDetail(templateDetail: IndustryContractTemplateDetail) {
    const dialogRef = this.dialog.open(AddEditTemplateDetailsDialogComponent,{
      data: {
        action: 'edit',
        title: 'Edit Industry Contract Template Details',
        templateDetail: templateDetail
      }
    });

    dialogRef.afterClosed().subscribe((returnedData: AddEditTemplateDetailsDialogData) => {
      if (returnedData.action === 'close') {
        var indexToRemove = this.contractDetailData.data.findIndex(x => x.industryContractTemplateDetailId == returnedData.templateDetail.industryContractTemplateDetailId);
        this.contractDetailData.data.splice(indexToRemove, 1, returnedData.templateDetail);
        this.contractDetailsComponent.renderTableRow();
      }
    });
  }

  private checkFeatures(): void {
    for (const vwFeature of this.viewFeatures) {
      if (this.authorizationService.hasFeature(vwFeature.feature)) {
        vwFeature.accessable = true;
      }
    }
  }

  createContractFromTemplateDialog() {
    const dialogRef = this.dialog.open(AddContractFromTemplateDialogComponent, {
      data: {
        action: 'add',
        title: 'Add Contract From Template',
        template: this.template
      }
    });
  }
}
