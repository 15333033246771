import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ContractStatusResponse } from '../models/ContractStatusResponse';
import { SelectItem } from '../models/SelectItem';

@Injectable({
  providedIn: 'root',
})
export class ContractStatusService {
  apiRoot: string;
  statuses: SelectItem[] = [];

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.apiRoot = environment.cmApiRoot;
  }

  public getContractStatusList(): Observable<ContractStatusResponse> {

    return this.http.get<ContractStatusResponse>(`${this.apiRoot}/api/ContractStatuses`);
  }


}

