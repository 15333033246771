import { AuthorizationService } from "src/app/services/authorization.service";

export class AddDefinitionToolbarHelper {
  public showAction: string;
  public canAddEdit: boolean = true;
  public currentToolbar = [];
  private readonly TOOLBAR_ITEMS = [
    {
      feature: 'ContractManagement::AddEditContract',
      item: {
        label: 'Add Definition',
        name: 'addDefinition',
        tip: 'Add New Definition',
        icon: '#inm-icon__plus-square',
        disabled: !this.canAddEdit,
        show: true,
      },
    },
  ];
  constructor(private authService: AuthorizationService) {
    this.createActionToolbar();
  }

  createActionToolbar(): void {
    for (const toolbar of this.TOOLBAR_ITEMS) {
      if (!toolbar.feature) {
        this.currentToolbar.push(toolbar.item);
        continue;
      }
      if (toolbar.feature && this.authService.hasFeature(toolbar.feature)) {
        this.currentToolbar.push(toolbar.item);
      }
    }
  }
}
