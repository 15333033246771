import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { map } from 'lodash';
import { ContractTemplateResponse } from 'src/app/models/ContractTemplateResponse';
import { IndustryContractTemplate } from 'src/app/models/IndustryContractTemplate';
import { IndustryContractTemplateDetail } from 'src/app/models/IndustryContractTemplateDetail';
import { IndustryContractTemplateResponse } from 'src/app/models/IndustryContractTemplateResponse';
import { Pagination } from 'src/app/models/Pagination';
import { SelectItem } from 'src/app/models/SelectItem';
import { ContractTypeService } from 'src/app/services/contractTypeService';
import { PayerService } from 'src/app/services/payerService';
import { TemplateLibaryService } from 'src/app/services/templateLibraryService';
import * as toastr from 'toastr';

import { AuthorizationService } from '../../../services/authorization.service';
import { GlobalFilterService } from '../../../services/global-filter.service';
import { ToolbarAction } from '../../shared/table-toolbar/ToolBarAction';
import { AddContractFromTemplateDialogComponent } from '../add-contract-from-template-dialog/add-contract-from-template-dialog.component';
import { AddEditTemplatesDialogData } from '../add-edit-templates-dialog/add-edit-templates-dialog-data';
import { AddEditTemplatesDialogComponent } from '../add-edit-templates-dialog/add-edit-templates-dialog.component';
import { ManageTemplatesToolbarHelper } from './helper/template-library-toolbar.helper';

{ }


@Component({
  selector: 'app-template-library',
  templateUrl: './template-library.component.html',
  styleUrls: ['./template-library.component.scss']
})
export class TemplateLibraryComponent {

  @HostListener('window:keyup.enter')
  onKeyPressEnter() {
    this.onSubmit();
  }

  constructor(
    private payerService: PayerService,
    private contractTypeService: ContractTypeService,
    globalFilterService: GlobalFilterService,
    private fb: UntypedFormBuilder,
    private authorizationService: AuthorizationService,
    private templateLibraryService: TemplateLibaryService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    globalFilterService.ClientChanged.subscribe(() => {
      this.onResetFilter();
      this.ngOnInit();
    });
    this.toolbarHelper = new ManageTemplatesToolbarHelper(this.authorizationService)
    this.checkFeatures();
  }

  sectionRoot = '/templatelibrary/';

  isLoadingResults = false;
  routeActivated = false;
  showSearchResults = false;
  showNewPbidColumn = false;
  isGridEmpty = true;

  // Data sources
  dataSource: any;
  totalRecordCount: number;

  payersList: SelectItem[];
  filteredPayers: SelectItem[];
  selectedPayer: string;

  contractTypeList: SelectItem[];
  contractTypes: SelectItem[];
  networkName: string;
  enteredNetworkName: string;
  selectedContractTypes: number[];

  // Filter Form Related stuff
  filterFormGroup: UntypedFormGroup;
  exceptionFilter: number;
  resetEnabled = false;
  selectShow = false;
  ResolveDisable = true;
  private readonly MAX_EXPORT_LIMIT_EXCEPTIONS = 1000;
  private readonly MAX_TEMPLATE_DETAIL_RECORDS = 4;
  datepipe: DatePipe = new DatePipe('en-US');

  sort = 'NetworkName';
  sortOrder = 'asc';
  offset = 0;
  limit = 30;
  pageIndex = 0;

  hasNoData = true;
  canAddEdit: boolean = true;

  contractTemplateResponse: ContractTemplateResponse;
  elementData: IndustryContractTemplateResponse[] = [];
  filteredData: IndustryContractTemplateResponse[] = [];
  validData: IndustryContractTemplateResponse[] = [];
  detailData: IndustryContractTemplateDetail[] = [];

  selection = new SelectionModel<IndustryContractTemplateResponse>(true, []);
  public readonly toolbarHelper: ManageTemplatesToolbarHelper;

  displayedColumns: string[] = [
    'NetworkName',
    'PayerGroupName',
    'ContractType',
    'TemplateDetails',
    'IndustryContractTemplateId',
    'Actions'
  ];

  public readonly viewFeatures = [
    { feature: 'ContractManagement::ViewTemplate', name: 'view', accessable: false },
    { feature: 'ContractManagement::AddEditContract', name: 'add', accessable: false },
    { feature: 'ContractManagement::AddEditTemplate', name: 'edit', accessable: false },
  ];

  @ViewChild(MatSort) Sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('matInputPayerSearch') inputPayerSearch: ElementRef;

  onSubmit(): void {
    this.resetEnabled = true;
    this.pageIndex = 0;
    this.offset = 0;
    this.showSearchResults = true;

    // get the values and submit the search query
    this.selectedContractTypes = this.filterFormGroup.controls.contractTypesCtrl.value ?
        map(this.filterFormGroup.controls.contractTypesCtrl.value,'value')
        : [];

    this.enteredNetworkName = this.filterFormGroup.controls.networkNameCtrl.value;

    this.selectedPayer = "";
    if (this.filterFormGroup.controls.payerIdCtrl.value != undefined && this.filterFormGroup.controls.payerIdCtrl.value != null)
    {
      this.selectedPayer = this.filterFormGroup.controls.payerIdCtrl.value.value;
    }

    this.loadAllTemplateLibraries();
  }

  loadAllTemplateLibraries(): void {
    this.isLoadingResults = true;

    this.templateLibraryService.getTemplates(
      new Pagination(this.limit, this.offset, this.sort, this.sortOrder),
      this.enteredNetworkName,
      this.selectedContractTypes,
      this.selectedPayer,
      this.MAX_TEMPLATE_DETAIL_RECORDS
    )
    .subscribe(
      (result) => {
        // Pagination stuff.
        this.limit = result.limit;
        this.offset = result.offset;
        this.totalRecordCount = result.totalRecordCount;
        this.selection.clear();

        // Data stuff
        this.contractTemplateResponse = result;
        this.elementData = this.contractTemplateResponse.results;
        this.elementData.forEach(function(part, index)
        {
          this.detailData = this[index].industryContractTemplateDetails;
          if (this.detailData != null) {

            this.detailData.forEach(function(part2, index2) {
              if ((this[index2].bin == "" || this[index2].bin == null) &&
                  (this[index2].pcn == "" || this[index2].pcn == null) &&
                  (this[index2].networkId == "" || this[index2].networkId == null) &&
                  (this[index2].groupNumber == "" || this[index2].groupNumber == null)) {
                    part.industryContractTemplateDetails = null;
              }
            }, this.detailData);
          }
        }, this.elementData);

        this.filteredData = this.elementData;
        this.dataSource = new MatTableDataSource<IndustryContractTemplateResponse>(
          this.filteredData
        );

        this.hasNoData = this.dataSource == null || this.dataSource == undefined || this.dataSource.data.length < 1;

        this.isLoadingResults = false;
        this.resetEnabled = true;
      },
      (err) => {
        toastr.error('We are unable to load Industry Contract Templates at this time');
        this.isLoadingResults = false;
        this.resetEnabled = true;
      }
    );
  }

  sortData(sort: Sort): void {
    this.sort = sort.active;
    this.sortOrder = sort.direction;
    this.paginator.firstPage();
    this.loadAllTemplateLibraries();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.loadAllPayers();
    this.loadAllContractTypes();
    this.onChanges();
    this.canAddEdit = this.viewFeatures[2].accessable;
    this.toggleActions(this.toolbarHelper.currentToolbar)
  }

  private toggleActions(toolbar) : void {
    toolbar.every((item) => {
      item.disabled = !this.canAddEdit;
    })
  }

  loadAllPayers(): void {
    this.isLoadingResults = true;
    this.payerService.getPayerList().subscribe({
      next: (result) => {
        this.payersList = this.transformValue(result);
        this.filteredPayers = this.payersList;
        this.isLoadingResults = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Payers at this time');
        this.isLoadingResults = false;
      },
    });
  }

  loadAllContractTypes(): void {
    this.isLoadingResults = true;
    this.contractTypeService.getContractTypeList().subscribe({
      next: (result) => {
        this.contractTypeList = result.contractTypeList.map((a) => ({
          value: a.contractTypeId,
          label: a.name,
        }));
        this.contractTypes = this.contractTypeList;
        this.isLoadingResults = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Contract Types at this time');
        this.isLoadingResults = false;
      },
    });
  }

  arrayObjects: SelectItem[] =[];
  transformValue(response):SelectItem[]{
    this.arrayObjects = [];
    response.forEach(data => {
      this.arrayObjects.push({
            label: data,
            value: data
        })
    })
    return this.arrayObjects;
  }

  onKeyPayer(value): void {
    const filterValue = value.toLowerCase();
    this.filteredPayers = this.payersList.filter((payer) =>
      payer.label.toLowerCase().includes(filterValue)
    );
  }

  onChanges(): void {
    this.filterFormGroup.valueChanges.subscribe(() => {
      this.isGridEmpty = true;
    });
  }


  onPageChange(event: PageEvent): void {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * event.pageSize;
    this.selection.clear();

    this.loadAllTemplateLibraries();
  }

  initFormGroup(): void {
    this.filterFormGroup = this.fb.group(
      {
        networkNameCtrl: '',
        payerIdCtrl: '',
        contractTypesCtrl: '',
      }
    );
  }

  onResetFilter(): void {
    this.filterFormGroup.reset();
    this.filterFormGroup.markAsUntouched();
    this.filterFormGroup.markAsPristine();
    this.resetEnabled = false;
    this.showSearchResults = false;
    this.isGridEmpty = true;
    this.sort = 'NetworkName';
    this.sortOrder = 'asc';
    this.pageIndex = 0;
    this.offset = 0;
    this.limit = 30;
    this.dataSource = [];
    this.inputPayerSearch.nativeElement.value = '';
    this.filteredPayers = this.payersList;
    this.contractTypes = this.contractTypeList;
    this.totalRecordCount = 0;
  }

  atLeastOneValueSelected(): boolean {
    const controls = this.filterFormGroup.controls;
    if (controls) {
      return (controls.networkNameCtrl.value != null && controls.networkNameCtrl.value != undefined && controls.networkNameCtrl.value.length > 0) ||
      (controls.payerIdCtrl.value != null && controls.payerIdCtrl.value != undefined && controls.payerIdCtrl.value != '' ) ||
      (controls.contractTypesCtrl.value != null && controls.contractTypesCtrl.value != undefined && controls.contractTypesCtrl.value.length > 0);
    }
    return false;
  }

  private checkFeatures(): void {
    for (const vwFeature of this.viewFeatures) {
      if (this.authorizationService.hasFeature(vwFeature.feature)) {
        vwFeature.accessable = true;
      }
    }
  }

  addNewTemplateDialog() {
    const dialogRef = this.dialog.open(AddEditTemplatesDialogComponent, {
      data: {
        action: 'add',
        title: 'Add New Industry Contract Template'
      }
    });

    dialogRef.afterClosed().subscribe((returnedData: AddEditTemplatesDialogData) => {
      if (returnedData.action === 'close') {
        this.router.navigate(['cm/templatelibrary/managetemplates/',returnedData.template.industryContractTemplateId])
      }
    })
  }

  toolbarBtnClick(event: ToolbarAction): void {
    switch (event.name) {
      case 'addTemplate': {
        this.addNewTemplateDialog();
        break;
      }
    }
  }

  createContractFromTemplateDialog(element: IndustryContractTemplate) {
    const dialogRef = this.dialog.open(AddContractFromTemplateDialogComponent, {
      data: {
        action: 'add',
        title: 'Add Contract From Template',
        template: element
      }
    });
  }
}
