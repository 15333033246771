import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LookerSummaryComponent } from 'src/app/components/looker/looker-summary/looker-summary.component';
import { LookerViewComponent } from 'src/app/components/looker/looker-view/looker-view.component';
import { NoAccessComponent } from 'src/app/components/no-access/no-access.component';

import { AuthGuard } from '../../guards/auth.guard';
import { FeatureGuard } from '../../guards/feature.guard';

const routes: Routes = [
  {
    path: 'looker',
    component: LookerSummaryComponent,
    children: [
      { path: '', redirectTo: 'analytics/home', pathMatch: 'full' },
      { path: 'analytics', redirectTo: 'analytics/:slugid', pathMatch: 'full' },
      {
        path: 'analytics/:slugid',
        component: LookerViewComponent,
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'ContractManagement::AllUsers', shouldDetach: true },
      },
      {
        path: 'no-accesss',
        component: NoAccessComponent,
      },
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LookerRoutingModule {}
