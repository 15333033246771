<div class="documents-table">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matPaginator>
    <ng-container matColumnDef="contractId">
      <th hidden="true" mat-header-cell *matHeaderCellDef> Contract ID </th>
      <td hidden="true" mat-cell *matCellDef="let element" title="{{element.contractId}}"> {{element.contractId}}</td>
    </ng-container>
    <ng-container matColumnDef="contractPdfId">
      <th hidden="true" mat-header-cell *matHeaderCellDef> Contract PDF Id </th>
      <td hidden="true" mat-cell *matCellDef="let element" title="{{element.contractPdfId}}"> {{element.contractPdfId}}</td>
    </ng-container>
    <ng-container matColumnDef="contractFileName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element" title="{{element.contractFileName}}"> {{element.contractFileName}}</td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
      <td mat-cell *matCellDef="let element" title="{{element.createdBy}}"> {{element.createdBy}}</td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
      <td mat-cell *matCellDef="let element" title="{{element.createdDate | date: 'MMM d, yyyy h:mm a' }}"> {{element.createdDate | date: 'MMM d, yyyy h:mm a' }}</td>
    </ng-container>
    <ng-container matColumnDef="contractPdfGuid">
      <th mat-header-cell *matHeaderCellDef ></th>
      <td mat-cell *matCellDef="let element" title="Download: {{element.contractFileName}}">
        <button class="inm-button" type="button" (click)="actionOnClick(element)" >
          <svg class="inm-icon" aria-label="Download">
            <use [attr.xlink:href]="'#inm-icon__download'">Download</use>
          </svg>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div>
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center" *ngIf="isLoadingResults">
      </mat-spinner>
    </div>
  </div>
</div>
<div [hidden]="totalItemCount < 1">
  <mat-paginator #paginator [length]="totalItemCount" [pageIndex]="pageIndex" [pageSize]="30"
  [pageSizeOptions]="[5,10,15,30]" showFirstLastButtons (page)="onPageChange($event)">
</mat-paginator>
</div>
