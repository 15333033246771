<div class="float-container">
  <h1>Contract Search</h1>
  <div class="content">
    <div class="float-child" class="full-width">
      <form  [formGroup]="filterFormGroup" [style.fontSize.px]="14" class="content">
        <div>
          <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
            <mat-label>Network Name</mat-label>
            <input title="Network Name" matInput formControlName="networkNameCtrl" type="string">
          </mat-form-field>
          <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
            <mat-label>Payer</mat-label>
            <mat-select panelClass="selectbox-panel" formControlName="payerIdCtrl">
              <input #matInputPayerSearch placeholder="Payer" matInput type="text"
                (keyup)="onKeyPayer($any($event.target).value)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let payer of filteredPayers" [value]="payer">
                {{payer.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
            <mat-label>Contract Type</mat-label>
            <mat-select formControlName="contractTypesCtrl" multiple>
              <mat-option *ngFor="let contractType of contractTypes" [value]="contractType"
                [selected]="contractTypeList">
                {{contractType.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>
        <div fxLayout="row" class="float-child">
          <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
            <mat-label>Contract Status</mat-label>
            <mat-select formControlName="contractStatusCtrl" multiple>
              <input #matInputContractStatusSearch placeholder="ContractStatus" matInput type="text"
                (keyup)="onKeyContractStatus($any($event.target).value)">
              <mat-option *ngFor="let status of contractStatusList" [value]="status">
                {{status.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field hideRequiredMarker="true" appearance="outline" color="accent" fxFlexOffset="1">
            <mat-label>Contract Creation Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker" required>
              <input matStartDate formControlName="createdDateFrom"
                placeholder="Start date">
              <input matEndDate formControlName="createdDateTo" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
      </div>
     </form>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end" >
        <button mat-raised-button fxFlexAlign="center" fxFlexOffset="2" type="button"
          (click)="onSubmit()" color="accent">Search</button>
        <button mat-raised-button type="button" fxFlexOffset="2" color="accent"
          fxFlexAlign="center" [disabled]="!resetEnabled"
          (click)="onResetFilter()">Reset</button>
    </div>
    <div>
      <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center"
          *ngIf="isLoadingResults">
        </mat-spinner>
      </div>
      <div>
        <section>
          <app-toolbar *ngIf="viewFeatures[2].accessable"  [toolbarItemList]="toolbarHelper.currentToolbar"
          (OnClick)="toolbarBtnClick($event)"></app-toolbar>
          <table mat-table [dataSource]="dataSource" matSortDisabled="{{hasNoData}}" matSort (matSortChange)="sortData($event)" multiTemplateDataRows>
            <ng-container matColumnDef="NetworkName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Network Name </th>
              <td mat-cell *matCellDef="let element"
                title="{{element.networkName}}">
                <a style="cursor: pointer;" *ngIf="viewFeatures[1].accessable" routerLink="/cm/contracts/contractview/{{element.contractId}}/view">
                {{element.networkName}}
              </a>
              <span *ngIf="!viewFeatures[1].accessable">
                {{element.networkName}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="PayerGroupName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Payer
              </th>
              <td mat-cell *matCellDef="let element"> {{element.payerGroupName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="ContractType">
              <th mat-header-cell *matHeaderCellDef> Contract Type
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngFor = "let type of element.contractContractTypes; let i=index">
                  <span class="list-format" *ngIf="i>0 && i!=element.contractContractTypes.length">,</span>
                  {{type.contractTypeDesc}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="CreatedDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
              <td mat-cell *matCellDef="let element">
                {{element.createdDate | date: 'MMM d, yyyy h:mm a'}} </td>
            </ng-container>
            <ng-container matColumnDef="CreatedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By
              </th>
              <td mat-cell *matCellDef="let element"> {{element.createdBy}}
              </td>
            </ng-container>
            <ng-container matColumnDef="LastModifiedDate" >
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified Date </th>
              <td mat-cell *matCellDef="let element">
                {{element.lastModifiedDate | date: 'MMM d, yyyy h:mm a'}} </td>
            </ng-container>
            <ng-container matColumnDef="LastModifiedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified By
              </th>
              <td mat-cell *matCellDef="let element"> {{element.lastModifiedBy}}
              </td>
            </ng-container>
            <ng-container matColumnDef="ContractStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status
              </th>
              <td mat-cell *matCellDef="let element"> {{element.contractStatusDesc}}
              </td>
            </ng-container>
            <ng-container matColumnDef="EffectiveStartDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective Start Date
              </th>
              <td mat-cell *matCellDef="let element"> {{element.effectiveStartDate | date: 'MMM d, yyyy'}}
              </td>
            </ng-container>
            <ng-container matColumnDef="LinksToContractPdfs">clickPdfs
              <th mat-header-cell *matHeaderCellDef> Links to the List of Contract PDFs
              </th>

              <td mat-cell *matCellDef="let element">
                <a class="inm-button--link inm-actions__link" *ngIf="element.contractPdfs != null && element.contractPdfs != undefined && element.contractPdfs.length > 0" style="cursor: pointer;" [routerLink]=""
                (click)="clickPdfs(element)"> <i class="fa-solid fa-file-pdf fa-lg"></i>
                </a>
                <span *ngIf="element.contractPdfs == null || element.contractPdfs  == undefined || element.contractPdfs.length == 0">&nbsp;</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="ContractId">
              <th mat-header-cell [hidden]="true" *matHeaderCellDef> Contract Id </th>
              <td mat-cell [hidden]="true" *matCellDef="let element">
                {{element.contractId}}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator #paginator [length]="totalItemCount" [pageIndex]="pageIndex"
            [pageSize]="30" [pageSizeOptions]="[5,10,15,30]" showFirstLastButtons
            (page)="onPageChange($event)">
          </mat-paginator>
        </section>
      </div>
    </div>
</div>
