import { AuthorizationService } from "src/app/services/authorization.service";

export class ManageTemplatesToolbarHelper {
    public showAction: string;
    public currentToolbar = [];
    private readonly TOOLBAR_ITEMS = [
      {
        feature: 'ContractManagement::AddEditTemplate',
        item: {
          label: 'Add Industry Contract Template Details',
          name: 'addTemplateDetails',
          tip: 'Add New Template Details',
          icon: '#inm-icon__plus-square',
          disabled: false,
          show: true,
        },
      },
    ];

    constructor(private authService: AuthorizationService) {
      this.generateToolbarData();
    }

    generateToolbarData(): void {
      for (const toolbar of this.TOOLBAR_ITEMS) {
        if (!toolbar.feature) {
          this.currentToolbar.push(toolbar.item);
          continue;
        }
        if (toolbar.feature && this.authService.hasFeature(toolbar.feature)) {
          this.currentToolbar.push(toolbar.item);
        }
      }
    }
}
