// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

//  *** Note: Instrumentation key is temporary so we can verify entries are being made.
//  Remove from here once done.

export const environment = {
  production: false,
  envName: 'dev',
  applicationNameContractManagement: 'Inmar.Healthcare.Cm',
  applicationDisplayName: 'CONTRACT MANAGEMENT',
  cmUrlRoot: 'https://dev-web-cm.healthcare.inmar.com',
  cmApiRoot: 'https://dev-api-cm.healthcare.inmar.com',
  authZApiRoot: 'https://dev-api-authz.healthcare.inmar.com/v1.0',
  masterDataApiRoot: 'https://dev-api-masterdata.healthcare.inmar.com/v1.0',
  healthcareTopBar:
    'https://dev-web-auth.healthcare.inmar.com/Scripts/healthcare-topbar.js',
  healthcareSignInAddress: 'https://dev-web-auth.healthcare.inmar.com/SignIn/SignIn',
  lookerAnalyticsRoot: 'https://devpfmanalytics.cloud.looker.com',
  lookerAdminRoot: 'https://dev-api-lookeradmin.healthcare.inmar.com'
};
