import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-multiple-checkbox',
  templateUrl: './multiple-checkbox.component.html',
  styleUrls: ['./multiple-checkbox.component.less']
})
export class MultipleCheckboxComponent {

  localCheckboxItemList: { label: string, value: string, selected: boolean }[] = [];
  maxLabelLength: number = 20;

  loadedBaseList: boolean = false;
  loadedCheckedList: boolean = false;

  @Input() canAddEdit: boolean = true;
  @Input() set data(value) {
    var checkboxItems = value.fullCheckboxItemList;
    let myLocalItems: { label: string, value: string, selected: boolean }[] = [];

    if (checkboxItems && checkboxItems.length > 0) {
      checkboxItems.forEach(function (item) {
        myLocalItems.push({ 'label': item.label, 'value': item.value, 'selected': false })
      });
    }

    var checboxSelectedItems = value.selectedCheckboxItemList;
    let myLocalSelectedItems: { label: string, value: string }[] = [];
    if (checboxSelectedItems && checboxSelectedItems.length > 0) {
      checboxSelectedItems.forEach(function (item) {
        myLocalSelectedItems.push({ 'label': item.label, 'value': item.value })
      });
    }

    if (myLocalSelectedItems && myLocalSelectedItems.length > 0) {
      myLocalItems.forEach(function (localCheckboxValue) {
        var index = myLocalSelectedItems.findIndex(function (localSelectCheckboxValue) {
          return localCheckboxValue.value === localSelectCheckboxValue.value;
        });
        if (index >= 0) {
          localCheckboxValue.selected = true;
        }
      });
    }

    this.localCheckboxItemList = myLocalItems;
    this.multiSelectedCheckboxList = myLocalSelectedItems;

    if (value.maxLabelLength) {
      this.maxLabelLength = value.maxLabelLength;
    }

  }

  @Output() sendMultiCheckBoxList: EventEmitter<any> = new EventEmitter<any>();

  multiSelectedCheckboxList: any[];

  constructor() {
    this.multiSelectedCheckboxList = [];
    this.localCheckboxItemList = [];
  }

  onCheckboxItemSelect(data: MatCheckboxChange, value: any) {
    if (data.checked) {
      // this list stores value as label and value
      this.multiSelectedCheckboxList.push({ 'label': value.value, 'value': value.value });
    } else {
      var index = this.multiSelectedCheckboxList.findIndex(item => item.value == value.value);
      this.multiSelectedCheckboxList.splice(index, 1);
    }

    this.sendMulitCheckboxList();
  }

  sendMulitCheckboxList() {
    this.sendMultiCheckBoxList.emit(this.multiSelectedCheckboxList);
  }
}
