import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FactorgpiDialogData } from 'src/app/models/FactorgpiDialogData';
import { ContractFactorService } from 'src/app/services/contractFactorService';

@Component({
  selector: 'app-addcontractfactorgpis',
  templateUrl: './addcontractfactorgpis.component.html',
  styleUrls: ['./addcontractfactorgpis.component.less']
})
export class AddcontractfactorgpisComponent {
  contractGpiForm: UntypedFormGroup;
  saveDisabled = true;
  cancelDisabled = false;
  radioOptionDisable = true;
  contractId: number;
  factorId: number;
  clientId: number;
  pagetitle = 'Add Factor GPIs';
  isLoadingResults = false;
  gpiCodes: string[] = [];
  validateNumeric: string[] = [];
  validateDuplicate: string[] = [];
  validateLength: string[] = [];
  validateMaxLength: string[] = [];
  validate: string[] = [];
  isExclude = false;
  addOnBlur = true;
  readonly separatorKeysCodes = [COMMA, ENTER, SPACE] as const;
  errorMessage: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: FactorgpiDialogData,
    private contractFactorService: ContractFactorService,
    public dialogRef: MatDialogRef<AddcontractfactorgpisComponent>
  ) { }

  ngOnInit() {
    this.isExclude = this.data.isExcluded;
    this.radioOptionDisable = this.data.factorId == 0 ? false : this.radioOptionDisable;
  }


  add(event: MatChipInputEvent): void {
    const value = event.value.trim();
    const regex = /^[0-9]+$/i;

    if (value) {
      this.errorMessage = '';
      const codeList = value.split(/[\s, ;\r?\n]+/);
      codeList.forEach((val) => {
        if (codeList.length > 0) {
          if (!val.match(regex)) {
            this.validateNumeric.push(val);
          } else if (this.gpiCodes.includes(val)) {
            this.validateDuplicate.push(val);
          } else if (this.gpiCodes.length >= 50) {
            this.validateMaxLength.push(val);
          } else if (val.length != 10 && val.length != 14) {
            this.validateLength.push(val);
          } else {
            this.gpiCodes.push(val.trim());
          }
        }
      });
      if (this.validateNumeric.length > 0) {
        this.errorMessage += 'The following GPIs should be numeric. <br/>' + this.validateNumeric.toString() + '<br/>';
        this.validateNumeric.splice(0);
      }
      if (this.validateDuplicate.length > 0) {
        this.errorMessage += 'The following GPIs are duplicates. <br/>' + this.validateDuplicate.toString() + '<br/>';
        this.validateDuplicate.splice(0);
      }
      if (this.validateLength.length > 0) {
        this.errorMessage += 'The GPIs can only be 10 or 14 characters. <br/>' + this.validateLength.toString() + '<br/>';
        this.validateLength.splice(0);
      }
      if (this.validateMaxLength.length > 0) {
        this.errorMessage += 'Cannot add more than 50 GPIs at a time. The following are removed. <br/>' + this.validateMaxLength.toString();
        this.validateMaxLength.splice(0);
      }
      event.input.value = '';
    }

  }

  remove(value: string): void {
    const index = this.gpiCodes.indexOf(value.trim());
    this.errorMessage = '';
    if (index >= 0) {
      this.gpiCodes.splice(index, 1);
    }
  }

  canSubmit(): boolean {
    return this.gpiCodes.length > 0;
  }

  saveGpis() {
    this.saveDisabled = true;
    this.cancelDisabled = true;
    this.contractId = this.data.contractId;
    this.factorId = this.data.factorId;

    this.contractFactorService.addContractFactorGpis(this.isExclude, this.gpiCodes, this.contractId, this.factorId).subscribe(
      (results) => {
        const returnData: string = 'close'
        this.isLoadingResults = false;
        this.dialogRef.close(returnData);
        toastr.success('Factor GPI added successfully!');
        this.saveDisabled = true;
      },
      (err) => {
        toastr.error('We are unable to add GPIs at this time');
      }
    )
    this.gpiCodes = new Array<string>();
  }
}
