import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'lodash';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { GlobalFilterService } from './global-filter.service';
import { PharmacyStore } from '../models/PharmacyStore';

@Injectable({
  providedIn: 'root',
})
export class PayerService {
  private readonly Timeout: number = 30000;
  apiRoot: string;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService) {
    this.apiRoot = environment.cmApiRoot;
  }

  public getPayerList(): Observable<string[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get<string[]>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/masterdata/GetDistinctPayerNamesByPayerTypes`);
  }

  public getRetailBannerList(
  ): Observable<string[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get<string[]>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/masterdata/GetRetailBanner`, {headers: headers});
  }

  public getStoreList(
    filterCriteria: string
    ): Observable<PharmacyStore[]> {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      const criteria = "{'Criteria': '" + this.globalFilterService.selectedClientId + ' ' + filterCriteria + "'}";
      var searchCriteria:string = this.globalFilterService.selectedClientId + ' ' + filterCriteria;
      return this.http.get<PharmacyStore[]>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/masterdata/GetStoreList?criteria=${searchCriteria}`);
    }
}
