<h3 mat-dialog-title class="inm-headline">&nbsp;&nbsp;{{pagetitle}}</h3>

<div mat-dialog-content>
  <div>
    <br>
    <mat-hint style="font-size: smaller; margin-left: 8px;">Comma, semicolon, space separated list of GPI10/GPI14 only
      (max 50)
    </mat-hint>
    <div>
      <mat-form-field style="width: 700px;" color="accent" fxFlexOffset="1">

        <mat-label>Factor GPI's</mat-label>
        <mat-chip-grid required="true" #matchipList aria-label="GPIs">
          <mat-chip *ngFor="let code of gpiCodes" (removed)="remove(code)">
            {{code}}
            <button matChipRemove class="no-border no-background">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input required placeholder="GPIs..." [matChipInputFor]="matchipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-grid>
      </mat-form-field>

    </div>
    <div>
    </div>
  </div>
  <div class="error-text" [innerHTML]="errorMessage"></div>
  <div class="float-child" style="width: 100%;">
    <section class='inm-actions--confirmation'>
      <button class="inm-button--action button-spacing" [disabled]="!canSubmit()" (click)="saveGpis()">Save</button>
      <button class="inm-button button-spacing" [disabled]="cancelDisabled" [mat-dialog-close]="true">Cancel</button>
    </section>
  </div>

</div>