import { IndustryContractTemplateContractTypes } from "./IndustryContractTemplateContractTypes";
import { IndustryContractTemplateDetail } from "./IndustryContractTemplateDetail";

export class IndustryContractTemplateResponse {
  industryContractTemplateId: number = 0;
  payerGroupName: string = "";
  networkName: string = "";
  industryContractTemplateContractTypes: IndustryContractTemplateContractTypes[] = [];
  industryContractTemplateDetails: IndustryContractTemplateDetail[] = [];
}
