import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ContractPdf } from 'src/app/models/ContractPdf';
import { ContractPdfDetail } from 'src/app/models/ContractPdfDetail';

import { ViewContractPdfsDialogData } from './ViewContractPdfsDIalogDataModel';

@Component({
  selector: 'app-view-contract-pdfs',
  templateUrl: './view-contract-pdfs.component.html',
  styleUrls: ['./view-contract-pdfs.component.scss']
})
export class ViewContractPdfsComponent implements OnInit, AfterViewInit {

  contractPdsForm: UntypedFormGroup;
  dataSource: any;
  contractPDFs: MatTableDataSource<ContractPdf>;
  contractId: number;
  clientId: number;
  count: number;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ViewContractPdfsDialogData
  )
  {
    this.data.action = 'view';
  }

  displayedColumns: string[] = [
    'contractFileName'
  ];

  ngOnInit(): void {
    this.contractPdsForm = this.fb.group( {
      title: [
        {value:'Title'}
      ],
      contractId: [
        {value: 0}
      ],
      clientId: [
        {value: 0}
      ],
      contractPDFs: [
        {value: new MatTableDataSource<ContractPdfDetail>}
      ]
    });

    this.contractId = this.data.contractId;
    this.clientId = this.data.clientId;
    this.count = this.data.count;
  }

  ngAfterViewInit(): void {

  }
}
