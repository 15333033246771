import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ToolbarAction } from '../shared/table-toolbar/ToolBarAction';

export interface IToolbarItem {
  label?: string;
  name: string;
  tip: string;
  icon: string;
  disabled?: boolean;
  show: boolean;
  exclude?: boolean;
}

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent {
  @Input() toolbarItemList: IToolbarItem[];
  @Input() canAddEdit: boolean = true;
  @Output() OnClick: EventEmitter<ToolbarAction> = new EventEmitter<ToolbarAction>();
  disabled = true;
  constructor() { }

  toolbarBtnClick(name: string, index: number): void {
    this.OnClick.emit({ name, index });
  }
}
