<div>
  <app-toolbar
    class="flex-child"
    style="margin-top: 0"
    [toolbarItemList]="manageRateToolbarHelper.currentToolbar"
    (OnClick)="toolbarBtnClick($event)"
    [canAddEdit]="canAddEdit"
  ></app-toolbar>
</div>
<div style="overflow: auto; max-height: 250px">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disabled]="selectionDisabled || !canAddEdit"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="
            $event ? selection.toggle(row) && enabledisableActions() : null
          "
          [checked]="selection.isSelected(row)"
          [disabled]="selectionDisabled || !canAddEdit"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="rateId">
      <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header>
        Rate ID
      </th>
      <td
        hidden="true"
        mat-cell
        *matCellDef="let element"
        title="{{ element.rateId }}"
      >
        {{ element.rateId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="rateTypeDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rate Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.rateTypeDescription }}
      </td>
    </ng-container>
    <ng-container matColumnDef="priceTypeCodeDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Price Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.priceTypeCodeDescription }}
      </td>
    </ng-container>
    <ng-container matColumnDef="appliedRate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rate</th>
      <td mat-cell *matCellDef="let element">
        {{ element.appliedRate | number : "1.2-2" }}%
      </td>
    </ng-container>
    <ng-container matColumnDef="dispensingFee">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dispensing Fee</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dispensingFee | currency }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dirType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DIR Type</th>
      <td mat-cell *matCellDef="let element">{{ element.dirType }}</td>
    </ng-container>
    <ng-container matColumnDef="assessmentWindowDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Assessment Window
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.assessmentWindowDescription }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dirAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dirAmount | currency }}
      </td>
    </ng-container>
    <ng-container matColumnDef="daysSupply">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Days Supply</th>
      <td mat-cell *matCellDef="let element">{{ element.daysSupply }}</td>
    </ng-container>
    <ng-container matColumnDef="rateEffectiveStartDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Effective Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.rateEffectiveStartDate | date : "MMM d, yyyy" }} -
        {{ element.rateEffectiveEndDate | date : "MMM d, yyyy" }}
      </td>
    </ng-container>
    <ng-container *ngIf="viewFeatures[0].accessable" matColumnDef="editAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div
          style="width: 75px"
          class="ui-toolbar-group-left"
          *ngFor="let item of currentActions; let i = index"
        >
          <button
            class="inm-button"
            *ngIf="item.show"
            [disabled]="item.disabled || !canAddEdit || editDisabled"
            type="button"
            matTooltip="{{ item.tip }}"
            (click)="editRate(element)"
          >
            <svg class="inm-icon" aria-label="item.tip" *ngIf="item.icon">
              <use [attr.xlink:href]="item.icon">&nbsp;{{ item.label }}</use>
            </svg>
            <div *ngIf="!item.icon">
              {{ item.label }}
            </div>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="header-row-first-group" sticky="true">
      <th
        mat-header-cell
        *matHeaderCellDef
        style="text-align: center"
        [attr.colspan]="11"
      >
        {{ title }}
      </th>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="['header-row-first-group']; sticky: true"
    ></tr>
    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      class="second-row"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
