<h3 mat-dialog-title class="inm-headline">&nbsp;&nbsp;{{data.title}}</h3>
<div mat-dialog-content class="zero-margin-right">
  <div fxLayout="column" fxLayoutAlign="space-around center">
    <div class="float-child">
      <form [formGroup]="templateForm" [style.fontSize.px]="14" class="content form-content">
        <div>
          <mat-form-field appearance="outline" color="accent" style="width: 100%;">
            <mat-label>Network Name</mat-label>
            <input maxlength="50" (input)="checkSaveEnabled()" title="Network Name" matInput formControlName="networkNameCtrl" type="string">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Payer</mat-label>
            <mat-select (selectionChange)="checkSaveEnabled()" formControlName="payerGroupNameCtrl" [compareWith]="compareObjects">
              <input #matInputPayerSearch placeholder="Payer" matInput type="text" 
                (keyup)="onKeyPayer($any($event.target).value)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let payer of filteredPayers" [value]="payer">
                {{payer.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" color="accent" fxFlexOffset="2">
            <mat-label>Contract Type</mat-label>
            <mat-select (selectionChange)="checkSaveEnabled()" [compareWith]="compareObjects" formControlName="contractTypesCtrl" multiple>
              <mat-option *ngFor="let contractType of contractTypes" [value]="contractType"
                [selected]="contractTypeList">
                {{contractType.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>

  <section class='inm-actions--confirmation'>
    <button class="inm-button--action button-spacing" [disabled]="saveDisabled || isLoadingResults" (click)="saveTemplate()">Save</button>
    <button class="inm-button button-spacing" [disabled]="isLoadingResults" [mat-dialog-close]="true">Cancel</button>
  </section>
</div>


