import { formatDate, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { AddEditDefinitionDialogDataModel } from 'src/app/models/add-edit-definition-dialog-data-model';
import { ContractAmendment } from 'src/app/models/contract-amendment';
import { ContractAmendmentResponse } from 'src/app/models/contract-amendment-response';
import { ContractByIdResponse } from 'src/app/models/ContractByIdResponse';
import { ContractContractType } from 'src/app/models/ContractContractType';
import { ContractDefinition } from 'src/app/models/ContractDefinition';
import { ContractDefinitionActionEmitter } from 'src/app/models/ContractDefinitionActionEmitter';
import { ContractDocument } from 'src/app/models/ContractDocument';
import { ContractNote } from 'src/app/models/ContractNote';
import { ContractPaginationResult } from 'src/app/models/ContractPaginationResult';
import { ContractRate } from 'src/app/models/ContractRate';
import { Pagination } from 'src/app/models/Pagination';
import { ContractAmendmentService } from 'src/app/services/contract-amendment.service';
import { ContractDefinitionService } from 'src/app/services/contract-definition.service';
import { ContractDocumentService } from 'src/app/services/contractDocumentService';
import { ContractFactorService } from 'src/app/services/contractFactorService';
import { ContractRateService } from 'src/app/services/contractRateService';
import { ContractStatusService } from 'src/app/services/contractStatusService';
import { GlobalFilterService } from 'src/app/services/global-filter.service';
import * as toastr from 'toastr';

import { ContractDetail } from '../../../models/ContractDetail';
import { AuthorizationService } from '../../../services/authorization.service';
import { ContractDetailService } from '../../../services/contract-detail.service';
import { ContractService } from '../../../services/contractService';
import { ContractTypeService } from '../../../services/contractTypeService';
import { AmendmentsGridComponent } from '../../shared/amendments-grid/amendments-grid.component';
import {
  ActionItem,
  ContractDetailsComponent,
} from '../../shared/contract-details/contract-details.component';
import { DefinitionsGridComponent } from '../../shared/definitions-grid/definitions-grid.component';
import { RatesGridComponent } from '../../shared/rates-grid/rates-grid.component';
import { ToolbarAction } from '../../shared/table-toolbar/ToolBarAction';
import { ActivateContractDialogComponent } from '../activate-contract-dialog/activate-contract-dialog/activate-contract-dialog.component';
import { AddEditDefinitionDialogComponent } from '../add-edit-definition-dialog/add-edit-definition-dialog.component';
import { AddEditRateDialogData } from '../add-edit-rate-dialog/add-edit-rate-dialog-data';
import { AddEditRateDialogComponent } from '../add-edit-rate-dialog/add-edit-rate-dialog.component';
import { AmendContractComponent } from '../amend-contract/amend-contract.component';
import { CreateEditDetailData } from '../create-edit-detail/create-edit-detail-data';
import { CreateEditDetailComponent } from '../create-edit-detail/create-edit-detail.component';
import { ContractCreateEditDialogData } from '../create-edit/ContractCreateEditDialogDataModel';
import { CreateEditContractsComponent } from '../create-edit/create-edit-contracts.component';
import { CreateContractnotesComponent } from '../create-notes/create-contractnotes-component/create-contractnotes-component';
import { AddDefinitionToolbarHelper } from './helper/add-definition-toolbar.helper';
import { AddFactorToolbarHelper } from './helper/add-factor-toolbar.helper';
import { ContractDetailsActionItemsHelper } from './helper/contract-details-action-items.helper';
import { ManageContractsToolbarHelper } from './helper/manage-contracts-toolbar.helper';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog/confirm-dialog.component';
import { SelectControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-manage-contract',
  templateUrl: './manage-contract.component.html',
  styleUrls: ['./manage-contract.component.scss'],
})
export class ManageContractComponent implements OnInit {
  pagetitle: string;
  isLoadingResults = false;
  dataSource: MatTableDataSource<ContractDetail> =
    new MatTableDataSource<ContractDetail>();

  contractTypeList: SelectItem[];
  contractTypes: SelectItem[] = [];
  localContractStatusList: SelectItem[] = [];

  ContractNotesdata: ContractNote[] = [];

  contractTypeResponseList: ContractContractType[] = [];
  localContractTypes: SelectItem[];
  localContractTypesList: SelectItem[] = [];
  contractDetails: ContractDetail[] = [];
  contractDetailData: any;
  contractRatesTraditional: ContractRate[];
  contractRatesEffective: ContractRate[];
  contractRatesTraditionalData: any;
  contractRatesEffectiveData: any;
  contractDefinitions: ContractDefinition[];
  contractDefinitionData: any;
  contractAmendments: ContractAmendment[] = [];
  contractAmendmentData: any;
  contractDocumentsData: ContractDocument[] = [];

  Sort = '';
  sortOrder = 'asc';
  offset = 0;
  limit = 30;

  detailSort = '';
  detailSortOrder = 'asc';
  detailOffset = 0;
  detailLimit = 30;

  definitionSort = '';
  definitionsortOrder = 'asc';
  definitionoffset = 0;
  definitionlimit = 30;
  pageIndex = 0;

  rateSort = '';
  ratesortOrder = 'asc';
  rateoffset = 0;
  ratelimit = 30;

  contractDetailCount: number;
  contractDefinitionCount: number;

  ContractDocumentSort = 'contractPdfId';
  contractDocumentSortOrder = 'desc';
  contractDocumentOffset = 0;
  contractDocumentLimit = 30;
  contractDocumentPageIndex = 0;

  fileUploadData: any;

  hasNoData: boolean = true;
  contractId: number;
  clientId: string;
  contractStatus: number;
  mode: string;
  canAddEdit: boolean = true;
  canAddNoteDoc: boolean = true;

  private router: Router;
  public contractRates$: Observable<ContractRate[]>;

  contractByIdResponse: ContractByIdResponse = new ContractByIdResponse();

  public readonly toolbarHelper: ManageContractsToolbarHelper;
  public readonly contractDetailsActionItemsHelper: ContractDetailsActionItemsHelper;
  public readonly addDefinitionToolbarHelper: AddDefinitionToolbarHelper;
  public readonly addFactorToolbarHelper: AddFactorToolbarHelper;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('contractDetailsComponent', { static: false })
  contractDetailsComponent: ContractDetailsComponent;
  @ViewChild('traditionalRatesComponent', { static: false })
  traditionalRatesComponent: RatesGridComponent;
  @ViewChild('effectiveRatesComponent', { static: false })
  effectiveRatesComponent: RatesGridComponent;
  @ViewChild('definitionGridComponent', { static: false })
  definitionGridComponent: DefinitionsGridComponent;
  @ViewChild('amendmentGridComponent', { static: false })
  amendmentGridComponent: AmendmentsGridComponent;

  displayedColumns: string[] = [
    'BIN',
    'PCN',
    'GroupNumber',
    'NetworkId',
    'ContractDetailId',
    'ContractId',
  ];

  public readonly viewFeatures = [
    {
      feature: 'ContractManagement::ViewContract',
      name: 'view',
      accessable: false,
    },
    {
      feature: 'ContractManagement::AddEditContract',
      name: 'edit',
      accessable: false,
    },
  ];

  constructor(
    private contractTypeService: ContractTypeService,
    private contractStatusService: ContractStatusService,
    private contractService: ContractService,
    private contractDetailService: ContractDetailService,
    private contractFactorservice: ContractFactorService,
    private contractDefinitionService: ContractDefinitionService,
    private authorizationService: AuthorizationService,
    private contractRateService: ContractRateService,
    private contractDocumentService: ContractDocumentService,
    private contractAmendmentService: ContractAmendmentService,
    private route: ActivatedRoute,
    private globalFilterService: GlobalFilterService,
    public dialog: MatDialog,
    private location: Location
  ) {
    this.toolbarHelper = new ManageContractsToolbarHelper(
      this.authorizationService
    );
    this.addDefinitionToolbarHelper = new AddDefinitionToolbarHelper(
      this.authorizationService
    );
    this.contractDetailsActionItemsHelper =
      new ContractDetailsActionItemsHelper(this.authorizationService);
  }

  ngOnInit(): void {
    this.contractId = +this.route.snapshot.paramMap.get('id');
    this.route.paramMap.subscribe(async (val) => {
      this.contractId = +this.route.snapshot.paramMap.get('id');
      this.mode = this.route.snapshot.paramMap.get('mode');
      this.clientId = this.globalFilterService.selectedClientId;

      this.pagetitle = 'Manage Contract';
      this.checkFeatures();

      //  Need to ensure we have the contract status first so that each tab can use
      //  this value to enable/disable based on status.
      await this.loadContract();

      this.loadContractDetails();
      this.loadContractRates('all');
      this.loadContractDefinitions();
      this.loadContractNotes();
      this.loadContractDocuments();
      this.loadDocumentFileUploadData();
      this.loadContractAmendments();
    });
  }

  loadContractRates(typeToLoad: string) {
    this.contractRateService
      .getContractRatesForContractId(this.contractId)
      .subscribe((rates: ContractRate[]) => {
        if (typeToLoad === 'traditional' || typeToLoad === 'all') {
          this.contractRatesTraditional = [];
        }

        if (typeToLoad === 'effective' || typeToLoad === 'all') {
          this.contractRatesEffective = [];
        }

        rates.forEach((part, index) => {
          if (part.isTraditional && (typeToLoad === 'traditional' || typeToLoad === 'all')) {
            this.contractRatesTraditional.push(part);
          } 
          if (!part.isTraditional && (typeToLoad === 'effective' || typeToLoad === 'all')){
            this.contractRatesEffective.push(part);
          }
        });

        if (typeToLoad === 'traditional' || typeToLoad === 'all') {
          this.contractRatesTraditionalData = {
            rateDesc: 'Traditional Rates',
            data: this.contractRatesTraditional,
            isTraditional: true,
          };
        }

        if (typeToLoad === 'effective' || typeToLoad === 'all') {
          this.contractRatesEffectiveData = {
            rateDesc: 'Effective Rates',
            data: this.contractRatesEffective,
            isTraditional: false,
          };
        }

        if (
          this.contractByIdResponse.contractStatusDesc.toLowerCase() !==
          'pending'
        ) {
          this.traditionalRatesComponent.manageRateToolbarHelper.disableToolbar();
          this.traditionalRatesComponent.disableSelection();
          this.effectiveRatesComponent.manageRateToolbarHelper.disableToolbar();
          this.effectiveRatesComponent.disableSelection();
        }
      });
  }

  loadContractNotes(): void {
    this.contractService
      .getContractNotes(
        new Pagination(this.limit, this.offset, this.Sort, this.sortOrder),
        this.contractId
      )
      .subscribe(
        (results) => {
          var loc = results;
          this.ContractNotesdata = results.items;
          this.disableNonPendingActions(this.toolbarHelper.currentToolbarNote);
        },
        (err) => {
          toastr.error('We are unable to load ContractNotes at this time');
        }
      );
  }

  loadAllContractTypes(): void {
    this.localContractTypesList = this.contractTypeService.types;
  }

  private disableNonPendingActions(toolbar): void {
    toolbar.every((item) => {
      item.disabled =
        item.name === 'addContractNote'
          ? !this.canAddNoteDoc
          : !this.canAddEdit;
    });
  }

  async loadContract() {
    var localContractId = this.contractId;

    this.isLoadingResults = true;

    var result = await this.contractService
      .getContract(this.contractId)
      .toPromise();

    if (result == undefined || result == null) {
      toastr.error('We are unable to load Contracts at this time');
      this.isLoadingResults = false;
      return;
    }
    this.canAddEdit =
      (result.contractStatusId == null ||
        result.contractStatusId == undefined ||
        result.contractStatusId === 3) &&
      this.viewFeatures[1].accessable; //  3=Pending and has feature access.

    this.canAddNoteDoc =
      (result.contractStatusId == null ||
        result.contractStatusId == undefined ||
        result.contractStatusId === 3) &&
      this.viewFeatures.some((f) => f.accessable); //  3=Pending and has feature access.

    this.contractStatus = result.contractStatusId;

    this.contractByIdResponse = null;
    this.contractByIdResponse = result;

    this.contractTypeResponseList = [];
    var localResponseList = this.contractTypeResponseList;
    this.loadAllContractTypes();
    var localContractTypesB = this.localContractTypesList;

    if (this.contractByIdResponse.contractContractTypes != null) {
      this.contractByIdResponse.contractContractTypes.forEach(function (
        part,
        index
      ) {
        if (part != null && localContractTypesB) {
          localContractTypesB.forEach(function (part2, index2) {
            if (part2.value == part) {
              localResponseList.push(
                new ContractContractType(
                  localContractId,
                  part2.value,
                  part2.label
                )
              );
            }
          });
        }
      });
    }

    if (localResponseList) {
      localResponseList.sort((a, b) =>
        a.contractTypeDesc > b.contractTypeDesc ? 1 : -1
      );
    }

    this.loadAllLocalContractStatuses();
    var localContractStatusesB = this.localContractStatusList;
    if (
      this.contractByIdResponse.contractStatusDesc == null ||
      this.contractByIdResponse.contractStatusDesc === ''
    ) {
      var contractStatusDescription: string = '';
      var contractStatusId = this.contractByIdResponse.contractStatusId;
      if (this.contractByIdResponse.contractStatusId) {
        localContractStatusesB.forEach(function (part1, index) {
          if (part1.value == contractStatusId) {
            contractStatusDescription = part1.label;
          }
        });
      }
      this.contractByIdResponse.contractStatusDesc = contractStatusDescription;
    }

    this.disableNonPendingActions(this.toolbarHelper.currentToolbar);
    this.isLoadingResults = false;
  }

  loadContractDetails() {
    this.contractDetailService
      .getContractDetailsForContractId(
        new Pagination(
          this.detailLimit,
          this.detailOffset,
          this.detailSort,
          this.detailSortOrder
        ),
        this.contractId
      )
      .subscribe(
        (results) => {
          this.contractDetails = results.items;
          this.contractDetailCount = results.totalItemCount;

          this.detailLimit = results.limit;
          this.detailOffset = results.offset;

          this.dataSource = new MatTableDataSource<ContractDetail>(
            results.items
          );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          this.contractDetailData = {
            actions: 'Edit',
            data: this.contractDetails,
            count: results.totalItemCount,
            hasContractDetails: true,
          };
          this.disableNonPendingActions(this.toolbarHelper.currentToolbar);
        },
        (err) => {
          toastr.error('We are unable to load Contract Details at this time');
        }
      );
  }

  sortChanged(sort: Sort) {
    this.detailSort = sort.active;
    this.detailSortOrder = sort.direction;

    this.loadContractDetails();

    if (this.paginator == null || this.paginator == undefined) {
      return;
    }
    this.paginator.firstPage();
  }

  pageChanged(page: PageEvent) {
    this.detailLimit = page.pageSize;
    this.pageIndex = page.pageIndex;
    this.detailOffset = page.pageIndex * page.pageSize;

    this.loadContractDetails();
  }

  loadContractDefinitions() {
    this.contractDefinitionService
      .getContractDefinitionsForContractId(
        new Pagination(
          this.definitionlimit,
          this.definitionoffset,
          this.definitionSort,
          this.definitionsortOrder
        ),
        this.contractId
      )
      .subscribe(
        (defs) => {
          this.contractDefinitions = [];

          var tempContractDefinitions =
            defs as ContractPaginationResult<ContractDefinition>;
          this.contractDefinitionCount = defs.totalItemCount;

          tempContractDefinitions.items.forEach((def, index) => {
            this.contractDefinitions.push(def);
          });

          this.contractDefinitionData = {
            action: 'Edit',
            data: this.contractDefinitions,
            count: this.contractDefinitionCount,
          };
          this.disableNonPendingActions(
            this.addDefinitionToolbarHelper.currentToolbar
          );
        },
        (err) => {
          toastr.error('We are unable to load Definitions at this time');
        }
      );
  }

  loadDocumentFileUploadData() {
    this.fileUploadData = {
      fileUploadComponent: 'DocumentUpload',
      contractId: this.contractId,
      maxFileSize: 20480000,
      acceptedFileTypes: ['application/pdf'],
      message: 'Drag and drop .pdfs here',
      topMessage: 'Up to 5 files per load. 20MB limit per file.',
      errorMessage:
        'PDF files only.  20MB limit per file.  5 files per batch. No duplicate file names.',
      checkForDuplicateNames: true,
      maxNumberOfFiles: 5,
    };
  }

  loadContractDocuments(): void {
    this.contractDocumentsData = [new ContractDocument()];
  }

  loadContractAmendments(): void {
    this.contractAmendmentService
      .getContractAmendmentsByContractId(
        new Pagination(this.limit, this.offset, this.Sort, this.sortOrder),
        this.contractId
      )
      .subscribe(
        (results: ContractAmendmentResponse) => {
          console.log(results);
          this.hasNoData = results.totalItemCount === 0;
          console.log(this.hasNoData);
          this.contractAmendments = [];
          results.items.forEach((val, index) => {
            this.contractAmendments.push(val);
          });
          this.contractAmendmentData = {
            data: results.items,
          };
        },
        (err) => {
          toastr.error(
            'We are unable to load Contract Amendments at this time'
          );
        }
      );
  }

  retrieveLatestDocuments(response: string): void {
    this.ContractDocumentSort = 'contractPdfId';
    this.contractDocumentSortOrder = 'desc';
    this.loadContractDocuments();
  }

  loadAllLocalContractStatuses() {
    this.localContractStatusList = this.contractStatusService.statuses;
  }

  editDefinition(data: ContractDefinition) {
    var definition = new ContractDefinitionActionEmitter();
    (definition.action = 'Edit'), (definition.definition = data);

    this.updateDefinition(definition);
  }

  toolbarBtnClick(event: ToolbarAction): void {
    switch (event.name) {
      case 'addContractDetails': {
        this.createContractDetailsDialog();
        break;
      }
      case 'addRate': {
        break;
      }
      case 'addFactor': {
        //  TODO: Add Contract Factor
        break;
      }
    }
  }

  addDefinition(event: ToolbarAction) {
    const definitionToAdd: ContractDefinition = {
      definitionId: 0,
      definitionType: '',
      mony: '',
      tbg: '',
      daw: '',
    };

    const dialogRef = this.dialog.open(AddEditDefinitionDialogComponent, {
      width: '420px',
      maxHeight: '825px',
      data: {
        action: 'add',
        contractId: this.contractId,
        clientId: this.globalFilterService.selectedClientId,
        definition: definitionToAdd,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadContractDefinitions();
      this.definitionGridComponent.renderTableRows();
    });
  }

  updateDefinition(definition: ContractDefinitionActionEmitter): void {
    if (definition.action === 'Edit') {
      const dialogRef = this.dialog.open(AddEditDefinitionDialogComponent, {
        width: '420px',
        maxHeight: '825px',
        data: {
          action: 'edit',
          contractId: this.contractId,
          clientId: this.globalFilterService.selectedClientId,
          definition: definition.definition,
        },
      });
      dialogRef
        .afterClosed()
        .subscribe((definition: AddEditDefinitionDialogDataModel) => {
          if (definition.action === 'close') {
            var indexToRemove = this.contractDefinitions.findIndex(
              (x) => x.definitionId == definition.definitionId
            );
            this.contractDefinitions.splice(indexToRemove, 1);
            this.contractDefinitions.splice(0, 0, definition.definition);
            this.definitionGridComponent.renderTableRows();
          }
        });
    } else {
      this.definitionlimit = definition.pagination.limit;
      this.definitionoffset = definition.pagination.offset;
      this.definitionSort = definition.pagination.sort;
      this.definitionsortOrder = definition.pagination.sortOrder;
      this.loadContractDefinitions();
      this.contractDefinitionData = {
        action: 'pagination',
        data: this.contractDefinitions,
        count: this.contractDefinitionCount,
      };
      // this.definitionsGridComponent.disableSpinner();
    }
  }

  toolbarBtnClicknote() {
    const dialogRef = this.dialog.open(CreateContractnotesComponent, {
      width: '800px',
      maxHeight: '1200px',
      data: {
        action: 'add',
        contractId: this.contractId,
        clientId: this.globalFilterService.selectedClientId,
      },
    });
    dialogRef.afterClosed().subscribe((returnedData: string) => {
      if (returnedData === 'close') {
        this.loadContractNotes();
      }
    });
  }

  actionOnClick(event: ActionItem) {
    if (event.name == 'editContractDetails') {
      this.updateContractDetail(event.element);
    }
  }

  createContractDetailsDialog() {
    const detailsToAdd: ContractDetail = {
      contractDetailId: 0,
      contractId: this.contractId,
      bin: '',
      groupNumber: '',
      pcn: '',
      networkId: '',
    };

    const dialogRef = this.dialog.open(CreateEditDetailComponent, {
      data: {
        action: 'add',
        title: 'Add Contract Details',
        detail: detailsToAdd,
      },
    });

    dialogRef.afterClosed().subscribe((returnedData: CreateEditDetailData) => {
      if (returnedData.action === 'close') {
        this.loadContractDetails();
      }
    });
  }

  updateContractDetail(detail: ContractDetail) {
    detail.contractId = this.contractId;
    const dialogRef = this.dialog.open(CreateEditDetailComponent, {
      data: {
        action: 'edit',
        title: 'Edit Contract Details',
        detail: detail,
      },
    });

    dialogRef.afterClosed().subscribe((returnedData: CreateEditDetailData) => {
      if (returnedData.action === 'close') {
        var indexToRemove = this.contractDetailData.data.findIndex(
          (x) => x.contractDetailId == returnedData.detail.contractDetailId
        );
        this.contractDetailData.data.splice(
          indexToRemove,
          1,
          returnedData.detail
        );
        // this.contractDetailData.data.push(returnedData.detail);
        this.contractDetailsComponent.renderTableRow();
      }
    });
  }

  cancel() {
    this.location.back();
  }

  clickEditContract(value) {
    this.viewContractEditScreen(value);
  }

  clickActivateContract(value) {
    this.viewContractActivateScreen(value);
  }

  viewContractActivateScreen(value): void {
    const dialogRef = this.dialog.open(ActivateContractDialogComponent, {
      width: '500px',
      maxHeight: '800px',
      data: {
        action: 'activate',
        contractId: value,
        contractStatus: this.contractStatus,
        strDate: this.contractByIdResponse.effectiveStartDate,
        endDate: this.contractByIdResponse.effectiveEndDate,
        clientId: this.globalFilterService.selectedClientId,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe((returnedData: ContractCreateEditDialogData) => {
        if (returnedData.action === 'close') {
          this.contractId = returnedData.contractId;
          this.clientId = this.globalFilterService.selectedClientId;
          this.mode = 'view';
          this.pagetitle = 'Manage Contract';
          this.contractStatus = returnedData.contract.contractStatusId;
          this.canAddEdit =
            returnedData.contract.contractStatusId == null ||
            returnedData.contract.contractStatusId == undefined ||
            returnedData.contract.contractStatusId === 3; //  3=Pending

          var localContractId = this.contractId;
          var localResponseList = this.contractTypeResponseList;
          this.loadAllContractTypes();
          var localContractTypesB = this.localContractTypesList;
          this.contractByIdResponse.contractStatusId =
            returnedData.contract.contractStatusId;
          this.contractByIdResponse.contractStatusDesc =
            returnedData.contract.contractStatusId === 1
              ? 'Active'
              : this.contractByIdResponse.contractStatusDesc;
          this.contractByIdResponse.effectiveStartDate =
            returnedData.contract.effectiveStartDate;
          this.contractByIdResponse.effectiveEndDate =
            returnedData.contract.effectiveEndDate;

          if (returnedData.contract.contractContractTypes != null) {
            returnedData.contract.contractContractTypes.forEach(function (
              part,
              index
            ) {
              if (part != null && localContractTypesB) {
                localContractTypesB.forEach(function (part2, index2) {
                  if (part2.value == part) {
                    localResponseList.push(
                      new ContractContractType(
                        localContractId,
                        part2.value,
                        part2.label
                      )
                    );
                  }
                });
              }
            });
          }

          if (localResponseList) {
            localResponseList.sort((a, b) =>
              a.contractTypeDesc > b.contractTypeDesc ? 1 : -1
            );
          }
          this.loadContractAmendments();
          this.loadAllLocalContractStatuses();
          var localContractStatusesB = this.localContractStatusList;
          if (
            this.contractByIdResponse.contractStatusDesc == null ||
            this.contractByIdResponse.contractStatusDesc === ''
          ) {
            var contractStatusDescription: string = '';
            var contractStatusId = this.contractByIdResponse.contractStatusId;
            if (this.contractByIdResponse.contractStatusId) {
              localContractStatusesB.forEach(function (part1, index) {
                if (part1.value == contractStatusId) {
                  contractStatusDescription = part1.label;
                }
              });
            }
            this.contractByIdResponse.contractStatusDesc =
              contractStatusDescription;
            this.contractByIdResponse.contractStatusId = contractStatusId;
          }
        }
        this.canAddNoteDoc =
          (this.contractStatus == null ||
            this.contractStatus == undefined ||
            this.contractStatus === 3) &&
          this.viewFeatures.some((f) => f.accessable); //  3=Pending and has feature access.
        this.refreshTabs();
      });
  }

  private refreshTabs(): void {
    this.disableNonPendingActions(this.toolbarHelper.currentToolbar);
    this.disableNonPendingActions(
      this.addDefinitionToolbarHelper.currentToolbar
    );
    this.disableNonPendingActions(this.toolbarHelper.currentToolbarNote);
  }

  viewContractEditScreen(value): void {
    const dialogRef = this.dialog.open(CreateEditContractsComponent, {
      width: '1075px',
      maxHeight: '800px',
      data: {
        action: 'edit',
        contractId: value,
        clientId: this.globalFilterService.selectedClientId,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe((returnedData: ContractCreateEditDialogData) => {
        if (returnedData.action === 'close') {
          this.contractId = returnedData.contractId;
          this.clientId = this.globalFilterService.selectedClientId;
          this.mode = 'view';
          this.pagetitle = 'Manage Contract';
          this.contractByIdResponse = returnedData.contract;

          var localContractId = this.contractId;
          this.contractTypeResponseList = [];
          var localResponseList = this.contractTypeResponseList;
          this.loadAllContractTypes();
          var localContractTypesB = this.localContractTypesList;

          if (returnedData.contract.contractContractTypes != null) {
            returnedData.contract.contractContractTypes.forEach(function (
              part,
              index
            ) {
              if (part != null && localContractTypesB) {
                localContractTypesB.forEach(function (part2, index2) {
                  if (part2.value == part) {
                    localResponseList.push(
                      new ContractContractType(
                        localContractId,
                        part2.value,
                        part2.label
                      )
                    );
                  }
                });
              }
            });
          }

          if (localResponseList) {
            localResponseList.sort((a, b) =>
              a.contractTypeDesc > b.contractTypeDesc ? 1 : -1
            );
          }

          this.loadAllLocalContractStatuses();
          var localContractStatusesB = this.localContractStatusList;
          if (
            this.contractByIdResponse.contractStatusDesc == null ||
            this.contractByIdResponse.contractStatusDesc === ''
          ) {
            var contractStatusDescription: string = '';
            var contractStatusId = this.contractByIdResponse.contractStatusId;
            if (this.contractByIdResponse.contractStatusId) {
              localContractStatusesB.forEach(function (part1, index) {
                if (part1.value == contractStatusId) {
                  contractStatusDescription = part1.label;
                }
              });
            }
            this.contractByIdResponse.contractStatusDesc =
              contractStatusDescription;
            this.contractByIdResponse.contractStatusId = contractStatusId;
          }
        }
      });
  }

  private checkFeatures(): void {
    for (const vwFeature of this.viewFeatures) {
      if (this.authorizationService.hasFeature(vwFeature.feature)) {
        vwFeature.accessable = true;
      }
    }
  }

  addContractRate(event: any, isTraditionalRate: boolean) {
    const rateToAdd: ContractRate = {
      rateId: 0,
      description: '',
      assessmentWindowId: 0,
      assessmentWindowDesc: '',
      rateTypeId: 0,
      rateTypeDescription: '',
      priceTypeCodeId: 0,
      priceTypeDescription: '',
      isTraditional: isTraditionalRate,
      adminOrTransactionFee: null,
      appliedRate: null,
      dirAmount: null,
      dirType: '',
      dispensingFee: null,
      rateEffectiveEndDate: null,
      rateEffectiveStartDate: null,
      daysSupply: this.contractByIdResponse.daysSupply,
    };

    const dialogRef = this.dialog.open(AddEditRateDialogComponent, {
      width: '1075px',
      maxHeight: '800px',
      data: {
        action: 'add',
        title: 'Add Rate',
        rate: rateToAdd,
        rateType: isTraditionalRate ? 'traditional' : 'effective',
        contractId: this.contractId,
        daysSupply: this.contractByIdResponse.daysSupply,
      },
    });

    dialogRef.afterClosed().subscribe((returnedData: AddEditRateDialogData) => {
      if (returnedData.action === 'close') {
        this.contractRateService
          .getContractRateForRateId(this.contractId, returnedData.rate.rateId)
          .subscribe((rate) => {
            if (returnedData.rateType === 'traditional') {
              this.contractRatesTraditional.push(rate);
              this.traditionalRatesComponent.renderTableRow();
            } else if (returnedData.rateType === 'effective') {
              this.contractRatesEffective.push(rate);
              this.effectiveRatesComponent.renderTableRow();
            } else {
              // log unknown rate
            }
          });
      }
    });
  }

  deleteContractRate(selection: any, rateType: any) {
    let width: string = '1200px';
    let maxHeight = '700px';

    let message = this.createDeleteRateDialogMessage(selection);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: width,
      maxHeight: maxHeight,
      data: {
        message: 'Are you sure want to Delete following Rates? <br/>' + message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
        title: 'Delete Rate',
      },
    });
    dialogRef.afterClosed().subscribe((returnedData: string) => {
      if (returnedData == 'close') {

        var rateIdsToSend = new Array;
        for (const rate of selection) {
          rateIdsToSend.push(rate.rateId);
        }

        this.contractRateService
          .deleteRate(rateIdsToSend, this.contractId)
          .subscribe(
            (results) => {
              toastr.success('Rates deleted successfully.');
              this.loadContractRates(rateType);
            },
            (err) => {
              toastr.error('We are unable to remove Rates at this time');
            }
          );
        if (rateType === 'traditional') {
          this.traditionalRatesComponent.selection.clear();
          this.traditionalRatesComponent.enabledisableActions();
        }
        if (rateType === 'effective') {
          this.effectiveRatesComponent.selection.clear();
          this.effectiveRatesComponent.enabledisableActions();
        }
      }
    });
  }

  createDeleteRateDialogMessage(selection: any[]): string {
    let message: string = '';
    for (const rate of selection) {
      message +=
        'Rate Type:' +
        rate.rateTypeDescription +
        ' Price Type:' +
        rate.priceTypeCodeDescription +
        ' Days Supply:' +
        rate.daysSupply +
        ' Rate:' +
        rate.appliedRate.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }) +
        ' Dispensing Fee:' +
        rate.dispensingFee.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }) +
        ' Effective Date:' +
        formatDate(rate.rateEffectiveStartDate, 'MMM d, yyyy', 'en_US') +
        '-' +
        formatDate(rate.rateEffectiveEndDate, 'MMM d, yyyy', 'en_US') +
        '<br/>';
    }
    return message;
  }

  amendContract() {
    const dialogRef = this.dialog.open(AmendContractComponent, {
      width: '800px',
      maxHeight: '1200px',
      data: {
        action: 'add',
        contractId: this.contractId,
        clientId: this.globalFilterService.selectedClientId,
      },
    });
    dialogRef.afterClosed().subscribe((returnedData: string) => {
      if (returnedData === 'close') {
        this.checkFeatures();
        this.canAddNoteDoc =
          (this.contractStatus == null ||
            this.contractStatus == undefined ||
            this.contractStatus === 3) &&
          this.viewFeatures.some((f) => f.accessable); //  3=Pending and has feature access.
      }
    });
  }

  updateContractRate(rate: ContractRate) {
    var rateType = rate.isTraditional ? 'traditional' : 'effective';
    const dialogRef = this.dialog.open(AddEditRateDialogComponent, {
      width: '1075px',
      maxHeight: '800px',
      data: {
        action: 'edit',
        title: 'Edit Rate',
        rate: rate,
        rateType: rateType,
        daysSupply: this.contractByIdResponse.daysSupply,
        contractId: this.contractByIdResponse.contractId,
      },
    });

    dialogRef.afterClosed().subscribe((returnedData: AddEditRateDialogData) => {
      if (returnedData.action === 'close') {
        this.contractRateService
          .getContractRateForRateId(this.contractId, returnedData.rate.rateId)
          .subscribe((rate) => {
            if (returnedData.rateType === 'traditional') {
              var indexToRemove = this.contractRatesTraditional.findIndex(
                (x) => x.rateId == rate.rateId
              );
              this.contractRatesTraditional.splice(indexToRemove, 1);
              this.contractRatesTraditional.push(rate);
              this.traditionalRatesComponent.renderTableRow();
            } else if (returnedData.rateType === 'effective') {
              var indexToRemove = this.contractRatesEffective.findIndex(
                (x) => x.rateId == rate.rateId
              );
              this.contractRatesEffective.splice(indexToRemove, 1);
              this.contractRatesEffective.push(rate);
              this.effectiveRatesComponent.renderTableRow();
            } else {
              // log unknown rate type
            }
          });
      }
    });
  }
}
