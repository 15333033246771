import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class BaseService {
  private headers: HttpHeaders;
  constructor(private cookieService: CookieService) {
    this.headers = new HttpHeaders();
    this.headers.append('Authorization', `Bearer ${this.cookieService.get('id_token')}`);
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
  }

  getHeaders(): HttpHeaders {
    return this.headers;
  }
}
