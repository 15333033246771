<h3 mat-dialog-title class="inm-headline">&nbsp;&nbsp;{{data.title}}</h3>
<div style="width: 100%;">
  <div mat-dialog-content class="zero-margin-right float-add-edit-template-container">
    <div fxLayout="column" fxLayoutAlign="space-around center">
      <div class="float-child">
        <ng-container class="error-messages" *ngIf="!templateDetailsForm.valid">
          <span class="error-messages">Please select at least one value for BIN, PCN, Group Number, or Network
            ID.</span>
        </ng-container>
        <form [formGroup]="templateDetailsForm" [style.fontSize.px]="14" class="content form-content">
          <div>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>BIN</mat-label>
              <input minlength="6" maxlength="6" (input)="checkSaveEnabled()" title="BIN" matInput
                formControlName="binCtrl" type="string">
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent" fxFlexOffset="2">
              <mat-label>PCN</mat-label>
              <input maxlength="20" (input)="checkSaveEnabled()" title="PCN" matInput formControlName="pcnCtrl"
                type="string">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>GROUP NUMBER</mat-label>
              <input maxlength="15" (input)="checkSaveEnabled()" title="GROUP NUMBER" matInput
                formControlName="groupNumberCtrl" type="string">
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent" fxFlexOffset="2">
              <mat-label>NETWORK ID</mat-label>
              <input maxlength="20" (input)="checkSaveEnabled()" title="NETWORK ID" matInput
                formControlName="networkIdCtrl" type="string">
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>

    <section class='inm-actions--confirmation'>
      <button class="inm-button--action button-spacing" [disabled]="saveDisabled || isLoadingResults"
        (click)="saveTemplateDetails()">Save</button>
      <button class="inm-button button-spacing" [disabled]="isLoadingResults" [mat-dialog-close]="true">Cancel</button>
    </section>
  </div>

  <div class="center-line-container" *ngIf="editMode == false">
    <div class="center-line"></div>
  </div>
  <div mat-dialog-content class="app-upload-container" *ngIf="editMode == false">
    <div class="error-messages" [innerHTML]="warningMessage">
    </div>
    <div class="download-messages template-align">
      <a href="../assets/upload-templates/industry-details-template.csv" download="industry-details-template.csv">
        Download Template
      </a>
    </div>
    <div>&nbsp;</div>
    <app-file-upload class="float-upload-container" (EditOnClick)="uploadTemplatetDetailCsv($event)"
      [data]="fileUploadData">
    </app-file-upload>
    <div class="error-messages" [innerHTML]="validationErrorMessage"></div>
  </div>

</div>
