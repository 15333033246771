import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'lodash';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { isEmpty } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { ContractPaginationResult } from "../models/ContractPaginationResult";
import { ContractRate } from "../models/ContractRate";
import { GridPagination } from "../models/GridPagination";
import { Pagination } from "../models/Pagination";
import { GlobalFilterService } from "./global-filter.service";
import { RateDeleteBody } from '../models/RateDeleteBody';

@Injectable({
  providedIn: 'root',
})
export class ContractRateService {
  private readonly Timeout: number = 30000;
  apiRoot: string;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService) {
    this.apiRoot = environment.cmApiRoot;
  }


  getContractRatesForContractId(
    ContractId?: number
  ) : Observable<ContractRate[]> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<ContractRate[]>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/rates`,  {headers: headers});
  }

  getContractRateForRateId(
    ContractId?: number,
    RateId?: number
  ): Observable<ContractRate> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<ContractRate>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/rates/${RateId}`, { headers: headers });
  }

  public updateContractRateById(
    contractRate,
    ContractId?: number,
    RateId?: number
  ): Observable<ContractRate> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<ContractRate>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/rates/${RateId}`, contractRate, { headers: headers });
  }

  public addRate(
    contractRate,
    ContractId?: number
  ): Observable<ContractRate[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    var contractRateArray = [contractRate];
    return this.http.post<ContractRate[]>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/rates`, contractRateArray, { headers: headers });
  }

  public deleteRate(
    RateIds: number[],
    ContractId?: number
  ): Observable<any> {
    var body = new RateDeleteBody();
    body.rateIds = RateIds;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/rates/delete`, body, { headers: headers });
  }

}
