import { DatePipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ContractService } from 'src/app/services/contractService';

import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog/confirm-dialog.component';
import { ContractNoteCreateDialogData } from './ContractNoteCreateDialogDataModel';

@Component({
  selector: 'app-create-contractnotes-component',
  templateUrl: './create-contractnotes-component.html',
  styleUrls: ['./create-contractnotes-component.scss']
})
export class CreateContractnotesComponent {
  contractForm: UntypedFormGroup;
  saveDisabled = true;
  cancelDisabled = false;
  contractId: number;
  clientId: number;
  pagetitle: string;
  isLoadingResults = false;

  ngOnInit() {
    this.initValidators();
    this.pagetitle = 'Add Contract Note';
    this.contractId = this.data.contractId;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private contractService: ContractService,
    public dialogRef: MatDialogRef<CreateContractnotesComponent>,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ContractNoteCreateDialogData) {

  }

  saveContract() {
    this.addContractNotes();
  }

  initValidators(): void {
    this.contractForm = this.fb.group({
      contractNote: ['']
    })
  }
  checkSaveEnabled(): void {
    if (this.contractForm.controls.contractNote.value.trim() != '') {
      this.saveDisabled = false;
    }
    else {
      this.saveDisabled = true;
    }
  }

  oncancelclick(name: string) {
    if (this.contractForm.controls.contractNote.value.trim() === '') {
      this.dialogRef.close();
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '500px',
        maxHeight: '500px',
        data: {
          message: 'Are you sure want to cancel without saving Data?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });
      dialogRef.afterClosed().subscribe((returnedData: string) => {
        if (returnedData == 'close') {
          this.dialogRef.close('close');
        }
      })
    }

  }

  addContractNotes(): void {
    this.saveDisabled = true;
    this.cancelDisabled = true;
    this.contractId = this.data.contractId;

    var note = this.contractForm.controls.contractNote.value;
    this.contractService.addContractNotes(this.contractId, note.trim()).subscribe(
      (results) => {
        this.isLoadingResults = false;
        this.dialogRef.close('close');
        toastr.success('Contract Note added successfully!');
        this.saveDisabled = true;
        this.cancelDisabled = true;
      },
      (err) => {
        toastr.error('We are unable to add ContractNotes at this time');
      }
    )

  }

}
