import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddEditTemplatesDialogData } from './add-edit-templates-dialog-data';
import { ContractTypeService } from 'src/app/services/contractTypeService';
import { SelectItem } from 'primeng/api';
import { PayerService } from 'src/app/services/payerService';
import { IndustryContractTemplate } from 'src/app/models/IndustryContractTemplate';
import { ContractType } from 'src/app/models/ContractType';
import { map } from 'lodash';
import { TemplateLibaryService } from 'src/app/services/templateLibraryService';

@Component({
  selector: 'app-add-edit-templates-dialog',
  templateUrl: './add-edit-templates-dialog.component.html',
  styleUrls: ['./add-edit-templates-dialog.component.less']
})
export class AddEditTemplatesDialogComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<AddEditTemplatesDialogComponent>,
    private fb: FormBuilder,
    private contractTypeService: ContractTypeService,
    private payerService: PayerService,
    private templateLibraryService: TemplateLibaryService,
    @Inject(MAT_DIALOG_DATA) public data: AddEditTemplatesDialogData
  ) { 
    if (this.data.action === 'add') {
      this.editMode = false;
    }
  }

  templateForm: FormGroup;
  saveDisabled = true;
  editMode = true;
  isLoadingContractTypes: boolean = false;
  isLoadingPayers: boolean = false;
  isLoadingResults: boolean = false; 
  contractTypeList: SelectItem[];
  contractTypes: SelectItem[];
  selectedContractTypes: number[] = [];
  networkName: string;

  payersList: SelectItem[];
  filteredPayers: SelectItem[];
  selectedPayer: string;
  templateToEdit: IndustryContractTemplate;

  ngOnInit(): void {
    this.templateForm = this.fb.group({
      networkNameCtrl: ['',[
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(1)
      ]],
      payerGroupNameCtrl: ['', Validators.required],
      contractTypesCtrl: ['', Validators.required]
    });

    this.loadAllContractTypes();
    this.loadAllPayers();
    this.populateFieldsIfEdit();
  }

  populateFieldsIfEdit() {
    this.templateToEdit = this.data.template;
    if (this.editMode) {
      this.templateForm.controls.networkNameCtrl.setValue(this.templateToEdit.networkName);
      this.templateForm.controls.payerGroupNameCtrl.setValue({value: this.templateToEdit.payerGroupName, label: this.templateToEdit.payerGroupName});
      const selectedValues = this.templateToEdit.industryContractTemplateContractTypes.map(element => ({
        value: element.contractTypeId,
        label: element.contractType.name
      }));
      this.templateForm.controls.contractTypesCtrl.setValue(selectedValues);
    }
  }

    /**
   * Check if save is enabled
   */
  checkSaveEnabled(): void {
    this.saveDisabled =
      this.templateForm.pristine ||
      this.templateForm.invalid ||
       this.checkValuesChange();
  }

  loadAllContractTypes(): void {
    this.isLoadingContractTypes = true;
    this.contractTypeService.getContractTypeList().subscribe({
      next: (result) => {
        this.contractTypeList = result.contractTypeList.map((a) => ({
          value: a.contractTypeId,
          label: a.name,
        }));
        this.contractTypes = this.contractTypeList;
        this.isLoadingContractTypes = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Contract Types at this time');
        this.isLoadingContractTypes = false;
      },
    });
  
  }

  loadAllPayers(): void {
    this.isLoadingPayers = true;
    this.payerService.getPayerList().subscribe({
      next: (result) => {
        this.payersList = this.transformValue(result);
        this.filteredPayers = this.payersList;
        this.isLoadingPayers = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Payers at this time');
        this.isLoadingPayers = false;
      },
    });
  }

  arrayObjects: SelectItem[] =[];
  transformValue(response):SelectItem[]{
    this.arrayObjects = [];
    response.forEach(data => {
      this.arrayObjects.push({
            label: data,
            value: data
        })
    })
    return this.arrayObjects;
  }

  onKeyPayer(value): void {
    const filterValue = value.toLowerCase();
    this.filteredPayers = this.payersList.filter((payer) =>
      payer.label.toLowerCase().includes(filterValue)
    );
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.value === o2.value && o1.label === o2.label;
  }

  saveTemplate() {

    const templateRequestModel: any  = {
      networkName: this.templateForm.controls.networkNameCtrl.value,
      payerGroupName: this.templateForm.controls.payerGroupNameCtrl.value.value,
      contractTypeIds: this.templateForm.controls.contractTypesCtrl.value ? map(this.templateForm.controls.contractTypesCtrl.value,'value') : []
    };

    if (this.editMode) {
      this.isLoadingResults = true;
      this.templateLibraryService.updateTemplateById(templateRequestModel, this.templateToEdit.industryContractTemplateId)
        .subscribe({
          next: (result: IndustryContractTemplate) => {
            const returnData: AddEditTemplatesDialogData =  {
              action: 'close',
              title: 'null',
              template: result
            }
            this.isLoadingResults = false;
            this.dialogRef.close(returnData);
            toastr.success('Industry contract template was updated successfully!');
          },
          error: (err) => {
            toastr.error('We are unable to update Industry Contract Templates at this time.', err);
            this.isLoadingResults = false;
          }
      });
    } else {
      this.isLoadingResults = true;
      this.templateLibraryService.createNewTemplate(templateRequestModel)
        .subscribe({
          next: (result) => {
            const returnData: AddEditTemplatesDialogData =  {
              action: 'close',
              title: 'null',
              template: result
            }
            this.isLoadingResults = false;
            this.dialogRef.close(returnData);
            toastr.success('Industry contract template was created successfully!');
          },
          error: (err) => {
            toastr.error('We are unable to create new Industry Contract Templates at this time.', err);
            this.isLoadingResults = false;
          }
        });
    }
  }

  checkValuesChange(): boolean {
    if (this.editMode) {
      const networkName = this.templateForm.controls.networkNameCtrl.value;
      const payerGroupName = this.templateForm.controls.payerGroupNameCtrl.value.value;
      const contractTypeIds = this.templateForm.controls.contractTypesCtrl.value ? map(this.templateForm.controls.contractTypesCtrl.value,'value') : [];
      return (
        networkName === this.templateToEdit.networkName &&
        payerGroupName === this.templateToEdit.payerGroupName &&
        this.arraysAreEqual(contractTypeIds, this.templateToEdit.industryContractTemplateContractTypes.map(obj => obj.contractTypeId))
      );
    }
    return false;
  }

  arraysAreEqual(array1: any[], array2: any[]): boolean {
    if (array1.length !== array2.length) {
      return false;
    }

    return array1.every((value, index) => value === array2[index]);
  }

}
