import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/contracts/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { AddcontractfactorgpisComponent } from 'src/app/components/contracts/create-factorGPI/addcontractfactorgpis/addcontractfactorgpis.component';
import { ManageContractsToolbarHelper } from 'src/app/components/contracts/manage/helper/manage-contracts-toolbar.helper';
import { ContractFactorGpi } from 'src/app/models/ContractFactorGpi';
import { Pagination } from 'src/app/models/Pagination';
import { UploadfilegpiResponse } from 'src/app/models/UploadfilegpiResponse';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ContractFactorService } from 'src/app/services/contractFactorService';
import { GlobalFilterService } from 'src/app/services/global-filter.service';

import { ToolbarAction } from '../../table-toolbar/ToolBarAction';

@Component({
  selector: 'app-factorsgpi-grid',
  templateUrl: './factorsgpi-grid.component.html',
  styleUrls: ['./factorsgpi-grid.component.scss']
})
export class FactorsgpiGridComponent {

  dataSource = new MatTableDataSource<any>();
  displayedColumn: string[] = [];
  local: ContractFactorGpi[] = [];
  isExclude = false;
  isDisableExclude = true;
  isDisableInclude = true;
  Sort = '';
  sortOrder = '';
  offset = 0;
  limit = 30;
  selectShow = true;
  pageIndex = 0;
  totalItemCount: number;
  contractId: number;
  factorId = 0;
  clientId: number;
  isLoadingResults = false;
  isRadioOptionchange = false;
  files: any[] = [];
  showError = false;
  disabledRemove = true;
  gpiCodes: string[] = [];
  validateNumeric = 0;
  validateDuplicate = 0;
  validateLength = 0;
  validateMaxLength: string[] = [];
  deleteGpi: string[] = [];
  factorGpiDelete: ContractFactorGpi[] = [];
  public currentToolbarFactor = [];
  public toolBarAction: any;
  selection = new SelectionModel<ContractFactorGpi>(true, []);
  public readonly toolbarHelper: ManageContractsToolbarHelper;
  _canAddEdit: boolean = true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;

  @Input() set canAddEdit(value: boolean){
    this._canAddEdit = value;
    this.disableNonPendingActions(this.currentToolbarFactor)
  };
  get canAddEdit(): boolean {
    return this._canAddEdit;
  }

  errorMessage = '';
  validationErrorMessage = '';

  fileUploadData: {
    fileUploadComponent: string; contractId: number; maxFileSize: number; acceptedFileTypes: string[]; message: string;
    topMessage: string; maxNumberOfFiles: number; errorMessage: string; factorId: number; isExclude: boolean
  };
  fileUploadResponse: { status: boolean, gpi: string[] };

  constructor(
    private authorizationService: AuthorizationService,
    private contractFactorService: ContractFactorService,
    private route: ActivatedRoute,
    private globalFilterService: GlobalFilterService,
    public dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    this.toolbarHelper = new ManageContractsToolbarHelper(this.authorizationService);
  }

  async ngOnInit() {
    this.contractId = +this.route.snapshot.paramMap.get('id');
    this.displayedColumn = ['factorGpiId', 'select', 'gpiType', 'gpi'];
    this.sort.direction = "desc";
    this.sort.start = "desc";
    this.dataSource.sort = this.sort;
    this.sort.sort({ id: 'gpi', start: 'desc', disableClear: false });
    this.currentToolbarFactor = this.toolbarHelper.currentToolbarFactor;

    await this.loadContractGpis();
    this.disableNonPendingActions(this.currentToolbarFactor)
  }

  addUploadedgpi(addgpi: UploadfilegpiResponse) {
    if (addgpi.status) {
      this.validategpicodes(addgpi.gpi);
      if (this.gpiCodes.length > 0) {
        this.saveGpis(this.isExclude, this.contractId, this.factorId);
      }
    }
    else {
      this.validationErrorMessage = '';
    }
  }

  isAllSelected(): boolean {

    const numSelected = this.selection.selected.length;
    const numRows = this.local.length;
    return numSelected === numRows;
  }
  
  deleteEnable() {
    if (this.selection.selected.length > 0) {
      this.toolbarHelper.enableGpiRemove();
      this.currentToolbarFactor = this.toolbarHelper.currentToolbarFactor;
    }
    else {
      this.toolbarHelper.disableGpiRemove();
      this.currentToolbarFactor = this.toolbarHelper.currentToolbarFactor;
    }
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data
        .forEach((row) => this.selection.select(row));

    this.deleteEnable();
  }

  async loadContractGpis() {

    this.isLoadingResults = true;
    var results = await this.contractFactorService.getContractFactorGpis(
      new Pagination(this.limit, this.offset, this.Sort, this.sortOrder),
      this.contractId).toPromise();

    if (results == undefined || results == null) {
      toastr.error('We are unable to load Gpis at this time');
      this.isLoadingResults = false;
      return;
    }
    // this.contractFactorService.getContractFactorGpis(
    //   new Pagination(this.limit, this.offset, this.Sort, this.sortOrder),
    //   this.contractId).subscribe(
        // (results) => {
    console.log(results);
    if (results.gpiList) {
      this.isExclude = (results.isExcluded === null) ? this.isExclude : results.isExcluded;
      this.factorId = results.factorId;
      this.totalItemCount = results.gpiList.totalItemCount;
      this.local = results.gpiList.items;
      this.selection.clear();
      this.dataSource = new MatTableDataSource(this.local);
      this.changeDetectorRefs.detectChanges();
    }

    this.fileUploadData = {
      fileUploadComponent: 'GPIUpload',
      contractId: this.contractId,
      maxFileSize: 1000000,
      acceptedFileTypes: ['text/csv'],
      message: 'Drag and drop .csv file here',
      topMessage: 'Select 1 file to import GPIs. 1MB limit per file.',
      maxNumberOfFiles: 1,
      errorMessage: 'Single CSV file only. Max limit 1MB.',
      factorId: this.factorId,
      isExclude: this.isExclude
    };

  this.isLoadingResults = false;
        // },
        // (err) => {
        //   this.isLoadingResults = false;
        //   toastr.error('We are unable to load Gpis at this time');
        // }
      // )
  }

  private disableNonPendingActions(toolbar) : void {
    toolbar.every((item) => {
      item.disabled = !this.canAddEdit;
    })
  }

  onPageChange(event: PageEvent): void {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * event.pageSize;
    this.selection.clear();
    this.loadContractGpis();
  }

  sortData(sort: Sort) {
    this.Sort = sort.active;
    this.sortOrder = sort.direction;
    this.loadContractGpis();

    if (this.paginator == null || this.paginator == undefined) {
      return;
    }
    this.paginator.firstPage();
  }

  onchange(data: MatRadioChange) {

    this.isExclude = data.value === 'Exclude' ? true : false;
    this.isRadioOptionchange = true;
    this.saveGpis(this.isExclude, this.contractId, this.factorId);
  }

  saveGpis(isExclude: boolean, contractId: number, factorId: number) {
    this.isLoadingResults = true;
    this.isExclude = isExclude;
    this.contractId = contractId;
    this.factorId = factorId;
    this.contractFactorService.addContractFactorGpis(isExclude, this.gpiCodes, contractId, factorId).subscribe(
      (results) => {
        const returnData: string = 'close'
        this.isLoadingResults = false;
        this.factorId = results.factorId;
        if (this.gpiCodes.length > 0) {
          toastr.success('Factor GPI added successfully!');
          this.loadContractGpis();
        }
        else if (this.isRadioOptionchange) {
          toastr.success('GPI Include/Exclude changed successfully.');
        }
        this.fileUploadData = {
          fileUploadComponent: 'GPIUpload',
          contractId: this.contractId,
          maxFileSize: 1000000,
          acceptedFileTypes: ['text/csv'],
          message: 'Drag and drop .csv file here',
          topMessage: 'Select 1 file to import GPIs. 20MB limit per file.',
          maxNumberOfFiles: 1,
          errorMessage: 'Single CSV file only. Max limit 1MB.',
          factorId: this.factorId,
          isExclude: this.isExclude
        };
        this.gpiCodes = new Array<string>();
        this.isLoadingResults = false;
        this.isRadioOptionchange = false;
      },
      (err) => {
        this.isLoadingResults = false;
        this.gpiCodes = new Array<string>();
        toastr.error('We are unable to add GPIs at this time');
      }
    )

  }

  validategpicodes(gpi: string[]) {
    const regex = /^[0-9]+$/i;
    gpi.forEach((val) => {

      if (gpi.length > 0) {
        var trimval = val.trim();
        // check if gpi is numeric
        if (!trimval.match(regex)) {
          this.validateNumeric++;
        }

        // check for duplicate
        else if (this.gpiCodes.includes(trimval)) {
          this.validateDuplicate++;
        }

        // check gpi is 10 or 14 characters only
        else if (trimval.length != 10 && trimval.length != 14) {
          this.validateLength++;
        }

        // add to the array
        else {
          this.gpiCodes.push(trimval);
        }
      }
    });

    // create proper error message to display
    if (this.validateNumeric > 0) {
      this.validationErrorMessage += +this.validateNumeric + ' Invalid GPI value(s) were found in the file. Only numerics are allowed. <br/>';
      this.validateNumeric = 0;
    }
    if (this.validateDuplicate > 0) {
      this.validationErrorMessage += this.validateDuplicate + ' Duplicate GPI value(s) found in the file were ignored. <br/>';
      this.validateDuplicate = 0;
    }
    if (this.validateLength > 0) {
      this.validationErrorMessage += this.validateLength + ' Invalid GPI value(s) were found in the file. Gpi length should be 10 or 14. <br/>';
      this.validateLength = 0;
    }
  }

  toolbarBtnClick(toolbaraction: ToolbarAction) {

    if (toolbaraction.name == 'addContractFactorGpi') {
      this.validationErrorMessage = '';
      const dialogRef = this.dialog.open(AddcontractfactorgpisComponent, {
        width: '800px',
        maxHeight: '800px',
        data: {
          factorId: this.factorId,
          contractId: this.contractId,
          clientId: this.globalFilterService.selectedClientId,
          isExcluded: this.isExclude
        },
      });
      dialogRef.afterClosed().subscribe((returnedData: string) => {
        if (returnedData === 'close') {
          this.Sort = 'FactorGpiId';
          this.sortOrder = 'desc';
          this.loadContractGpis();
        }
      });
    }
    else if (toolbaraction.name == 'removeContractFactorGpi') {

      if (this.selection.selected.length > 0) {

        let selectedGpis: ContractFactorGpi[] = this.selection.selected;
        let deleteGpiList: string[] = [];
        let width: string = '500px';
        let count = this.selection.selected.length;
        let message: string = '';
        selectedGpis.forEach(function (val, index) {

          if (deleteGpiList.length > 4) {
            width = '700px';
            message += deleteGpiList.toString() + '<br/>';
            deleteGpiList.splice(0);
          }
          else if (index + 1 === count) {
            deleteGpiList.push(val.gpi);
            message += deleteGpiList.toString() + '<br/>';
            deleteGpiList.splice(0);
          }
          deleteGpiList.push(val.gpi);
        });

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: width,
          maxHeight: '700px',
          data: {
            message: 'Are you sure want to Delete following GPIs?. <br/>' + message,
            buttonText: {
              ok: 'Yes',
              cancel: 'No'
            },
            title: 'Delete GPI'
          }
        });
        dialogRef.afterClosed().subscribe((returnedData: string) => {
          if (returnedData == 'close') {

            this.isLoadingResults = true;
            this.factorGpiDelete = this.selection.selected;
            console.log(this.factorGpiDelete);
            this.contractFactorService.deleteContractFactorGpis(this.factorId, this.factorGpiDelete, this.contractId).subscribe(
              (results) => {

                this.factorGpiDelete = new Array<ContractFactorGpi>();
                toastr.success('All selected GPIs deleted successfully.');
                this.selection.clear();
                this.deleteEnable();
                this.loadContractGpis();
              },
              (err) => {
                this.isLoadingResults = false;
                this.factorGpiDelete = new Array<ContractFactorGpi>();
                toastr.error('We are unable to remove GPIs at this time');
              }
            );

          }
        })

      }
    }


  }

  listenToFactorIdChanges(localFactorId: number) {
    this.factorId = localFactorId;
  }
}
