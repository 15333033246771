import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contract-amendment-dialog',
  templateUrl: './contract-amendment-dialog.component.html',
  styleUrls: ['./contract-amendment-dialog.component.less']
})
export class ContractAmendmentDialogComponent implements OnInit {
  note: string
  pageTitle: string

  constructor(
    public dialogRef: MatDialogRef<ContractAmendmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit() {
    this.pageTitle = 'Amendment Note';
    this.note = this.data
  }
}
