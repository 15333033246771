import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isEmpty } from "rxjs/operators";
import { environment } from 'src/environments/environment';

import { ContractDefinition } from '../models/ContractDefinition';
import { ContractFactor } from '../models/ContractFactor';
import { ContractFactorGpi } from '../models/ContractFactorGpi';
import { ContractFactorGpiResponse } from '../models/ContractFactorGpiResponse';
import { ContractFactorGpiResult } from '../models/ContractFactorGpiResult';
import { FactorGpiBody } from '../models/FactorGpiBody';
import { Pagination } from '../models/Pagination';
import { GlobalFilterService } from './global-filter.service';

@Injectable({
  providedIn: 'root'
})
export class ContractFactorService {

  private readonly Timeout: number = 30000;
  apiRoot: string;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService) {
    this.apiRoot = environment.cmApiRoot;
  }

  public getContractFactorGpis(
    pagination: Pagination,
    ContractId?: number
  ): Observable<ContractFactorGpiResponse> {
    const params = pagination.buildSearchParams();
    return this.http.get<ContractFactorGpiResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/contractfactor/GPI/`, { params });
  }

  public getContractFactors(
    ContractId?: number
  ): Observable<ContractFactor> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get<ContractFactor>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/contractfactor`, { headers });
  }

  public upsertContractFactors(
    contractFactor,
    ContractId?: number
  ) : Observable<ContractFactor> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<ContractFactor>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/contractfactor`, contractFactor, { headers });
  }

  public addContractFactorGpis(
    isExcluded: boolean,
    gpiCodes: string[],
    ContractId?: number,
    FactorId?: number,
  ): Observable<ContractFactorGpiResult> {
    var body = new FactorGpiBody();
    body.IsExcluded = isExcluded;
    body.Gpis = gpiCodes;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<ContractFactorGpiResult>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/contractfactor/${FactorId}/GPI/`, body, { headers });
  }

  public deleteContractFactorGpis(
    FactorId: number,
    gpiCodes: ContractFactorGpi[],
    ContractId?: number
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: gpiCodes
    };
    return this.http.delete<any>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/contractfactor/${FactorId}/GPI/`, options);
  }

}
