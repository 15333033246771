<div>
  <ul class="checkbox-list-style">
    <li *ngFor="let item of localCheckboxItemList">
      <mat-checkbox [checked]="item.selected" (change)="onCheckboxItemSelect($event, item)" labelPosition="after" [disabled]="!canAddEdit">
        <span title="{{item.label}}" *ngIf="item.label.length < maxLabelLength">
          {{item.label}}
        </span>
        <span title="{{item.label}}" *ngIf="item.label.length >= maxLabelLength">
          {{item.label.substr(0,(maxLabelLength - 3))}} ...
        </span>
      </mat-checkbox>
    </li>
  </ul>
</div>
