import { AuthorizationService } from "src/app/services/authorization.service";

export class ManageContractsToolbarHelper {
  public showAction: string;
  public currentToolbar = [];
  public currentToolbarNote = [];
  public currentToolbarFactor = [];
  public canAddEdit: boolean = true;
  disabledRemove = true;

  private readonly TOOLBAR_ITEMS = [
    {
      feature: 'ContractManagement::AddEditContract',
      item: {
        label: 'Add Contract Details',
        name: 'addContractDetails',
        tip: 'Add New Contract Details',
        icon: '#inm-icon__plus-square',
        disabled: !this.canAddEdit,
        show: true,
      },

    },
  ];

  private readonly TOOLBAR_ITEMS_Note = [
    {
      feature: '',
      item: {
        label: 'Add Contract Note',
        name: 'addContractNote',
        tip: 'Add New Contract Note',
        icon: '#inm-icon__plus-square',
        disabled: !this.canAddEdit,
        show: true,
      },

    },
  ];

  private readonly TOOLBAR_ITEMS_Factor = [
    {
      feature: '',
      item: {
        label: 'Add GPI',
        name: 'addContractFactorGpi',
        tip: 'Add New GPI',
        icon: '#inm-icon__plus-square',
        disabled: !this.canAddEdit,
        show: true,
      },

    },
    {
      feature: '',
      item: {
        label: 'Remove GPI',
        name: 'removeContractFactorGpi',
        tip: 'Delete GPI',
        icon: '#inm-icon__trash',
        disabled: (this.disabledRemove || !this.canAddEdit),
        show: true,
      },

    },
  ];


  constructor(private authService: AuthorizationService) {
    this.generateToolbarData();
    this.generateToolbarNoteData();
    this.generateToolbarFactorData();
  }

  enableGpiRemove() {
    this.disabledRemove = false;
    this.currentToolbarFactor[1].disabled = false;
  }

  disableGpiRemove() {
    this.disabledRemove = true;
    this.currentToolbarFactor[1].disabled = true;
  }

  generateToolbarData(): void {
    for (const toolbar of this.TOOLBAR_ITEMS) {
      if (!toolbar.feature) {
        this.currentToolbar.push(toolbar.item);
        continue;
      }
      if (toolbar.feature && this.authService.hasFeature(toolbar.feature)) {
        this.currentToolbar.push(toolbar.item);
      }
    }
  }

  generateToolbarNoteData(): void {
    for (const toolbarnote of this.TOOLBAR_ITEMS_Note) {
      if (!toolbarnote.feature) {
        this.currentToolbarNote.push(toolbarnote.item);
        continue;
      }
      if (toolbarnote.feature && this.authService.hasFeature(toolbarnote.feature)) {
        this.currentToolbarNote.push(toolbarnote.item);
      }
    }
  }

  generateToolbarFactorData(): void {

    for (const toolbarfactor of this.TOOLBAR_ITEMS_Factor) {
      if (!toolbarfactor.feature) {
        this.currentToolbarFactor.push(toolbarfactor.item);
        continue;
      }
      if (toolbarfactor.feature && this.authService.hasFeature(toolbarfactor.feature)) {
        this.currentToolbarFactor.push(toolbarfactor.item);
      }
    }
  }
}
