<div style="min-height: 1030px; position:relative">
  <div style="min-height: 540px;">
    <div style="max-height: 50px;">
      <mat-radio-group (change)="onchange($event)" [disabled]="!canAddEdit">
        <mat-radio-button [checked]="!isExclude" value="Include">Include</mat-radio-button>
        <mat-radio-button [checked]="isExclude" value="Exclude">Exclude</mat-radio-button>
      </mat-radio-group>
    </div>
    <br>
    <div style="max-height: 50px;">

      <div>
        <app-toolbar class="flex-child" style="margin-top: 0;" [toolbarItemList]="currentToolbarFactor"
          (OnClick)="toolbarBtnClick($event) " [canAddEdit]="canAddEdit"></app-toolbar>
      </div>

    </div>
    <div style="position: relative; max-height: 420px; overflow: auto">
      <div >
        <table mat-table placeholder="GPI" title="GPI" [dataSource]=" dataSource " matSort
          (matSortChange)=" sortData($event) " matPaginator>

          <ng-container matColumnDef="factorGpiId">
            <th hidden=" true " mat-header-cell *matHeaderCellDef mat-sort-header> FactorGpiId </th>
            <td hidden=" true " mat-cell *matCellDef=" let element " title=" {{element}} "> {{ element.factorGpiId}}
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox [disabled]='local.length && !selectShow || !canAddEdit' (change)=" $event ? masterToggle() : null "
                [checked]=" selection.hasValue() && isAllSelected() "
                [indeterminate]=" selection.hasValue() && !isAllSelected() ">
              </mat-checkbox>
            </th>
            <td mat-cell class="matselect" style="max-width: 10%; text-align: right;" *matCellDef=" let row ">
              <mat-checkbox [disabled]=" !selectShow || !canAddEdit" (click)=" $event.stopPropagation() "
                (change)=" $event ? selection.toggle(row) && deleteEnable() : null "
                [checked]=" selection.isSelected(row) ">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="gpiType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Gpi Type </th>
            <td class="matgpicol" mat-cell *matCellDef=" let element ">
              {{ element.gpiType}}
            </td>
          </ng-container>
          <ng-container matColumnDef="gpi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gpi
            </th>
            <td mat-cell *matCellDef=" let element "> {{element.gpi}}
            </td>
          </ng-container>
          <ng-container matColumnDef="header-row-first-group" sticky="true">
            <th mat-header-cell *matHeaderCellDef style="text-align: left;" [attr.colspan]="11">
              GPI
            </th>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef=" displayedColumn; sticky: true">GPI</tr>
          <tr mat-row *matRowDef=" let row; columns: displayedColumn;">GPI</tr>
        </table>
      </div>
      <div>
        <mat-paginator #paginator [length]=" totalItemCount " [pageIndex]=" pageIndex " [pageSize]=" 30 "
          [pageSizeOptions]=" [5,10,15,30] " showFirstLastButtons (page)=" onPageChange($event) ">
        </mat-paginator>
      </div>
    </div>
  </div>


  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center" [hidden]="!isLoadingResults">
    </mat-spinner>
  </div>


  <div style="margin-left: 15px; margin-right: 15px; margin-bottom: 5px; bottom: 0px;">
    <app-file-upload class="float-document-container" (EditOnClick)="addUploadedgpi($event) "
      [data]=" fileUploadData " [canAddEdit]="canAddEdit" ></app-file-upload>
  </div>

  <div class="error-text" [innerHTML]=" validationErrorMessage "></div>

</div>
