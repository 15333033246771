import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageContractComponent } from 'src/app/components/contracts/manage/manage-contract.component';
import { ContractsComponent } from 'src/app/components/contracts/search/contracts.component';
import { NoAccessComponent } from 'src/app/components/no-access/no-access.component';
import { ManageTemplatesComponent } from 'src/app/components/templates/manage-templates/manage-templates.component';
import { TemplateLibraryComponent } from 'src/app/components/templates/template-library/template-library.component';

import { TemplateSummaryComponent } from '../../components/templates/template-summary/template-summary.component';
import { AuthGuard } from '../../guards/auth.guard';
import { FeatureGuard } from '../../guards/feature.guard';

const routes: Routes = [
  {
    path: 'cm',
    component: TemplateSummaryComponent,
    children: [
      { path: '', redirectTo: 'contracts', pathMatch: 'prefix'},
      {
        path: 'contracts',
        component: ContractsComponent,
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'ContractManagement::ViewContract', shouldDetach: true },
      },
      {
        path: 'templatelibrary',
        component: TemplateLibraryComponent,
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'ContractManagement::ViewTemplate', shouldDetach: true },
      },
      {
        path: 'contracts/contractview/:id/:mode',
        component: ManageContractComponent,
         canActivate: [AuthGuard, FeatureGuard],
         data: { feature: 'ContractManagement::ViewContract' },
      },
      {
        path: 'no-access',
        component: NoAccessComponent,
      },
      {
        path: 'templatelibrary/managetemplates/:id',
        component: ManageTemplatesComponent,
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'ContractManagement::ViewTemplate' }
      },
      {
        path: 'no-accesss',
        component: NoAccessComponent,
      },
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateRoutingModule {}
