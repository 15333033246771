import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ContractAmendment } from 'src/app/models/contract-amendment';
import { ContractAmendmentResponse } from 'src/app/models/contract-amendment-response';
import { Pagination } from 'src/app/models/Pagination';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ContractAmendmentService } from 'src/app/services/contract-amendment.service';
import { GlobalFilterService } from 'src/app/services/global-filter.service';

import { ContractAmendmentDialogComponent } from '../../contracts/contract-amendment/contract-amendment-dialog/contract-amendment-dialog.component';

@Component({
  selector: 'app-amendments-grid',
  templateUrl: './amendments-grid.component.html',
  styleUrls: ['./amendments-grid.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AmendmentsGridComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [];
  isLoadingResults = false;

  amendments: ContractAmendment[]  = []
  Sort = 'AmendmentDate';
  sortOrder = 'desc';
  offset = 0;
  limit = 30;
  pageIndex = 0;
  totalItemCount: number;
  contractId: number;

  title: string;

  public readonly viewFeatures = [
    { feature: 'Get::GetContractById', name: 'view', accessible: false }
  ];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;

  @Input() set data(value: any) {
    if (value == null || value == undefined)
    {
      return;
    }
    this.title = "Amendments"
    this.contractId = +this.route.snapshot.paramMap.get('id');
    this.totalItemCount = value.data.length;

    this.displayedColumns = [ 'contractId', 'parentContractId', 'amendmentHistoryId', 'networkName', 'contractStatusName', 'description', 'amendmentBy', 'amendmentDate' ];

    const sortState: Sort = {active: 'amendmentDate', direction: 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);

    this.dataSource = new MatTableDataSource(value.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
  }

  constructor(private amendmentService: ContractAmendmentService,
    private authService: AuthorizationService,
    private route: ActivatedRoute,
    private globalFilterService: GlobalFilterService,
    private dialog: MatDialog) {
      this.checkFeatures()
    }

  ngOnInit(): void { }

  private checkFeatures(): void {
    for (const vwFeature of this.viewFeatures) {
      if (this.authService.hasFeature(vwFeature.feature)) {
        vwFeature.accessible = true;
      }
    }
  }

  viewAmendmentNote(note: string) {
    const dialogRef = this.dialog.open(ContractAmendmentDialogComponent, {
      width: '1075px',
      maxHeight: '800px',
      data: note
    })
  }

  loadContractAmendments(): void {
    if (!this.contractId) {
      return;
    }
    this.isLoadingResults = true;
    this.amendmentService.getContractAmendmentsByContractId(
      new Pagination(this.limit, this.offset, this.Sort, this.sortOrder),
      this.contractId,
      ).subscribe(
        (results) => {
          this.amendments = results.items;
          this.dataSource = new MatTableDataSource(this.amendments);
          this.isLoadingResults = false;
          this.totalItemCount = results.totalItemCount;
        },
        (err) => {
          this.isLoadingResults = false;
          toastr.error('We are unable to load Contract Amendments at this time');
        }
      )
  }

  onPageChange(event: PageEvent): void {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * event.pageSize;

    this.loadContractAmendments();
  }

  sortData(sortData: Sort) {
    this.Sort = sortData.active;
    this.sortOrder = sortData.direction;

    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.loadContractAmendments();
  }
}
