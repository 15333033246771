import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ContractClassificationResponse } from '../models/ContractClassificationResponse';
import { SelectItem } from '../models/SelectItem';

const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class ContractClassificationService {
  apiRoot: string;
  contractClassifications: SelectItem[] = [];

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.apiRoot = environment.cmApiRoot;
  }


  public getContractClassificationList(): Observable<ContractClassificationResponse> {

    return this.http.get<ContractClassificationResponse>(`${this.apiRoot}/api/ContractClassifications`);
  }


}

