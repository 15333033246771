import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { PriceTypeCode } from '../models/PriceTypeCode';
import { PriceTypeCodeResponse } from '../models/PriceTypeCodeResponse';
import { SelectItem } from '../models/SelectItem';

const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class PriceTypeCodeService {
  apiRoot: string;
  types: SelectItem[] = [];

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.apiRoot = environment.cmApiRoot;
  }


  public getPriceTypeCodeList(): Observable<PriceTypeCode[]> {

    return this.http.get<PriceTypeCode[]>(`${this.apiRoot}/api/ContractPriceTypeCodes`);
  }


}
