<div style="overflow: auto; max-height: 260px">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortDataGrid($event)" matPaginator>
    <ng-container matColumnDef="definitionId">
      <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header> Definition ID </th>
      <td hidden="true" mat-cell *matCellDef="let element" title="{{element.definitionId}}"> {{ element.definitionId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="contractId">
      <th hidden="true" mat-header-cell *matHeaderCellDef mat-sort-header> Contract ID </th>
      <td hidden="true" mat-cell *matCellDef="let element" title="{{element.contractId}}"> {{ element.contractId }}</td>
    </ng-container>
    <ng-container matColumnDef="definitionType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Definition Type </th>
      <td mat-cell *matCellDef="let element" title="{{element.definitionType}}"> {{ element.definitionType }}</td>
    </ng-container>
    <ng-container matColumnDef="mony">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> MONY </th>
      <td mat-cell *matCellDef="let element"> {{ element.mony }}</td>
    </ng-container>
    <ng-container matColumnDef="tbg">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TBG </th>
      <td mat-cell *matCellDef="let element"> {{ element.tbg }}</td>
    </ng-container>
    <ng-container matColumnDef="daw">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> DAW </th>
      <td mat-cell *matCellDef="let element"> {{ element.daw }}</td>
    </ng-container>
    <ng-container matColumnDef="header-row-first-group" sticky="true">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;" [attr.colspan]="6">

        {{title}}
      </th>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="ui-toolbar-group-left" *ngFor="let item of currentToolbar;let i = index">
          <button class="inm-button" *ngIf="item.show" [disabled]="!canAddEdit" type="button"
            matTooltip="{{item.tip}}" (click)="editClick(element)">
            <svg class="inm-icon" aria-label="item.tip" *ngIf="item.icon">
              <use [attr.xlink:href]="item.icon">{{item.label}}</use>
            </svg>
            <div *ngIf="!item.icon">
              {{item.label}}
            </div>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

    </tr>
  </table>

</div>

<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner label="aria-label" color="accent" fxFlexOffset="50" fxFlexAlign="center" *ngIf="isLoadingResults">
  </mat-spinner>
</div>

<div style="position: sticky;">
  <mat-paginator #paginator [length]="totalItemCount" [pageIndex]="pageIndex" [pageSize]="30"
    [pageSizeOptions]="[5,10,15,30]" showFirstLastButtons (page)="onPageChange($event)">
  </mat-paginator>
</div>
