<div>
  <form [formGroup]="factorsForm" [style.fontSize.px]="14" class="form-content">
    <div class="flex-container">
      <fieldset class="factor-fieldset">
        <legend class="legend-content"></legend>
        <div class="flex-factor-container">
          <div class="flex-child">
            <div class="form-control-element">
              <div class="inm-label factor-label">COB</div>
              <div class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isExcludedCobCtrl" (change)="onChangeCob($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isExcludedCobCtrl" (change)="onChangeCob($event)">
                  <mat-radio-button [value]="false">Inc</mat-radio-button>
                  <mat-radio-button [value]="true">Exc</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="factor-control">
                <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
                  <mat-select (selectionChange)="checkSaveEnabled()" formControlName="factorCobsCtrl"
                    [compareWith]="compareCobObjectValues" multiple>
                    <mat-option *ngFor="let factorCob of factorCobList" [value]="factorCob.value"
                      [selected]="factorCobList">
                      {{ factorCob.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-control-element">
              <div class="inm-label factor-label">State</div>
              <div class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isExcludedStateCtrl" (change)="onChangeState($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isExcludedStateCtrl" (change)="onChangeState($event)">
                  <mat-radio-button [value]="false">Inc</mat-radio-button>
                  <mat-radio-button [value]="true">Exc</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="factor-control">
                <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
                  <mat-select (selectionChange)="checkSaveEnabled()" formControlName="factorStatesCtrl"
                    [compareWith]="compareStateObjectValues" multiple>
                    <mat-option *ngFor="let factorState of factorStateList" [value]="factorState.value"
                      [selected]="factorStateList">
                      {{ factorState.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-control-element">
              <div class="inm-label factor-label">Retail Banner/Store</div>
              <div class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isRetailBannerOrStoreCtrl" (change)="onChangeIsBannerSelected($event)">
                  <mat-radio-button [value]="true">Retail Banner</mat-radio-button>
                  <mat-radio-button [value]="false">Store</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isRetailBannerOrStoreCtrl" (change)="onChangeIsBannerSelected($event)">
                  <mat-radio-button [value]="true">Ret</mat-radio-button>
                  <mat-radio-button [value]="false">Store</mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="isBannerSelected" class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isExcludedRetailBannerCtrl" (change)="onChangeRetailBanner($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isExcludedRetailBannerCtrl" (change)="onChangeRetailBanner($event)">
                  <mat-radio-button [value]="false">Inc</mat-radio-button>
                  <mat-radio-button [value]="true">Exc</mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="isBannerSelected" class="factor-control">
                <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
                  <mat-select (selectionChange)="
                      onChangeBannerValues(); checkSaveEnabled()
                    " formControlName="factorRetailBannersCtrl" [compareWith]="compareRetailBannerObjectValues"
                    multiple>
                    <mat-option *ngFor="let retailBanner of retailBannerList" [value]="retailBanner.value"
                      [selected]="retailBannerList">
                      {{ retailBanner.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="!isBannerSelected" class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isExcludedStoreCtrl" (change)="onChangeStore($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isExcludedStoreCtrl" (change)="onChangeStore($event)">
                  <mat-radio-button [value]="false">Inc</mat-radio-button>
                  <mat-radio-button [value]="true">Exc</mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="!isBannerSelected" class="search-container">
                <mat-form-field appearance="outline" color="accent" fxFlexOffset="1" class="search-container">
                  <mat-label>Search for stores</mat-label>
                  <mat-hint style="margin-bottom: 5px"></mat-hint>
                  <input class="search-input" matInput id="factorStoreSearchCtrl" (keydown.enter)="loadStoreList()"
                    type="text" title="Search for Stores" formControlName="factorStoreSearchCtrl" maxlength="20" />
                  <button mat-icon-button matSuffix matSuffix type="button" (click)="loadStoreList()">
                    <svg class="inm-icon search-button" aria-label="Search">
                      <use [attr.xlink:href]="'#inm-icon__search'">Search</use>
                    </svg>
                  </button>
                </mat-form-field>
              </div>
              <div>
                <ul *ngIf="!isBannerSelected" TYPE="square" class="search-descr-container">
                  <li class="search-desc-bullet">You may search by store number, address, city or NCPDP number</li>
                  <li class="search-desc-bullet">You must enter at least 3 characters/ numbers to perform a partial search</li>
                </ul>
              </div>
              <div *ngIf="!isBannerSelected" class="search-container-dual-listbox">
                <dual-list class="all-dual-list" (destinationChange)=" onChangeStoreDestinationList($event); checkSaveEnabled() "
                  *ngIf="!isSmallerScreenSize" [sort]="true" [source]="storeList" [display]="storeKey" [key]="storeKey"
                  [(destination)]="storeDestinationList" [disabled]="!canAddEdit"></dual-list>
                <dual-list class="no-list-bar" (destinationChange)=" onChangeStoreDestinationList($event); checkSaveEnabled() "
                  *ngIf="isSmallerScreenSize" [format]="{ add: ' + ', remove: ' - ', all: '', none: '', direction: 'left-to-right', draggable: false, locale: undefined}" [sort]="true" [source]="storeList" [display]="storeKey" [key]="storeKey"
                  [(destination)]="storeDestinationList" [disabled]="!canAddEdit"></dual-list>
              </div>
            </div>
          </div>
          <div class="flex-child">
            <div class="form-control-element">
              <div class="inm-label factor-label">Paid at U&C</div>
              <div class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isExcludedUsualAndCustomaryCtrl"
                  (change)="onChangeUsualAndCustomary($event)" [disabled]="!canAddEdit">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isExcludedUsualAndCustomaryCtrl"
                (change)="onChangeUsualAndCustomary($event)" [disabled]="!canAddEdit">
                <mat-radio-button [value]="false">Inc</mat-radio-button>
                <mat-radio-button [value]="true">Exc</mat-radio-button>
              </mat-radio-group>
              </div>
              <div class="factor-control">
                <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
                  <mat-select (selectionChange)="checkSaveEnabled()" formControlName="factorUsualAndCustomarysCtrl"
                    [compareWith]="compareUsualAndCustomaryObjectValues" multiple>
                    <mat-option *ngFor="
                        let factorUsualAndCustomary of factorUsualAndCustomaryList
                      " [value]="factorUsualAndCustomary.value" [selected]="factorUsualAndCustomaryList">
                      {{ factorUsualAndCustomary.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-control-element">
              <div class="inm-label factor-label">Vaccines</div>
              <div class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isExcludedVaccineCtrl" (change)="onChangeVaccine($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isExcludedVaccineCtrl" (change)="onChangeVaccine($event)">
                  <mat-radio-button [value]="false">Inc</mat-radio-button>
                  <mat-radio-button [value]="true">Exc</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="factor-control">
                <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
                  <mat-select (selectionChange)="checkSaveEnabled()" formControlName="factorVaccinesCtrl"
                    [compareWith]="compareVaccineObjectValues" multiple>
                    <mat-option *ngFor="let factorVaccine of factorVaccineList" [value]="factorVaccine.value"
                      [selected]="factorVaccineList">
                      {{ factorVaccine.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-control-ar-element" >
              <div class="inm-label factor-label">
                Include Account Receivable
              </div>
              <div class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isARZeroDollarExcludedCtrl" (change)="onChangeARZeroDollar($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isARZeroDollarExcludedCtrl" (change)="onChangeARZeroDollar($event)">
                  <mat-radio-button [value]="false">Inc</mat-radio-button>
                  <mat-radio-button [value]="true">Exc</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="factor-control">
                <mat-checkbox (change)="onChangeHasZeroDollar($event)" formControlName="factorHasARZeroDollarCtrl"
                  labelPosition="after">AR Dollar = 0</mat-checkbox>
              </div>
            </div>
            <div class="form-control-element">
              <div class="inm-label factor-label">Injections</div>
              <div class="excluded-control">
                <mat-radio-group  *ngIf="!isSmallerScreenSize" formControlName="isExcludedInjectionsCtrl" (change)="onChangeInjections($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group  *ngIf="isSmallerScreenSize" formControlName="isExcludedInjectionsCtrl" (change)="onChangeInjections($event)">
                  <mat-radio-button [value]="false">Inc</mat-radio-button>
                  <mat-radio-button [value]="true">Exc</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="factor-control">
                <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
                  <mat-select (selectionChange)="checkSaveEnabled()" formControlName="factorInjectionsCtrl"
                    [compareWith]="compareInjectionObjectValues" multiple>
                    <mat-option *ngFor="let factorInjection of factorInjectionList" [value]="factorInjection.value"
                      [selected]="factorInjectionList">
                      {{ factorInjection.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-control-element">
              <div class="inm-label factor-label">OTC</div>
              <div class="excluded-control">
                <mat-radio-group *ngIf="!isSmallerScreenSize" formControlName="isExcludedOtcCtrl" (change)="onChangeOtc($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group *ngIf="isSmallerScreenSize" formControlName="isExcludedOtcCtrl" (change)="onChangeOtc($event)">
                  <mat-radio-button [value]="false">Inc</mat-radio-button>
                  <mat-radio-button [value]="true">Exc</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="factor-control">
                <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
                  <mat-select (selectionChange)="checkSaveEnabled()" formControlName="factorOtcsCtrl"
                    [compareWith]="compareOtcObjectValues" multiple>
                    <mat-option *ngFor="let factorOtc of factorOtcList" [value]="factorOtc.value"
                      [selected]="factorOtcList">
                      {{ factorOtc.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="factor-daw-fieldset">
        <legend class="legend-content">&nbsp;DAW &nbsp;</legend>
        <div class="flex-container">
          <div class="daw-flex-child">
            <div class="form-daw-control-element">
              <div class="inm-label factor-label">Brand</div>
              <div class="excluded-control">
                <mat-radio-group formControlName="isExcludedDawBrandCtrl" (change)="onChangeFactorDawBrand($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="factor-daw-control">
                <app-multiple-checkbox [data]="dawBrandData" formControlName="factorBrandDawsCtrl" [canAddEdit]="canAddEdit"
                  (sendMultiCheckBoxList)="onDawBrandCheckedList($event)"></app-multiple-checkbox>
              </div>
            </div>
            <div class="form-daw-control-element">
              <div class="inm-label factor-label">Generic</div>
              <div class="excluded-control">
                <mat-radio-group formControlName="isExcludedDawGenericCtrl" (change)="onChangeFactorDawGeneric($event)">
                  <mat-radio-button [value]="false">Include</mat-radio-button>
                  <mat-radio-button [value]="true">Exclude</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="factor-daw-control">
                <!-- Shouldn't the form control name be "factorGenericDawsCtrl" below? --JB -->
                <app-multiple-checkbox [data]="dawGenericData" formControlName="factorBrandDawsCtrl" [canAddEdit]="canAddEdit"
                  (sendMultiCheckBoxList)="onDawGenericCheckedList($event)"></app-multiple-checkbox>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div>
      <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center" *ngIf="isLoadingResults">
        </mat-spinner>
      </div>
    </div>
    <div class="flex-child action-box">
      <section class="inm-actions--confirmation button-section">
        <button class="inm-button--action button-spacing" [disabled]="saveDisabled || isLoadingResults || !canAddEdit"
          (click)="saveFactors()">
          Save
        </button>
        <button mat-dialog-close [disabled]="isLoadingResults" [disabled]="isLoadingResults" class="inm-button button-spacing" (click)="onReset()">
          Reset to last save
        </button>
      </section>
    </div>
  </form>
</div>
