<div>
  <div *ngIf="analyticsHome" class="analytics-on-demand">
    <h1></h1>
    <h2></h2>
  </div>
  <div class="loading-shade" *ngIf="!iFrameLoadComplete">
    <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center" *ngIf="!iFrameLoadComplete">
    </mat-spinner>
  </div>
  <iframe #looker *ngIf="iFrameReady" class="aod-iframe" [src]="this.SafeUrl" >
    <p>Your browser does not support iframes.</p>
  </iframe>
</div>

