import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthorizationService } from 'src/app/services/authorization.service';

export interface IActionItemsList {
  label?: string;
  name: string;
  tip?: string;
  icon?: string;
  disabled?: boolean;
  show: boolean;
  fileLink?: string;
  dropdownItems?: any[];
}

export class ActionItem {
  name: string;
  element: any;
}

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ContractDetailsComponent {
  tableCount: number = 0;
  dataSource = new MatTableDataSource<any>();
  isContractDetails: boolean = false;
  displayedColumns: string[] = [];
  Sort: string;
  sortOrder: string;
  limit: number;
  offset: number;
  pageIndex = 0;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @Output() OnClick: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();
  @Output() OnPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() OnSort: EventEmitter<Sort>= new EventEmitter<Sort>();
  @Input() canAddEdit: boolean = true;
  @Input() actionItemList: IActionItemsList[];
  @Input() set data(value) {
    if (value == null || value == undefined || value.length === 0) {
      return;
    }
    if (value.hasContractDetails) {
      this.isContractDetails = true;
      this.displayedColumns = ['contractDetailId', 'bin',  'pcn', 'groupNumber', 'networkId', 'actions' ];
    }
    else {
      this.displayedColumns = ['industryContractTemplateDetailId', 'bin', 'pcn', 'groupNumber', 'networkId', 'actions' ];
    }
    this.dataSource = new MatTableDataSource(value.data)
    this.tableCount = value.count;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
    this.dataSource.sort = this.sort;
  }

  renderTableRow() {
    this.table.renderRows();
    this.dataSource._updateChangeSubscription();
  }

  onPageChange(event: PageEvent): void {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * event.pageSize;

    this.OnPageChange.emit(event);
  }

  sortData(sort: Sort): void {
    this.Sort = sort.active;
    this.sortOrder = sort.direction;
    this.paginator.firstPage();

    this.OnSort.emit(sort);
  }

  actionOnClick(name:string, element: any) {
    this.OnClick.emit({name, element});
  }
}

