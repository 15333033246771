<h3 mat-dialog-title class="inm-headline">&nbsp;&nbsp;{{pagetitle}}</h3>
<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>  
  <div fxLayout="row wrap" fxLayout.sm="column" class="col-3" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="left">

    <form [formGroup]="contractForm" [style.fontSize.px]="14" class="form-content">
      <div>
        <mat-form-field style="width: 700px; height: 150px;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Amendment Description</mat-label>
          <textarea required rows="5" cols="40" style="max-width: 720px; max-height: 100px; resize: none;"
            (input)="checkSaveEnabled()" title="Contract Amendment" placeholder="Max 5000 characters"
            matInput formControlName="amendmentDescription" maxlength="5000" noWhitespace>
          </textarea>
        </mat-form-field>
      </div>
      <div>
      </div>
    </form>
  </div>
  <section class='inm-actions--confirmation'>
    <button class="inm-button--action button-spacing" [disabled]="saveDisabled || isLoadingResults"
      (click)="saveAmendment()">Save</button>
    <button class="inm-button button-spacing" [disabled]="isLoadingResults" (click)="onCancelClick()">Cancel</button>
  </section>
</div>