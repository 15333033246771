import { Route, Routes } from '@angular/router';
import * as _ from 'lodash';

import { HealthcheckComponent } from './components/healthcheck/healthcheck.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';

export const appRoutes: Routes = [
  { path: 'healthcheck', component: HealthcheckComponent },
  {
    path: 'cm',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/templates/template.module').then((m) => m.TemplateModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'looker',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/looker/looker.module').then((m) => m.LookerModule),
  },
  { path: '', component: LandingPageComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent },
];

export function componentFromRoute(route: string): Route {
  const myRoute = _.find(appRoutes, { path: route });
  return myRoute;
}
