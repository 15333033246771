import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IndustryContractTemplateDetail } from 'src/app/models/IndustryContractTemplateDetail';
import { UploadTemplateDetailsResponse } from 'src/app/models/UploadTemplateDetailsResponse';
import { TemplateDetailsService } from 'src/app/services/template-details.service';

import { UploadContractDetailData } from '../../contracts/create-edit-detail/upload-contract-detail-data';
import { AddEditTemplateDetailsDialogData } from './add-edit-template-details-dialog-data';
import { UploadTemplateDetailData } from './upload-template-detail-data';

@Component({
  selector: 'app-add-edit-template-details-dialog',
  templateUrl: './add-edit-template-details-dialog.component.html',
  styleUrls: ['./add-edit-template-details-dialog.component.less']
})

export class AddEditTemplateDetailsDialogComponent implements OnInit {
  editMode: boolean = true;
  saveDisabled: boolean = true;
  isLoadingResults: boolean = false;
  templateDetailsForm: FormGroup;
  templateDetail: IndustryContractTemplateDetail;

  fileUploadData: any;
  validationErrorMessage = '';
  warningMessage = 'Bulk upload. All current template details will be deleted.'
  detailEntries: IndustryContractTemplateDetail[] = [];
  binLength = 0;
  binNumeric = 0;
  groupLength = 0;
  pcnLength = 0;
  networkIdLength = 0;
  duplicateRecords = 0;
  emptyRows = 0;

  constructor(
    public dialogRef: MatDialogRef<AddEditTemplateDetailsDialogData>,
    private fb: FormBuilder,
    private templateDetailsService: TemplateDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: AddEditTemplateDetailsDialogData
  ) {
    if(this.data.action === 'add') {
      this.editMode = false;
    }
  }

  ngOnInit(): void {
    this.templateDetailsForm = this.fb.group({
      binCtrl: ['', Validators.pattern("[0-9]{6,}$")],
      groupNumberCtrl: [''],
      pcnCtrl: [''],
      networkIdCtrl: ['']
    });
    this.templateDetailsForm.setValidators(this.atLeastOneValidator());
    this.populateFieldsIfEdit();

    this.loadTemplateDetailFileData();
  }

  loadTemplateDetailFileData() {
    this.fileUploadData = {
      fileUploadComponent: 'TemplateDetailsUpload',
      industryContractTemplate: this.templateDetail.industryContractTemplateId,
      maxFileSize: 10240000,
      acceptedFileTypes: ['text/csv'],
      message: 'Drag and drop .csv here',
      topMessage: '1 file only. 10MB limit.',
      errorMessage: 'Single CSV file only. Max limit 10MB. No duplicate entries.',
      checkForDuplicateNames: true,
      maxNumberOfFiles: 1
    };
  }

  uploadTemplatetDetailCsv(upload: UploadTemplateDetailsResponse) {
    if (upload.status) {
      this.validateAndAddTemplateDetailRecords(upload.templateDetails, this.templateDetail.industryContractTemplateId);

      if (!this.hasInvalidRecords && this.detailEntries.length > 0) {
        this.uploadTemplateDetails(this.templateDetail.industryContractTemplateId)
      }
    }
    else {
      this.validationErrorMessage = '';
    }
  }

  hasInvalidRecords = false;
  contractDetailsArray: string[] = [];
  validateAndAddTemplateDetailRecords(records: string[], templateId: number) {
    // parse through records and add to array to determine if there are duplicates
    this.contractDetailsArray = [];
    this.binLength = 0;
    this.binNumeric = 0;
    this.pcnLength = 0;
    this.networkIdLength = 0;
    this.groupLength = 0;
    this.hasInvalidRecords = false;
    this.duplicateRecords = 0;
    // start by going through the body of records - contract detail items in the csv
    if (records.length > 0) {
      for (let item = 0; item < records.length; item++) {

        // split into an array based on the comma
        let data = records[item].split(',');

        if (data.length != 4) {
          // throw an error - invalid
        }

        var bin = data[0].trim();
        var pcn = data[1].trim();
        var group = data[2].trim();
        var networkId = data[3].trim();

        // network id can be blank or null but cannot be longer than 20 characters
        if (networkId.length > 20) {
          this.networkIdLength++;
        }

        // pcn cannot have a value greater than 20
        if (pcn.length > 20) {
          this.pcnLength++;
        }

        // group cannot have a value greater than 15
        if (group.length > 15) {
          this.groupLength++;
        }

        // bin can be blank, but must be 6 characters in length and must be numeric
        if (bin.length != 6 && bin.length > 1) {
          this.binLength++;
        }

        if (!this.isNumber(bin) && bin.length > 1) {
          this.binNumeric++;
        }

        // ok ... create a string based on all 4 values (null for blanks)
        let allDetails = (networkId == null || networkId.length < 1 ? 'null' : networkId) + (pcn == null || pcn.length < 1 ? 'null' : pcn) + (group == null || group.length < 1 ? 'null' : group) + (bin == null || bin.length < 1 ? 'null' : bin);

        if (this.contractDetailsArray.includes(allDetails)) {
          // we have a duplicate
          this.duplicateRecords++;
        }
        else {
          this.contractDetailsArray.push(allDetails);
        }

        // rows cannot be empty
        if (bin.length < 1 && pcn.length < 1 && group.length < 1 && networkId.length < 1) {
          this.emptyRows++;
        }

        // there could be thousands of records here, so circumvent the process if we have an error
        if (this.binLength > 0 || this.binNumeric > 0 || this.pcnLength > 0 || this.groupLength > 0 || this.networkIdLength > 0 || this.duplicateRecords > 0 || this.emptyRows > 0) {
          this.hasInvalidRecords = true;
          this.detailEntries = [];
        }

        // now add a detail entry as long as there are no invalid records
        if (!this.hasInvalidRecords) {
          this.detailEntries.push({'industryContractTemplateId': templateId, 'industryContractTemplateDetailId': 0, 'bin': bin, 'networkId': networkId, 'groupNumber': group, 'pcn': pcn});
        }
      }
    }

    // create proper error message to display

    if (this.binNumeric > 0) {
      this.validationErrorMessage += +this.binNumeric + ' invalid BIN' + (this.binNumeric > 1 ? 's' : '') + ' found in the file. Only numbers are allowed. <br/>';
      this.binNumeric = 0;
      this.hasInvalidRecords = true;
    }
    if (this.binLength > 0) {
      this.validationErrorMessage += +this.binLength + ' BIN' + (this.binLength > 1 ? 's' : '') + ' exceed' + (this.binLength < 2 ? 's' : '') + ' 6 digits. <br/>';
      this.binLength = 0;
      this.hasInvalidRecords = true;
    }
    if (this.pcnLength > 0) {
      this.validationErrorMessage += +this.pcnLength + ' PCN' + (this.pcnLength > 1 ? 's' : '') + ' exceed' + (this.pcnLength < 2 ? 's' : '') + ' 20 characters. <br/>';
      this.pcnLength = 0;
      this.hasInvalidRecords = true;
    }
    if (this.groupLength > 0) {
      this.validationErrorMessage += +this.groupLength + ' Group' + (this.groupLength > 1 ? 's' : '') + ' exceed' + (this.groupLength < 2 ? 's' : '') + ' 15 characters. <br/>';
      this.groupLength = 0;
      this.hasInvalidRecords = true;
    }
    if (this.networkIdLength > 0) {
      this.validationErrorMessage += +this.networkIdLength + ' Network' + (this.networkIdLength > 1 ? 's' : '') + ' exceed' + (this.networkIdLength < 2 ? 's' : '') + ' 20 characters. <br/>'
      this.networkIdLength = 0;
      this.hasInvalidRecords = true;
    }
    if (this.emptyRows > 0) {
      this.validationErrorMessage += +this.emptyRows + ' empty row' + (this.emptyRows > 1 ? 's' : '') + '. <br/>';
      this.emptyRows = 0;
      this.hasInvalidRecords = true;
    }
    if (this.duplicateRecords > 0) {
      this.validationErrorMessage += this.duplicateRecords + ' duplicate Contract Detail' + (this.duplicateRecords > 1 ? 's' : '') + ' found in the file. <br/>';
      this.duplicateRecords = 0;
      this.hasInvalidRecords = true;
    }

    if (this.hasInvalidRecords) {
      this.validationErrorMessage = '<b class="error-messages">* FILE UPLOAD FAILED</b><br/>' + this.validationErrorMessage;
    }

    if (records.length < 1) {
      this.binLength = 0;
      this.pcnLength = 0;
      this.groupLength = 0;
      this.networkIdLength = 0;
      this.emptyRows = 0;
      this.duplicateRecords = 0;
      this.hasInvalidRecords = true;
      this.validationErrorMessage = '<b class="error-messages">* NO DETAILS UPLOADED.  CSV FILE IS EMPTY</b><br/>' + this.validationErrorMessage;
    }

  }

  uploadTemplateDetails(templateId: number) {
    // get data from csv and add to array of contract details
    this.isLoadingResults = true;

    this.templateDetailsService.createTemplateDetailsBulk(templateId, this.detailEntries)
      .subscribe({
        next: (result) => {
          const returnData: UploadTemplateDetailData = {
            action: 'close',
            title: '',
            templateId: this.templateDetail.industryContractTemplateId
          }
          toastr.success('Template details were uploaded successfully!')
          this.dialogRef.close(returnData);
          this.isLoadingResults = false;
        },
        error: (err) => {
          toastr.error('We are unable to upload Template Details at this time.')
          this.isLoadingResults = false;
          console.log(err);
        }
      });

  }

  isNumber(value?: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  populateFieldsIfEdit() {
    this.templateDetail = this.data.templateDetail;
    if (this.editMode) {
      this.templateDetailsForm.controls.binCtrl.setValue(this.templateDetail.bin);
      this.templateDetailsForm.controls.groupNumberCtrl.setValue(this.templateDetail.groupNumber);
      this.templateDetailsForm.controls.pcnCtrl.setValue(this.templateDetail.pcn);
      this.templateDetailsForm.controls.networkIdCtrl.setValue(this.templateDetail.networkId);
    }
  }

  checkSaveEnabled(): void {
    this.saveDisabled =
      this.templateDetailsForm.pristine ||
      this.templateDetailsForm.invalid ||
      this.checkValuesChange();
  }

  checkValuesChange(): boolean {
    if (this.editMode) {
      const bin = this.templateDetailsForm.controls.binCtrl.value;
      const groupNumber = this.templateDetailsForm.controls.groupNumberCtrl.value;
      const pcn = this.templateDetailsForm.controls.pcnCtrl.value;
      const networkId = this.templateDetailsForm.controls.networkIdCtrl.value;
      return (
        bin === this.templateDetail.bin &&
        groupNumber === this.templateDetail.groupNumber &&
        pcn === this.templateDetail.pcn &&
        networkId === this.templateDetail.networkId
      );
    }
    return false;
  }

  saveTemplateDetails() {
    const templateDetailRequestModel: IndustryContractTemplateDetail = {
      industryContractTemplateDetailId: this.templateDetail.industryContractTemplateDetailId,
      bin: this.getStringValueOrNull(this.templateDetailsForm.controls.binCtrl.value),
      groupNumber: this.getStringValueOrNull(this.templateDetailsForm.controls.groupNumberCtrl.value),
      pcn: this.getStringValueOrNull(this.templateDetailsForm.controls.pcnCtrl.value),
      networkId: this.getStringValueOrNull(this.templateDetailsForm.controls.networkIdCtrl?.value),
      industryContractTemplateId: this.templateDetail.industryContractTemplateId
    }

    if (this.editMode) {
      this.isLoadingResults = true;
      this.templateDetailsService.updateTemplateDetails(this.templateDetail.industryContractTemplateDetailId, templateDetailRequestModel)
        .subscribe({
          next: (result) => {
            const returnData: AddEditTemplateDetailsDialogData = {
              action: 'close',
              title: '',
              templateDetail: result
            }
            toastr.success('Industry contract template details was updated successfully!')
            this.dialogRef.close(returnData);
            this.isLoadingResults = false;
          },
          error: (err) => {
            toastr.error('We are unable to create new Industry Contract Template Details at this time.')
            this.isLoadingResults = false;
            console.log(err)
          }
        });
    }
    else {
      this.isLoadingResults = true;
      let templateDetails: IndustryContractTemplateDetail[] = [];
      templateDetails.push(templateDetailRequestModel);
      this.templateDetailsService.createTemplateDetails(this.templateDetail.industryContractTemplateId, templateDetails)
        .subscribe({
          next: (result) => {
            const returnData: AddEditTemplateDetailsDialogData = {
              action: 'close',
              title: '',
              templateDetail: result[0] // Set to 0 because there's only one value returned
            }
            toastr.success('Industry contract template details was created successfully!')
            this.dialogRef.close(returnData);
            this.isLoadingResults = false;
          },
          error: (err) => {
            toastr.error('We are unable to create new Industry Contract Template Details at this time.')
            this.isLoadingResults = false;
            console.log(err);
          }
        });
    }
  }

  private atLeastOneValidator = () => {
    return (controlGroup) => {
        let controls = controlGroup.controls;
        if ( controls ) {
            var theOne = Object.keys(controls).find(key=> this.getStringValueOrNull(controls[key].value) !== null);
            if ( !theOne) {
                return {
                    atLeastOneRequired : {
                        text : 'At least one should have a value.'
                    }
                }
            }
        }
        return null;
    };
  }

  getStringValueOrNull(value: string | null | undefined): string | null {
    return value === "" ? null : value ?? null;
  }
}
