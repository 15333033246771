import { AuthorizationService } from "src/app/services/authorization.service";

export class ContractDetailsActionItemsHelper {
  public showAction: string;
  public currentActionItems = [];
  private readonly ACTION_ITEMS = [
    {
      feature: 'ContractManagement::AddEditContract',
      item: {
        label: 'Edit Contract Details',
        name: 'editContractDetails',
        tip: 'Edit Contract Details',
        icon: '#inm-icon__edit',
        disabled: false,
        show: true,
      },
    },
  ];

  constructor(private authService: AuthorizationService) {
    this.generateToolbarData();
  }

  generateToolbarData(): void {
    for (const actions of this.ACTION_ITEMS) {
      if (!actions.feature) {
        this.currentActionItems.push(actions.item);
        continue;
      }
      if (actions.feature && this.authService.hasFeature(actions.feature)) {
        this.currentActionItems.push(actions.item);
      }
    }
  }
}
