import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../environments/environment';
import { ContractDocument } from "../models/ContractDocument";
import { ContractDocumentResponse } from "../models/ContractDocumentResponse";
import { Pagination } from "../models/Pagination";
import { GlobalFilterService } from "./global-filter.service";

@Injectable({
  providedIn: 'root',
})
export class ContractDocumentService {
  private readonly Timeout: number = 30000;
  apiRoot: string;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService) {
    this.apiRoot = environment.cmApiRoot;
  }

  getContractDocumentsForContractId(
    pagination: Pagination,
    ContractId?: number
  ) : Observable<ContractDocumentResponse> {
    const params = pagination.buildSearchParams();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<ContractDocumentResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contractfile/contracts/${ContractId}`,  { params });
  }

  addContractDocument(
    file:FormData,
    ContractId?: number
  ) : Observable<ContractDocument>  {
      let fileUuid = uuidv4();
      return this.http.post<ContractDocument>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contractfile/AddContractFile/${ContractId}?guid=${fileUuid}`, file);
  }

  getContractDocumentByGuid(ContractId: number, ContractFileGuid: string, ContractFilename: string ) : Observable<ArrayBuffer> {
    const headers = new HttpHeaders().set('Content-Type', 'application/pdf');

    var result = this.http.get(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contractfile/ContractPdfByGuid/${ContractId}?contractFileGuid=${ContractFileGuid}&contractFilename=${ContractFilename}`, { headers: headers, responseType: 'arraybuffer' });
    return result
  }
}
