import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortModule, MatSortable } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ContractRate } from 'src/app/models/ContractRate';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ManageRateToolbarHelper } from '../../contracts/manage/helper/manage-rate-toolbar.helper';
import { ToolbarAction } from '../table-toolbar/ToolBarAction';
import { P } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-rates-grid',
  templateUrl: './rates-grid.component.html',
  styleUrls: ['./rates-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RatesGridComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [];
  editDisabled: boolean = false;
  selectionDisabled: boolean = false;
  title: string;

  public currentActions = [];

  private readonly ACTION_ITEMS = [
    {
      feature: 'ContractManagement::AddEditContract',
      item: {
        label: 'Edit Rate',
        name: 'editRate',
        tip: 'Edit Rate',
        icon: '#inm-icon__edit',
        disabled: false,
        show: true,
      },
    },
  ];

  public readonly viewFeatures = [
    {
      feature: 'ContractManagement::ViewContract',
      name: 'view',
      accessable: false,
    },
  ];

  selection = new SelectionModel<ContractRate>(true, []);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @Output() editRateClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() addRateClicked = new EventEmitter<any>();
  @Output() deleteRateClicked = new EventEmitter<any>();
  @Input() canAddEdit: boolean = true;
  @Input() set data(value) {
    if (value == null || value == undefined) {
      return;
    }

    this.title = value.rateDesc;

    this.displayedColumns = [
      'select',
      'rateId',
      'rateTypeDescription',
      'priceTypeCodeDescription',
      'appliedRate',
      'dispensingFee',
      'dirType',
      'assessmentWindowDescription',
      'dirAmount',
      'daysSupply',
      'rateEffectiveStartDate',
      'editAction',
    ];

    this.sort.sort({ id: 'rateId', start: 'asc', disableClear: false });
    this.dataSource = new MatTableDataSource(value.data);

    if (value.data.length == 0) {
      this.disableSelection();
      this.manageRateToolbarHelper.deleteDisable();
    }

    this.dataSource.sortingDataAccessor = (
      data: any,
      sortHeaderId: string
    ): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };

    this.dataSource.sort = this.sort;
  }
  public readonly manageRateToolbarHelper: ManageRateToolbarHelper;

  constructor(private authService: AuthorizationService) {
    this.generateActionItemData();
    this.checkFeatures();
    this.manageRateToolbarHelper = new ManageRateToolbarHelper(
      this.authService
    );
    this.manageRateToolbarHelper.deleteDisable();
  }

  ngOnInit(): void {}

  renderTableRow() {
    this.table.renderRows();
    this.dataSource._updateChangeSubscription();
    if (this.dataSource.data.length == 0) {
      this.disableSelection();
      this.manageRateToolbarHelper.deleteDisable();
    } else {
      this.enableSelection();
    }
  }

  editRate(element) {
    this.editRateClicked.emit(element);
  }

  enabledisableActions() {
    //We want to only enable delete when one item is selected
    //We want the edit to be disabled if they select more than one item
    if (this.selection.selected.length > 0) {
      this.manageRateToolbarHelper.deleteEnable();
      this.editDisabled = true;
    } else {
      this.manageRateToolbarHelper.deleteDisable();
      this.editDisabled = false;
    }
  }

  toolbarBtnClick(toolbaraction: ToolbarAction) {
    if (toolbaraction.name == 'addRate') {
      this.addRateClicked.emit();
    } else if (toolbaraction.name == 'deleteRate') {
      if (this.selection.selected.length > 0) {
        this.deleteRateClicked.emit(this.selection.selected);
      }
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.enabledisableActions();
  }

  generateActionItemData(): void {
    for (const action of this.ACTION_ITEMS) {
      if (!action.feature) {
        this.currentActions.push(action.item);
        continue;
      }
      if (action.feature && this.authService.hasFeature(action.feature)) {
        this.currentActions.push(action.item);
      }
    }
  }

  disableSelection(): void {
    this.selectionDisabled = true;
  }

  enableSelection(): void {
    this.selectionDisabled = false;
  }

  private checkFeatures(): void {
    for (const vwFeature of this.viewFeatures) {
      if (this.authService.hasFeature(vwFeature.feature)) {
        vwFeature.accessable = true;
      }
    }
  }
}
