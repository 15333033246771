<h3 mat-dialog-title class="inm-headline">
  &nbsp;&nbsp;{{pagetitle}}</h3>

  <div mat-dialog-content>
    <div fxLayout="row wrap" fxLayout.sm="column" class="col-3" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
    <div style="margin-left: 2px;" [innerHTML]=" message"></div>
  </div>

  <div class="float-child" style="width: 100%;">
    <section class='inm-actions--confirmation'>
      <button class="inm-button--action button-spacing" (click)="onConfirmClick()">{{confirmButtonText}}</button>
        <button class="inm-button button-spacing" [mat-dialog-close]="true">{{cancelButtonText}}</button>
    </section>
  </div>
</div>