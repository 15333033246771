import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminSummaryComponent } from 'src/app/components/admin/admin-summary/admin-summary.component';
import { NoAccessComponent } from 'src/app/components/no-access/no-access.component';

import { AuthGuard } from '../../guards/auth.guard';
import { FeatureGuard } from '../../guards/feature.guard';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminSummaryComponent,
    children: [
      { path: '', redirectTo: 'payee', pathMatch: 'full' },
      {
        path: 'no-accesss',
        component: NoAccessComponent,
      },
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
