import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { ToolbarAction } from './ToolBarAction';

export interface IToolbarItem {
  label?: string;
  name: string;
  tip?: string;
  icon?: string;
  disabled?: boolean;
  show: boolean;
  fileLink?: string;
  dropdownItems?: any[];
}

@Component({
  selector: 'app-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableToolbarComponent {
  @Input() toolbarItemList: IToolbarItem[];
  @Output() OnClick: EventEmitter<ToolbarAction> = new EventEmitter<ToolbarAction>();
  @Output() valueSelected = new EventEmitter<any>();
  constructor() {}

  toolbarBtnClick(name: string, index: number): void {
    this.OnClick.emit({ name, index });
  }

  optionWasSelected(value: any): void {
    this.valueSelected.emit(value);
  }
}
