<div class="mat-toolbarcontainer">
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation"
      style="height: 400px; position: fixed; margin-top: 80px;">
      <mat-nav-list>
        <ul id="hamburgerMenuItems">
          <li> <button style="float: right; cursor: pointer;" (click)="sidenav.close()">
              Close </button> </li>
          <div *ngFor="let section of sections">
            <li *ngIf="checkValidLink(section.feature)">
              <a *ngIf="section.isLegacyLink && !section.subSection && checkValidLink(section.feature)"
                [href]="section.path" (click)="sidenav.close()">{{ section.name }}</a>
              <a *ngIf="!section.isLegacyLink && !section.subSection && checkValidLink(section.feature)"
                routerLinkActive="active" [routerLink]="section.path"
                [routerLinkActiveOptions]="{ exact: false }"
                (click)="sidenav.close()">{{ section.name }}</a>
            </li>
          </div>
        </ul>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary">
        <div class="portalnav__title"></div>
        <div class="portalnav__content"></div>
        <span class='rebrand-logo'></span>
        <span class="inm-logo"></span>
        <a class="navbar-brand inmar-logo-text"
          style="margin-left: 35px; margin-bottom: 40px !important"
          href="{{healthcareContractManagementLink}}"><span
            class="inmar-logo-text-a">CONTRACT</span><span
            class="inmar-logo-text-b">MANAGEMENT</span></a>
        <div class="mat-icon" *ngIf="hamburger" style="color: white; margin-left: 25px; margin-bottom: 5px; overflow: visible;">
          <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div *ngIf="!hamburger" fxLayout="row" fxLayoutAlign="start">
          <ul id="menuItems">
            <li *ngFor="let section of sections">
              <a *ngIf="section.isLegacyLink && !section.subSection && checkValidLink(section.feature)"
                [href]="section.path">{{ section.name }}</a>
              <a *ngIf="!section.isLegacyLink && !section.subSection && checkValidLink(section.feature)"
                routerLinkActive="active" [routerLink]="section.path"
                [routerLinkActiveOptions]="{ exact: false }">{{ section.name }}</a>
            </li>
          </ul>
        </div>
        <span fxFlex></span>
        <div class="portal-navbar">

            <div *ngIf="clientsLoaded && usersClients.length > 1">

                  <p-dropdown autoWidth="false" [options]="usersClients" (onChange)="clientSelected($event)" class="client-droplist"></p-dropdown>

            </div>

            <div class="portal-navbar" *ngIf="clientsLoaded && usersClients.length === 1">
              <div><h4 class="client-label">{{usersClients[0]['label']}}</h4></div>
            </div>
        </div>
      </mat-toolbar>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<div style="margin-bottom: 5px; margin-left: 20px;">
  <div name="healthcareTopBar" id="healthcareTopBar"></div>
</div>
