import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ContractAmendmentResponse } from '../models/contract-amendment-response';
import { Pagination } from '../models/Pagination';
import { GlobalFilterService } from './global-filter.service';
import { AmendmentResponse } from '../models/AmendmentResponse';

@Injectable({
  providedIn: 'root'
})
export class ContractAmendmentService {

  private readonly Timeout: number = 30000;
  apiRoot: string;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService) {
    this.apiRoot = environment.cmApiRoot;
  }

  //  Retrieves the set of Definitions for a Contract.
  getContractAmendmentsByContractId(pagination: Pagination, ContractId?: number)
    : Observable<ContractAmendmentResponse> {
    const params = pagination.buildSearchParams();

    return this.http.get<ContractAmendmentResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${ContractId}/amendments`,  { params });
  }


  amendContract(contractId: number, amendmentDescription: string) : Observable<AmendmentResponse> {
    const params = {
      description: amendmentDescription
    }
    return this.http.post<AmendmentResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${contractId}/amendments`, params);
  }
}
