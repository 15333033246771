import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ContractNote } from 'src/app/models/ContractNote';
import { Pagination } from 'src/app/models/Pagination';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ContractService } from 'src/app/services/contractService';

import { NoteDialogComponent } from './note-dialog/note-dialog.component';

@Component({
  selector: 'app-contract-notes-component',
  templateUrl: './contract-notes-component.html',
  styleUrls: ['./contract-notes-component.less']
})
export class ContractNotesComponent {

  dataSource = new MatTableDataSource<any>();
  isContractNotes: boolean = false;
  displayedColumn: string[] = [];
  public currentToolbar = [];
  sortedData: any;
  local: ContractNote[];
  Sort = 'contractNoteId';
  sortOrder = 'asc';
  offset = 0;
  limit = 30;
  pageIndex = 0;
  totalItemCount: number;
  contractId: number;
  isLoadingResults = false;
  selection = new SelectionModel<ContractNote>(true, []);

  private readonly ACTION_ITEMS = [

  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @Output() EditOnClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private authService: AuthorizationService,
    private contractService: ContractService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.contractId = +this.route.snapshot.paramMap.get('id');
  }

  @Input() set data(value: ContractNote[]) {
    if (value == null || value == undefined || value.length === 0)
    {
      return;
    }
    this.local = value;
    this.displayedColumn = ['contractNoteId', 'note', 'createdDate', 'createdBy'];
    this.sort.sort({ id: 'contractNoteId', start: 'desc', disableClear: false })
    this.dataSource = new MatTableDataSource(this.local)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  shownotedialog(note) {
    const dialogRef = this.dialog.open(NoteDialogComponent, {
      width: '1075px',
      maxHeight: '800px',
      data: note
    });
  }

  loadContractNotes(): void {
    if (this.contractId) {
      this.isLoadingResults = true;
      this.contractService.getContractNotes(
        new Pagination(this.limit, this.offset, this.Sort, this.sortOrder),
        this.contractId).subscribe(
          (results) => {
            var loc = results;
            this.totalItemCount = results.totalItemCount;
            this.local = results.items;
            this.dataSource = new MatTableDataSource(this.local);
            this.isLoadingResults = false;
          },
          (err) => {
            this.isLoadingResults = false;
            toastr.error('We are unable to load ContractNotes at this time');
          }
        )
    }

  }

  onPageChange(event: PageEvent): void {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * event.pageSize;
    this.selection.clear();

    this.loadContractNotes();
  }

  sortData(sort: Sort) {

    this.Sort = sort.active;
    this.sortOrder = sort.direction;
    this.paginator.firstPage();
    this.loadContractNotes();

  }
}
