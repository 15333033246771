<section class="inm-layout-sidebar">
  <nav id="navbar" class="inm-expandable--sidebar inm-layout-sidebar__nav">
    <app-looker-side-nav (loadComplete)="sideNavDashboardItemsComplete($event)"></app-looker-side-nav>
  </nav>
  <div class="inm-layout-sidebar__content-area">
    <!-- main site content goes here -->
    <section class="inm-layout-main__content">
      <router-outlet (activate)="routeChanged()"> </router-outlet>
    </section>
  </div>
</section>
