import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContractAmendmentService } from 'src/app/services/contract-amendment.service';
import { AmendContractData } from './amend-contract-data';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { AmendmentResponse } from 'src/app/models/AmendmentResponse';

@Component({
  selector: 'app-amend-contract',
  templateUrl: './amend-contract.component.html',
  styleUrls: ['./amend-contract.component.scss']
})
export class AmendContractComponent implements OnInit{
  contractForm: UntypedFormGroup;
  saveDisabled = true;
  contractId: number;
  clientId: number;
  pagetitle: string;
  isLoadingResults = false;

  ngOnInit() {
    this.initValidators();
    this.pagetitle = 'Add Amendment Description';
    this.contractId = this.data.contractId;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private amendmentService: ContractAmendmentService,
    public dialogRef: MatDialogRef<AmendContractComponent>,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: AmendContractData) {

  }

  saveAmendment() {
    this.amendContract();
  }

  initValidators(): void {
    this.contractForm = this.fb.group({
      amendmentDescription: ['']
    })
  }

  checkSaveEnabled(): void {
    if (this.contractForm.controls.amendmentDescription.value.trim() != '') {
      this.saveDisabled = false;
    }
    else {
      this.saveDisabled = true;
    }
  }

  onCancelClick() {
    if (this.contractForm.controls.amendmentDescription.value.trim() === '') {
      this.dialogRef.close();
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '500px',
        maxHeight: '500px',
        data: {
          message: 'Are you sure want to cancel without saving Data?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });
      dialogRef.afterClosed().subscribe((returnedData: string) => {
        if (returnedData == 'close') {
          this.dialogRef.close('close');
        }
      })
    }

  }

  amendContract(): void {
    this.saveDisabled = true;
    this.isLoadingResults = true;
    this.contractId = this.data.contractId;
    var note = this.contractForm.controls.amendmentDescription.value;
    this.amendmentService.amendContract(this.contractId, note.trim()).subscribe(
      (results: AmendmentResponse) => {
        this.isLoadingResults = false;
        toastr.success('Contract amended successfully!');
        this.router.navigate([`/cm/contracts/contractview/${results.contractId}/view`]);
        this.dialogRef.close();
      },
      (err) => {
        toastr.error('We are unable to amend a contract at this time.');
        this.saveDisabled = false;
        this.isLoadingResults = false;
      }
    )
  }
}
