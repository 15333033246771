<h3 mat-dialog-title class="inm-headline">{{pageTitle}}</h3>
<div mat-dialog-content>
  <div fxLayout="row wrap">
    <ng-container *ngIf="mode==='add'">
      <p style="font-size:14px;padding:0px 0px 14px 0px;">You may add multiple definitions by clicking the "Add" button. When done, click the "Close" button to close the pop-up form.</p>
    </ng-container>
    <ng-container class="error-messages" *ngIf="definitionForm.errors?.['noMonyTbgOrDawSelection'] && (definitionForm.touched || definitionForm.dirty)">
      <span class="error-messages">Please select at least one value for MONY, TBG or DAW.</span>
    </ng-container>
    <div>
      <form [formGroup]="definitionForm" [style.fontSize.px]="14" style="display:flex;" class="content form-content" fxLayoutWrap>
        <div flex="33%" class="hc-dialog-content">
          <mat-label style="display:block;width:125px;">Definition Type:* </mat-label>
          <mat-radio-group required formControlName="definitionTypeGroup" (change)="checkSaveEnabled($event)" [ngModel]="definitionToEdit.definitionType"
              aria-label="Brand / Generic:*" class="hc-radio-button-group">
            <mat-radio-button aria-labelledby="definitionTypeGroup" *ngFor="let item of brandGenericItems" [value]="item">{{item}}</mat-radio-button>
          </mat-radio-group>
          <mat-label>MONY: </mat-label>
          <mat-radio-group formControlName="monyGroup" class="hc-radio-button-group" (change)="checkSaveEnabled($event)" [ngModel]="monyDisplay">
            <mat-radio-button aria-labelledby="monyGroup" color="accent" disableRipple *ngFor="let item of monyItems" [value]="item">{{item}}</mat-radio-button>
          </mat-radio-group>
          <mat-label>TBG: </mat-label>
          <mat-radio-group formControlName="tbgGroup" class="hc-radio-button-group" (change)="checkSaveEnabled($event)" [ngModel]="tbgDisplay">
            <mat-radio-button aria-labelledby="tbgGroup" color="accent" disableRipple *ngFor="let item of tbgItems" [value]="item">{{item}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div flex="33%">
          <mat-label>DAW: </mat-label>
          <div>
            <mat-checkbox color="accent" disableRipple *ngFor="let item of dawCheckboxes; let i=index" formControlName="d{{i}}"
              [(ngModel)]="item.checked" (change)="onCheckboxChange($event, item, i)" [checked]="item.checked">{{item.name}}</mat-checkbox>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div>
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-progress-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center"
        *ngIf="isLoadingResults">
      </mat-progress-spinner>
    </div>
  </div>

  <section class='inm-actions--confirmation'>
    <button class="inm-button--action button-spacing" [disabled]="saveDisabled || isLoadingResults"  (click)="saveDefinition()">{{mode==='edit'? 'save' : 'add'}}</button>
    <button class="inm-button button-spacing" [disabled]="isLoadingResults" [mat-dialog-close]="true">Cancel</button>
  </section>
</div>
