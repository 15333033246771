import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { ContractDetailResponse } from "../models/contract-detail-response";
import { ContractDetail } from "../models/ContractDetail";
import { ContractDetailsCreate } from "../models/ContractDetailsCreate";
import { Pagination } from "../models/Pagination";
import { GlobalFilterService } from "./global-filter.service";

@Injectable({
  providedIn: 'root'
})

export class ContractDetailService {
  private readonly Timeout: number = 30000;
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private apiRoot: string;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService) {
    this.apiRoot = environment.cmApiRoot;
  }

  public createContractDetails(contractId: number, contractDetail: ContractDetailsCreate) : Observable<ContractDetail> {
    return this.http.post<ContractDetail>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${contractId}/contractdetails`, contractDetail);
  }

  public createContractDetailsBulk(contractId: number, contractDetail: ContractDetailsCreate) : Observable<ContractDetail> {
    return this.http.post<ContractDetail>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${contractId}/contractdetails?isBatch=true`, contractDetail);
  }

  public getContractDetailsForContractId(pagination: Pagination, contractId: number): Observable<ContractDetailResponse> {
    const params = pagination.buildSearchParams();

    return this.http.get<ContractDetailResponse>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${contractId}/contractdetails`, { params });
  }

  public updateContractDetails(contractId: number, contractDetail: ContractDetail) : Observable<ContractDetail> {
    return this.http.put<ContractDetail>(`${this.apiRoot}/api/clients/${this.globalFilterService.selectedClientId}/contracts/${contractId}/contractdetails/${contractDetail.contractDetailId}`, contractDetail);
  }
}
