<mat-toolbar>

  <div class="ui-toolbar-group-left" *ngFor="let item of toolbarItemList;let i = index">
    <mat-toolbar-column>
      <button style="margin-right: 10px;" class="inm-button" *ngIf="item.show" [disabled]="item.disabled" type="button"
        matTooltip="{{item.tip}}" (click)="toolbarBtnClick(item.name, i)">
        <svg class="inm-icon" aria-label="item.tip" *ngIf="item.icon">
          <use [attr.xlink:href]="item.icon">{{item.label}}</use>
        </svg>
        <div *ngIf="!item.icon">
          {{item.label}}
        </div>
      </button>
    </mat-toolbar-column>
  </div>


</mat-toolbar>