import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Feature } from '../../models/Feature';
import { Section } from '../../models/Section';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  public sectionsObj: Section;
  private lastModule: string;
  sectionsmenu: any[];
  @Input() parent: string;
  @Input() sections: any[];
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    switch (this.parent) {
      case 'cm':
        this.sectionsmenu = [
          {
            name: 'Contracts',
            path: 'contracts',
            feature: ['ContractManagement::ViewContract', 'ContractManagement::AddEditContract'],
            url: '/contracts',
            subSection: null,
          },
          {
            name: 'Template Library',
            path: 'templatelibrary',
            feature: ['ContractManagement::ViewTemplate', 'ContractManagement::AddEditTemplate'],
            url: '/templatelibrary',
            subSection: null,
          }
        ];
        break;
      default:
        this.sectionsmenu = [
          {
            name: 'Contract Search',
            path: 'contracts',
            feature: ['ContractManagement::ViewContract', 'ContractManagement::AddEditContract'],
            url: '/contracts',
            subSection: null,
          },
          {
            name: 'Template Library',
            path: 'templatelibrary',
            feature: ['ContractManagement::ViewTemplate', 'ContractManagement::AddEditTemplate'],
            url: '/templatelibrary',
            subSection: null,
          }
        ];
    }
  }
  checkValidLink(features: []): boolean {
    if (!features) {
      return false;
    }
    return features.some((f) => this.authorizationService.hasFeature(f));
  }

  // this function dynamically checks for access to and redirects
  // the user to the first router linking within the specified
  // parent.
  redirectToFirstLink(): void {
    let run = true;

    if (this.parent !== this.lastModule && this.sectionsmenu.length > 0) {
      this.sectionsmenu.forEach((item: any) => {
        if (run && item.feature.some((f) => this.authorizationService.hasFeature(f))) {
          run = false;
          this.lastModule = this.parent;
          this.router.navigate([`/${this.parent}/${item.path}`]);
        }
      });
    }
  }
}
