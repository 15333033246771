import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ContractTemplateDetailResponse } from '../models/contract-template-detail-response';
import { IndustryContractTemplateDetail } from '../models/IndustryContractTemplateDetail';
import { Pagination } from '../models/Pagination';

@Injectable({
  providedIn: 'root'
})
export class TemplateDetailsService {

  private readonly Timeout: number = 30000;
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  apiRoot: string;

  sort = 'IndustryContractTemplateDetailId';
  sortOrder = 'asc';
  offset = 0;
  limit = 30;
  pageIndex = 0;

  constructor(private http: HttpClient) {
    this.apiRoot = environment.cmApiRoot;
  }

  public createTemplateDetails(templateId: number, templateDetails: IndustryContractTemplateDetail[]) : Observable<IndustryContractTemplateDetail[]> {
    return this.http.post<IndustryContractTemplateDetail[]>(`${this.apiRoot}/api/IndustryContractTemplateDetails/create?industryContractTemplateId=${templateId}`, templateDetails);
  }

  public createTemplateDetailsBulk(templateId: number, templateDetails: IndustryContractTemplateDetail[]) : Observable<IndustryContractTemplateDetail[]> {
    return this.http.post<IndustryContractTemplateDetail[]>(`${this.apiRoot}/api/IndustryContractTemplateDetails/create?isBatch=true&industryContractTemplateId=${templateId}`, templateDetails);
  }

  public getTemplateDetails(pagination: Pagination, templateId: number) : Observable<ContractTemplateDetailResponse> {
    const params = pagination.buildSearchParams();
    return this.http.get<ContractTemplateDetailResponse>(`${this.apiRoot}/api/IndustryContractTemplateDetails/${templateId}/List`, {params});
  }

  public updateTemplateDetails(templateDetailId: number, templateDetails: IndustryContractTemplateDetail) : Observable<IndustryContractTemplateDetail> {
    return this.http.put<IndustryContractTemplateDetail>(`${this.apiRoot}/api/IndustryContractTemplateDetails/update?industryContractTemplateId=${templateDetailId}`, templateDetails);
  }
}
