<h3 mat-dialog-title class="inm-headline">{{data.title}}</h3>
  <div mat-dialog-content>
    <div class="pdfs-container" style="letter-spacing: normal;">
      <table mat-table [dataSource]="data.contractPDFs" class="mat-elevation-z10" matSort multitemplatedatarows>
        <ng-container matColumnDef="contractFileName">
          <th mat-header-cell *matHeaderCellDef> Contract File Name </th>
          <td mat-cell *matCellDef="let element"
            title="{{element.contractFileName}}"> {{element.contractFileName}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="float-child" style="width: 100%;">
      <section class='inm-actions--confirmation'>
        <button dialog-close mat-dialog-close class="inm-button button-spacing">Close</button>
      </section>
    </div>
  </div>
