import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-template-summary',
  templateUrl: './template-summary.component.html',
  styleUrls: ['./template-summary.component.scss'],
})
export class TemplateSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private route: ActivatedRoute
  ) {}
  public loadingStatus: boolean;
  public pageSize = 10;
  private routeSub: any;
  routeActivated = false;
  healthcareTopBarLoaded: Subscription;
  scroll: any;

  ngAfterViewInit(): void {
    const self = this;
    if (this.authorizationService.hasTopBarLoaded === true) {
      if (!this.authorizationService.hasFeature('ContractManagement::AllUsers')) {
        this.router.navigate(['/no-access']);
      }
    } else {
      this.healthcareTopBarLoaded =
        this.authorizationService.healthcareLoadedSubject$.subscribe(() => {
          if (!this.authorizationService.hasFeature('ContractManagement::AllUsers')) {
            this.router.navigate(['/no-access']);
          }
          self.healthcareTopBarLoaded.unsubscribe();
        });
    }
  }

  ngOnInit(): void {
    this.scroll = window.pageYOffset;
    const self = this;
  }
  // clean up to prevent memory leaks, by unsubscribing to subscribed events
  ngOnDestroy(): void {}
  // check if user has edit feature
  initialPageSetup(): void {}

  routeChanged(): void {
    this.routeActivated = true;
    if (this.authorizationService.HealthcareTopBar) {
      this.authorizationService.HealthcareTopBar.notifyActivity();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event): void {
    const prevScrollpos = window.pageYOffset;
    const currentScrollPos = window.pageYOffset;
    if (prevScrollpos <= 71) {
      document.getElementById('navbar').style.top = 71 - prevScrollpos + 'px';
    } else {
      document.getElementById('navbar').style.top = '0px';
    }
  }
}
