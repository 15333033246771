<div style="display: flex;">
  <div flex="1" style="padding-right: 20px; width: 33%;">
    <fieldset style="border:#03878c 2px solid; min-height: 1055px;">
      <legend style="color:#03878c ; margin-left: 10px; font-size: medium;font-weight:500;">&nbsp;GPI &nbsp;</legend>
      <app-factorsgpi-grid [canAddEdit]="canAddEdit"></app-factorsgpi-grid>
    </fieldset>
  </div>
  <div flex="1" style="margin-right: 10px; width: 67%; min-height: 900px; padding-top: 10px;">
    <app-factor-entries (sendFactorIdToGPI)="sendFactorId($event)" [canAddEdit]="canAddEdit"></app-factor-entries>
  </div>
</div>
