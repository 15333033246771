import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreakPoint } from '@angular/flex-layout';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { map } from 'lodash';
import { SelectItem } from 'primeng/api';
import { ContractFactor } from 'src/app/models/ContractFactor';
import { ContractFactorCob } from 'src/app/models/ContractFactorCob';
import { ContractFactorDaw } from 'src/app/models/ContractFactorDaw';
import { ContractFactorRetailBanner } from 'src/app/models/ContractFactorRetailBanner';
import { ContractFactorState } from 'src/app/models/ContractFactorState';
import { ContractFactorStore } from 'src/app/models/ContractFactorStore';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ContractFactorService } from 'src/app/services/contractFactorService';
import { PayerService } from 'src/app/services/payerService';

@Component({
  selector: 'app-factor-entries',
  templateUrl: './factor-entries.component.html',
  styleUrls: ['./factor-entries.component.scss']
})
export class FactorEntriesComponent implements OnInit {
  displayedColumn: string[] = [];
  isExclude = false;
  isDisableExclude = true;
  isDisableInclude = true;
  contractId: number;
  factorId = 0;
  clientId: number;
  isLoadingResults = false;
  showError = false;
  factorsForm: FormGroup;
  factorToEdit: ContractFactor;

  isExcludedCob = false;
  isExcludedState = false;
  isExcludedRetailBanner = false;
  isExcludedStore = false;

  isExcludedOtc = false;
  isExcludedVaccine = false;
  isExcludedInjections = false;
  isExcludedUsualAndCustomary = false;
  isARZeroDollarExcluded = false;

  dawBrandData: any = {};
  dawGenericData: any = {};

  isBannerSelected = true;
  hasBannerValues = true;

  storeDisplay: string = "label";
  storeKey: string = "value";

  isSmallerScreenSize = false;

  _canAddEdit: boolean = true;

  public readonly viewFeatures = [
    { feature: 'ContractManagement::AddEditContract', name: 'edit', accessable: false },
    { feature: 'ContractManagement::AddEditContract', name: 'add', accessable: false }
  ];

  @Output()sendFactorIdToGPI: EventEmitter<any> = new EventEmitter<any>();
  @Input() set canAddEdit(value: boolean) {
    this._canAddEdit = value ?? true;
    if (this.factorsForm == null || this.factorsForm == undefined) {
      return;
    }
    this._canAddEdit === true ? this.factorsForm.enable() :  this.factorsForm.disable();
    this.enableDropLists();
  }
  get canAddEdit(): boolean {
    return this._canAddEdit;
  }

  constructor(private authorizationService: AuthorizationService,
    private contractFactorService: ContractFactorService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private responsive: BreakpointObserver,
    private payerSearchService: PayerService) {

  }

  ngOnInit() {
    this.contractId = +this.route.snapshot.paramMap.get('id');
    this.checkFeatures();

    this.displayedColumn = ['factorGpiId', 'gpiType', 'gpi'];
    this.factorsForm = this.fb.group({
      contractIdCtrl: [''],
      factorIdCtrl: [''],
      isExcludedCobCtrl: [{value: '', disabled: !this.canAddEdit}],
      isExcludedStateCtrl: [{value: '', disabled: !this.canAddEdit}],
      isExcludedStoreCtrl: [{value: '', disabled: !this.canAddEdit}],
      isExcludedRetailBannerCtrl: [{value: '', disabled: !this.canAddEdit}],
      isExcludedDawBrandCtrl: [{value: '', disabled: !this.canAddEdit}],
      isExcludedDawGenericCtrl: [{value: '', disabled: !this.canAddEdit}],

      isExcludedOtcCtrl: [{value: '', disabled: !this.canAddEdit}],
      isExcludedInjectionsCtrl: [{value: '', disabled: !this.canAddEdit}],
      isExcludedUsualAndCustomaryCtrl: [{value: '', disabled: !this.canAddEdit}],
      isExcludedVaccineCtrl: [{value: '', disabled: !this.canAddEdit}],
      isARZeroDollarExcludedCtrl: [{value: '', disabled: !this.canAddEdit}],
      isRetailBannerOrStoreCtrl: [{value: '', disabled: !this.canAddEdit}],

      factorCobsCtrl: [''],
      factorStatesCtrl: [''],
      factorStoresCtrl: [''],
      factorRetailBannersCtrl: [''],
      factorStoreSearchCtrl: [''],

      factorOtcsCtrl: [''],
      factorVaccinesCtrl: [''],
      factorInjectionsCtrl: [''],
      factorUsualAndCustomarysCtrl: [''],
      factorHasARZeroDollarCtrl: [{value: '', disabled: !this.canAddEdit}],

      factorBrandDawsCtrl: [{value: '', disabled: !this.canAddEdit}],
      factorGenericDawsCtrl: [{value: '', disabled: !this.canAddEdit}]
    });

    this.loadCobList();
    this.loadStateList();
    this.loadRetailBannerList();
    this.loadAllDawsList();
    this.loadOtcList();
    this.loadFactorUsualAndCustomaryList();
    this.loadFactorInjectionList();
    this.loadFactorVaccineList();
    this.loadContractFactors();

    this.responsive.observe('(min-width: 960px) and (max-width: 1470px)')
      .subscribe(result => {

        if (result.matches) {
          this.isSmallerScreenSize = true;
        } else {
          this.isSmallerScreenSize = false;
        }

    });
  }

  private enableDropLists(): void {
    this.factorsForm.controls.factorCobsCtrl.enable();
    this.factorsForm.controls.factorUsualAndCustomarysCtrl.enable();
    this.factorsForm.controls.factorStatesCtrl.enable();
    this.factorsForm.controls.factorVaccinesCtrl.enable();
    this.factorsForm.controls.factorRetailBannersCtrl.enable();
    this.factorsForm.controls.factorStoresCtrl.enable();
    this.factorsForm.controls.factorInjectionsCtrl.enable();
    this.factorsForm.controls.factorOtcsCtrl.enable();
  }

  private checkFeatures(): void {
    for (const vwFeature of this.viewFeatures) {
      if (this.authorizationService.hasFeature(vwFeature.feature)) {
        vwFeature.accessable = true;
      }
    }
  }

  loadContractFactors(): void {
     this.isLoadingResults = true;
     this.contractFactorService.getContractFactors(
      this.contractId).subscribe(
        (results) => {
          this.isLoadingResults = false;
          this.factorsForm.controls.contractIdCtrl.setValue(results.contractId);
          this.factorsForm.controls.factorIdCtrl.setValue(results.factorId);
          this.factorsForm.controls.isExcludedCobCtrl.setValue(results.isExcludedCOB ? results.isExcludedCOB : false);
          this.factorsForm.controls.isExcludedStateCtrl.setValue(results.isExcludedState ? results.isExcludedState: false);
          this.factorsForm.controls.isExcludedStoreCtrl.setValue(results.isExcludedStore ? results.isExcludedStore: false);
          this.factorsForm.controls.isExcludedRetailBannerCtrl.setValue(results.isExcludedRetailBanner ? results.isExcludedRetailBanner: false);

          this.factorsForm.controls.isExcludedOtcCtrl.setValue(results.isExcludedOTC ? results.isExcludedOTC: false);
          this.factorsForm.controls.isExcludedInjectionsCtrl.setValue(results.isExcludedInjections ? results.isExcludedInjections: false);
          this.factorsForm.controls.isExcludedUsualAndCustomaryCtrl.setValue(results.isExcludedUsualAndCustomary ? results.isExcludedUsualAndCustomary: false);
          this.factorsForm.controls.isExcludedVaccineCtrl.setValue(results.isExcludedVaccine ? results.isExcludedVaccine: false);
          this.factorsForm.controls.isARZeroDollarExcludedCtrl.setValue(results.isARZeroDollarExcluded ? results.isARZeroDollarExcluded: false);

          this.factorsForm.controls.isExcludedDawBrandCtrl.setValue(results.isExcludedDawBrand ? results.isExcludedDawBrand : false);
          this.factorsForm.controls.isExcludedDawGenericCtrl.setValue(results.isExcludedDawGeneric ? results.isExcludedDawGeneric : false);

          this.cobValuesList = this.transformCobValues(results.factorCOBs);
          this.factorsForm.controls.factorCobsCtrl.setValue(results.factorCOBs);
          this.factorsForm.controls.factorStatesCtrl.setValue(results.factorStates);


          this.storeDestinationList = Array.from(this.transformStoreDestinationValue(results.factorStores));
          this.storeList = Array.from(this.storeDestinationList);
          this.storeDestinationEditList = Array.from(this.transformStoreDestinationValue(results.factorStores));
          this.factorsForm.controls.factorStoresCtrl.setValue(this.storeDestinationList);

          this.factorsForm.controls.factorRetailBannersCtrl.setValue(results.factorRetailBanners);
          this.factorsForm.controls.factorStoreSearchCtrl.setValue('');

          this.factorsForm.controls.factorOtcsCtrl.setValue(results.factorOTCs);
          this.factorsForm.controls.factorVaccinesCtrl.setValue(results.factorVaccines);
          this.factorsForm.controls.factorInjectionsCtrl.setValue(results.factorInjections);
          this.factorsForm.controls.factorUsualAndCustomarysCtrl.setValue(results.factorUsualAndCustomarys);
          this.factorsForm.controls.factorHasARZeroDollarCtrl.setValue(results.hasARZeroDollar ? results.hasARZeroDollar : false);

          this.transformedBrandDaws = this.transformFactorDawsValues(results.factorBrandDAWs)
          this.transformedBrandDawsEditList = Array.from(this.transformedBrandDaws);
          this.transformedGenericDaws = this.transformFactorDawsValues(results.factorGenericDAWs)
          this.transformedGenericDawsEditList = Array.from(this.transformedGenericDaws);
          this.factorsForm.controls.factorBrandDawsCtrl.setValue(this.transformedBrandDaws);
          this.factorsForm.controls.factorGenericDawsCtrl.setValue(this.transformedGenericDaws);

          // set up multiple checkbox control for daws
          this.dawBrandData = {
            "fullCheckboxItemList": this.allDawsList,
            "selectedCheckboxItemList": this.transformedBrandDaws,
            "maxLabelLength": 51
          }

          this.dawGenericData = {
            "fullCheckboxItemList": this.allDawsList,
            "selectedCheckboxItemList": this.transformedGenericDaws,
            "maxLabelLength": 51
          }

          // if no stores, then assume the user has selected banners or set banners as default
          if (results.factorRetailBanners && results.factorRetailBanners.length > 0) {
            this.factorsForm.controls.isRetailBannerOrStoreCtrl.setValue(true);
            this.isBannerSelected = true;
            this.hasBannerValues = true;
          } else {
            if ((!results.factorStores || results.factorStores.length < 1) &&  !this.factorsForm.controls.isExcludedStoreCtrl.value) {
              this.factorsForm.controls.isRetailBannerOrStoreCtrl.setValue(true);
              this.isBannerSelected = true;
              this.hasBannerValues = false;
            } else {
              this.factorsForm.controls.isRetailBannerOrStoreCtrl.setValue(false);
              this.isBannerSelected = false;
              this.hasBannerValues = false;
            }
        }

          this.isExcludedCob = results.isExcludedCOB ? results.isExcludedCOB : false;
          this.isExcludedState = results.isExcludedState ? results.isExcludedState: false;
          this.isExcludedRetailBanner = results.isExcludedRetailBanner ? results.isExcludedRetailBanner: false;
          this.isExcludedStore = results.isExcludedStore ? results.isExcludedStore: false;

          this.isExcludedOtc = results.isExcludedOTC ? results.isExcludedOTC : false;
          this.isExcludedVaccine = results.isExcludedVaccine ? results.isExcludedVaccine : false;
          this.isExcludedInjections = results.isExcludedInjections ? results.isExcludedInjections : false;
          this.isExcludedUsualAndCustomary = results.isExcludedUsualAndCustomary ? results.isExcludedUsualAndCustomary : false;
          this.isARZeroDollarExcluded = results.isARZeroDollarExcluded ? results.isARZeroDollarExcluded : false;

          this.sendFactorIdToGPI.emit(results.factorId);
          this.factorToEdit = results;
        },
        (err) => {
          this.isLoadingResults = false;
          toastr.error('We are unable to load Contract Factors at this time');
        }
      );
  }

  onReset() {
    this.factorsForm.controls.contractIdCtrl.setValue(this.factorToEdit.contractId);
    this.factorsForm.controls.factorIdCtrl.setValue(this.factorToEdit.factorId);
    this.factorsForm.controls.isExcludedCobCtrl.setValue(this.factorToEdit.isExcludedCOB ? this.factorToEdit.isExcludedCOB : false);
    this.factorsForm.controls.isExcludedStateCtrl.setValue(this.factorToEdit.isExcludedState ? this.factorToEdit.isExcludedState: false);
    this.factorsForm.controls.isExcludedStoreCtrl.setValue(this.factorToEdit.isExcludedStore ? this.factorToEdit.isExcludedStore: false);
    this.factorsForm.controls.isExcludedRetailBannerCtrl.setValue(this.factorToEdit.isExcludedRetailBanner ? this.factorToEdit.isExcludedRetailBanner: false);

    this.factorsForm.controls.isExcludedOtcCtrl.setValue(this.factorToEdit.isExcludedOTC ? this.factorToEdit.isExcludedOTC: false);
    this.factorsForm.controls.isExcludedInjectionsCtrl.setValue(this.factorToEdit.isExcludedInjections ? this.factorToEdit.isExcludedInjections: false);
    this.factorsForm.controls.isExcludedUsualAndCustomaryCtrl.setValue(this.factorToEdit.isExcludedUsualAndCustomary ? this.factorToEdit.isExcludedUsualAndCustomary: false);
    this.factorsForm.controls.isExcludedVaccineCtrl.setValue(this.factorToEdit.isExcludedVaccine ? this.factorToEdit.isExcludedVaccine: false);
    this.factorsForm.controls.isARZeroDollarExcludedCtrl.setValue(this.factorToEdit.isARZeroDollarExcluded ? this.factorToEdit.isARZeroDollarExcluded: false);

    this.factorsForm.controls.isExcludedDawBrandCtrl.setValue(this.factorToEdit.isExcludedDawBrand ? this.factorToEdit.isExcludedDawBrand : false);
    this.factorsForm.controls.isExcludedDawGenericCtrl.setValue(this.factorToEdit.isExcludedDawGeneric ? this.factorToEdit.isExcludedDawGeneric: false);

    this.cobValuesList = this.transformCobValues(this.factorToEdit.factorCOBs);
    this.factorsForm.controls.factorCobsCtrl.setValue(this.factorToEdit.factorCOBs);
    this.factorsForm.controls.factorStatesCtrl.setValue(this.factorToEdit.factorStates);


    this.storeDestinationList = Array.from(this.transformStoreDestinationValue(this.factorToEdit.factorStores));
    this.storeList = Array.from(this.storeDestinationList);
    this.storeDestinationEditList = Array.from(this.transformStoreDestinationValue(this.factorToEdit.factorStores));
    this.factorsForm.controls.factorStoresCtrl.setValue(this.storeDestinationList);

    this.factorsForm.controls.factorRetailBannersCtrl.setValue(this.factorToEdit.factorRetailBanners);
    this.factorsForm.controls.factorStoreSearchCtrl.setValue('');

    this.factorsForm.controls.factorOtcsCtrl.setValue(this.factorToEdit.factorOTCs);
    this.factorsForm.controls.factorVaccinesCtrl.setValue(this.factorToEdit.factorVaccines);
    this.factorsForm.controls.factorInjectionsCtrl.setValue(this.factorToEdit.factorInjections);
    this.factorsForm.controls.factorUsualAndCustomarysCtrl.setValue(this.factorToEdit.factorUsualAndCustomarys);
    this.factorsForm.controls.factorHasARZeroDollarCtrl.setValue(this.factorToEdit.hasARZeroDollar ? this.factorToEdit.hasARZeroDollar : false);

    this.loadAllDawsList();
    this.transformedBrandDaws = this.transformFactorDawsValues(this.factorToEdit.factorBrandDAWs)
    this.transformedBrandDawsEditList = Array.from(this.transformedBrandDaws);
    this.transformedGenericDaws = this.transformFactorDawsValues(this.factorToEdit.factorGenericDAWs)
    this.transformedGenericDawsEditList = Array.from(this.transformedGenericDaws);
    this.factorsForm.controls.factorBrandDawsCtrl.setValue(this.transformedBrandDaws);
    this.factorsForm.controls.factorGenericDawsCtrl.setValue(this.transformedGenericDaws);

    // set up multiple checkbox control for daws
    this.dawBrandData = {
      "fullCheckboxItemList": this.allDawsList,
      "selectedCheckboxItemList": this.transformedBrandDaws,
      "maxLabelLength": 51
    }

    this.dawGenericData = {
      "fullCheckboxItemList": this.allDawsList,
      "selectedCheckboxItemList": this.transformedGenericDaws,
      "maxLabelLength": 51
    }

    // if no stores, then assume the user has selected banners or set banners as default
    if (this.factorToEdit.factorRetailBanners && this.factorToEdit.factorRetailBanners.length > 0) {
      this.factorsForm.controls.isRetailBannerOrStoreCtrl.setValue(true);
      this.isBannerSelected = true;
      this.hasBannerValues = true;
    } else {
      if ((!this.factorToEdit.factorStores || this.factorToEdit.factorStores.length < 1) &&  !this.factorsForm.controls.isExcludedStoreCtrl.value) {
        this.factorsForm.controls.isRetailBannerOrStoreCtrl.setValue(true);
        this.isBannerSelected = true;
        this.hasBannerValues = false;
      } else {
        this.factorsForm.controls.isRetailBannerOrStoreCtrl.setValue(false);
        this.isBannerSelected = false;
        this.hasBannerValues = false;
      }
    }

    this.isExcludedCob = this.factorToEdit.isExcludedCOB ? this.factorToEdit.isExcludedCOB : false;
    this.isExcludedState = this.factorToEdit.isExcludedState ? this.factorToEdit.isExcludedState: false;
    this.isExcludedRetailBanner = this.factorToEdit.isExcludedRetailBanner ? this.factorToEdit.isExcludedRetailBanner: false;
    this.isExcludedStore = this.factorToEdit.isExcludedStore ? this.factorToEdit.isExcludedStore: false;

    this.isExcludedOtc = this.factorToEdit.isExcludedOTC ? this.factorToEdit.isExcludedOTC : false;
    this.isExcludedVaccine = this.factorToEdit.isExcludedVaccine ? this.factorToEdit.isExcludedVaccine : false;
    this.isExcludedInjections = this.factorToEdit.isExcludedInjections ? this.factorToEdit.isExcludedInjections : false;
    this.isExcludedUsualAndCustomary = this.factorToEdit.isExcludedUsualAndCustomary ? this.factorToEdit.isExcludedUsualAndCustomary : false;
    this.isARZeroDollarExcluded = this.factorToEdit.isARZeroDollarExcluded ? this.factorToEdit.isARZeroDollarExcluded : false;

    this.factorsForm.markAsUntouched();
    this.factorsForm.markAsPristine();
    this.checkSaveEnabled();
  }

  onChangeCob(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedCobCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeState(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedStateCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeRetailBanner(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedRetailBannerCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeStore(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedStoreCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeOtc(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedOtcCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeVaccine(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedVaccineCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeInjections(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedInjectionsCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeUsualAndCustomary(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedUsualAndCustomaryCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeARZeroDollar(data: MatRadioChange) {
    this.factorsForm.controls.isARZeroDollarExcludedCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeHasZeroDollar(data: MatCheckboxChange) {
    this.factorsForm.controls.factorHasARZeroDollarCtrl.setValue(data.checked);
    this.checkSaveEnabled();
  }

  onChangeIsBannerSelected(data: MatRadioChange) {
    this.factorsForm.controls.isRetailBannerOrStoreCtrl.setValue(data.value);
    this.isBannerSelected = data.value;
  }

  onChangeBannerValues() {
    if (this.factorsForm.controls.factorRetailBannersCtrl.value.length > 0) {
      this.hasBannerValues = true;
    } else {
      this.hasBannerValues = false;
    }
  }

  onChangeFactorDawBrand(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedDawBrandCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeFactorDawGeneric(data: MatRadioChange) {
    this.factorsForm.controls.isExcludedDawGenericCtrl.setValue(data.value);
    this.checkSaveEnabled();
  }

  onChangeStoreDestinationList($event): void{
    this.storeDestinationList.sort((a, b) => a.value - b.value);
    this.storeDestinationEditList.sort((a, b) => a.value - b.value);
    const listsAreTheSame = this.objectsAreSame(this.storeDestinationList, this.storeDestinationEditList);
     if (!listsAreTheSame) {
      this.factorsForm.controls.factorStoresCtrl.markAsDirty();
     } else {
        const noOtherFormChanges = this.checkValuesChange();
        // true is disable the save button
        if (noOtherFormChanges) {
          this.factorsForm.controls.factorStoresCtrl.markAsPristine();
        }

     }
  }

  onDawBrandCheckedList(items: any[]) {
    this.transformedBrandDaws = items;
    this.transformedBrandDaws.sort((a, b) => a.value - b.value);
    this.transformedBrandDawsEditList.sort((a, b) => a.value - b.value);
    const listsAreTheSame = this.objectsAreSame(this.transformedBrandDaws, this.transformedBrandDawsEditList);
     if (!listsAreTheSame) {
      this.factorsForm.controls.factorBrandDawsCtrl.markAsDirty();
      this.checkSaveEnabled();
     } else {
        const noOtherFormChanges = this.checkValuesChange();
        // true is disable the save button
        if (noOtherFormChanges) {
          this.factorsForm.controls.factorBrandDawsCtrl.markAsPristine();
          this.checkSaveEnabled();
        }
     }
  }

  onDawGenericCheckedList(items: any[]) {
    this.transformedGenericDaws = items;
    this.transformedGenericDaws.sort((a, b) => a.value - b.value);
    this.transformedGenericDawsEditList.sort((a, b) => a.value - b.value);
    const listsAreTheSame = this.objectsAreSame(this.transformedGenericDaws, this.transformedGenericDawsEditList);
     if (!listsAreTheSame) {
      this.factorsForm.controls.factorGenericDawsCtrl.markAsDirty();
      this.checkSaveEnabled();
     } else {
        const noOtherFormChanges = this.checkValuesChange();
        // true is disable the save button
        if (noOtherFormChanges) {
          this.factorsForm.controls.factorGenericDawsCtrl.markAsPristine();
          this.checkSaveEnabled();
        }

     }
  }

  objectsAreSame(x, y) {
    return JSON.stringify(x) === JSON.stringify(y);
 }

  saveFactors() {
    // start with Factor COBs
    var isExcludedCOB = this.factorsForm.controls.isExcludedCobCtrl.value;
    var factorCOBs = this.getFactorForSubmit(this.factorToEdit.factorCOBs, 'cobCode', 'factorCobId', this.factorsForm.controls.factorCobsCtrl.value);

    // assume that we want to wipe out all factorCOBs if none are selected
    if (!factorCOBs || factorCOBs == undefined || factorCOBs.length < 1) {
      factorCOBs = [];
    }

    // Next is Factor States
    var isExcludedState = this.factorsForm.controls.isExcludedStateCtrl.value;
    var factorStates = this.getFactorForSubmit(this.factorToEdit.factorStates, 'code', 'factorStateId', this.factorsForm.controls.factorStatesCtrl.value);

    // assume we want to wipe out all states if none are selected
    if (!factorStates || factorStates == undefined || factorStates.length < 1) {
      factorStates = [];
    }

    // Next is Factor Retail Banners
    var isExcludedRetailBanner = this.factorsForm.controls.isExcludedRetailBannerCtrl.value;
    var factorRetailBanners = this.getFactorForSubmit(this.factorToEdit.factorRetailBanners, 'retailBanner', 'factorRetailBannerId', this.factorsForm.controls.factorRetailBannersCtrl.value);

    // Next is Factor Stores
    var isExcludedStore = this.factorsForm.controls.isExcludedStoreCtrl.value;
    var factorStores = this.getFactorStoresForSubmit();

    // Next is OTC
    var isExcludedOTC = this.factorsForm.controls.isExcludedOtcCtrl.value;
    var factorOTCs = this.getFactorForSubmit(this.factorToEdit.factorOTCs, 'otcId', 'factorOtcId', this.factorsForm.controls.factorOtcsCtrl.value);

    // assume that we want to wipe out all factor OTCs if none are selected
    if (!factorOTCs || factorOTCs == undefined || factorOTCs.length < 1) {
      factorOTCs = [];
    }

    // Next is Vaccines
    var isExcludedVaccine = this.factorsForm.controls.isExcludedVaccineCtrl.value;
    var factorVaccines = this.getFactorForSubmit(this.factorToEdit.factorVaccines, 'code', 'factorVaccineId', this.factorsForm.controls.factorVaccinesCtrl.value);

    // assume that we want to wipe out all factor vaccines if none are selected
    if (!factorVaccines || factorVaccines == undefined || factorVaccines.length < 1) {
      factorVaccines = [];
    }

    // Next is Injections
    var isExcludedInjections = this.factorsForm.controls.isExcludedInjectionsCtrl.value;
    var factorInjections = this.getFactorForSubmit(this.factorToEdit.factorInjections, 'code', 'factorInjectionId', this.factorsForm.controls.factorInjectionsCtrl.value);

    // assume that we want to wipe out all factor injections if none are selected
    if (!factorInjections || factorInjections == undefined || factorInjections.length < 1) {
      factorInjections = [];
    }

    // Next is UC
    var isExcludedUsualAndCustomary = this.factorsForm.controls.isExcludedUsualAndCustomaryCtrl.value;
    var factorUsualAndCustomarys = this.getFactorForSubmit(this.factorToEdit.factorUsualAndCustomarys, 'code', 'factorUsualAndCustomaryId', this.factorsForm.controls.factorUsualAndCustomarysCtrl.value);

    // assume that we want to wipe out all factor U&Cs if none are selected
    if (!factorUsualAndCustomarys || factorUsualAndCustomarys == undefined || factorUsualAndCustomarys.length < 1) {
      factorUsualAndCustomarys = [];
    }

    // Next is AR Zero
    var isARZeroDollarExcluded = this.factorsForm.controls.isARZeroDollarExcludedCtrl.value;
    var hasARZeroDollar = this.factorsForm.controls.factorHasARZeroDollarCtrl.value;

    // assume we want to wipe out all factor stores if none are selected
    if (!factorStores || factorStores == undefined || factorStores.length < 1) {
      factorStores = [];
    }

    // retail banners are the default over stores
    var isRetailBannerOrStore = this.factorsForm.controls.isRetailBannerOrStoreCtrl.value;
    if (isRetailBannerOrStore) {
      isExcludedStore = null;
      factorStores = [];
    } else {
      isExcludedRetailBanner = null;
      factorRetailBanners = [];
    }

    // if no retail banners, assume we want to wipe out all retail banners
    if (!factorRetailBanners || factorRetailBanners == undefined || factorRetailBanners.length < 1) {
      factorRetailBanners = [];
    }

    // next is DAW Brand
    var isExcludedDawBrandSubmit = this.factorsForm.controls.isExcludedDawBrandCtrl.value;
    var factorBrandDaws = this.getFactorDawsForSubmit(this.factorToEdit.factorBrandDAWs, this.transformedBrandDaws, true);

    // assume we want to wipe out all factor brand daws if none are selected
    if (!factorBrandDaws || factorBrandDaws == undefined || factorBrandDaws.length < 1) {
      factorBrandDaws = [];
    }


    // next is DAW Generic
    var isExcludedDawGenericSubmit = this.factorsForm.controls.isExcludedDawGenericCtrl.value;
    var factorGenericDaws = this.getFactorDawsForSubmit(this.factorToEdit.factorGenericDAWs, this.transformedGenericDaws);

    // assume we want to wipe out all factor generic daws if none are selected
    if (!factorGenericDaws || factorGenericDaws == undefined || factorGenericDaws.length < 1) {
      factorGenericDaws = [];
    }

    // make sure to set factor id in post
    this.factorId = this.factorsForm.controls.factorIdCtrl.value;

    const factorsRequestModel: any = {
      "factorId": this.factorId,
      "contractId": this.contractId,
      "isExcludedCOB": isExcludedCOB,
      "factorCOBs": factorCOBs,
      "isExcludedState": isExcludedState,
      "factorStates": factorStates,
      "isExcludedRetailBanner": isExcludedRetailBanner,
      "factorRetailBanners": factorRetailBanners,
      "isExcludedStore": isExcludedStore,
      "factorStores": factorStores,
      "isExcludedOTC": isExcludedOTC,
      "factorOTCs": factorOTCs,
      "isExcludedVaccine": isExcludedVaccine,
      "factorVaccines": factorVaccines,
      "isExcludedInjections": isExcludedInjections,
      "factorInjections": factorInjections,
      "isExcludedUsualAndCustomary": isExcludedUsualAndCustomary,
      "factorUsualAndCustomarys": factorUsualAndCustomarys,
      "isARZeroDollarExcluded": isARZeroDollarExcluded,
      "hasARZeroDollar": hasARZeroDollar,
      "isExcludedDawBrand": isExcludedDawBrandSubmit,
      "factorBrandDAWs": factorBrandDaws,
      "isExcludedDawGeneric": isExcludedDawGenericSubmit,
      "factorGenericDAWs": factorGenericDaws
    };

    this.isLoadingResults = true;
    console.log(JSON.stringify(factorsRequestModel));

    this.contractFactorService.upsertContractFactors(factorsRequestModel,
      this.contractId).subscribe(
        (results) => {
          this.loadContractFactors();
          this.isLoadingResults = false;
          this.saveDisabled = true;
          this.loadRetailBannerList();
          toastr.success('Contract factors modified');
        },
        (err) => {
          this.isLoadingResults = false;
          toastr.error('We are unable to update Contract Factors at this time');
        }
    );
  }

  getFactorDawsForSubmit(factorDawsToEdit: any[], factorDawsUpdated: any[], isDawBrand: boolean = false): any[] {
    var factorDaws = factorDawsToEdit ? Array.from(factorDawsToEdit) : [];

    var factorsDawsLocal = [];
    if(factorDawsUpdated.values) {
      factorsDawsLocal = Array.from(map(factorDawsUpdated, 'value'));
      if (factorsDawsLocal.every((value) => value === undefined)) {
        factorsDawsLocal = Array.from(map(factorDawsUpdated))
      }
    }

    // now clean up the daws list -- add new ones and remove ones no longer in the list
    if (factorsDawsLocal && factorsDawsLocal.length > 0) {
      // if an item in the daws list is not in factor daws, then add it
      factorsDawsLocal.forEach(function (store) {
        if (!factorDaws || factorDaws.length < 1) {
          factorDaws = [];
          factorsDawsLocal.forEach( function(value) {
            factorDaws.push({"factorDawId":0, "daw": value, "isBrand": isDawBrand});
            });
        } else {
          // previous factor daws
          factorsDawsLocal.forEach( function(value) {
            const index = factorDaws.findIndex(item => item.daw == value);
            if (index < 0) {
              factorDaws.push({"factorDawId":0, "daw": value});
            }
          });
        }
      });
    }

    var factorDawsCopy = Array.from(factorDaws);
    if (factorDaws && factorDaws.length > 0) {
      if (!factorsDawsLocal || factorsDawsLocal.length < 1) {
        // no factor daws selected
        factorDawsCopy = [];
      } else if (factorsDawsLocal && factorsDawsLocal.length > 0) {
        // remove factor daw not in local list
        if (factorDaws && factorDaws.length > 0) {
          factorDaws.forEach( function(value) {
            const index = factorsDawsLocal.findIndex(item => item == value.daw);
            if (index < 0) {
              const storeIndex = factorDawsCopy.findIndex(item => item.daw == value.daw);
              factorDawsCopy.splice(storeIndex, 1);
            }
          });
        }
      }
    }

    return factorDawsCopy;
  }

  getFactorStoresForSubmit(): ContractFactorStore[] {
    var factorStores = this.factorToEdit.factorStores ? Array.from(this.factorToEdit.factorStores) : [];

    var factorsStoresLocal = [];
    if(this.storeDestinationList.values) {
      factorsStoresLocal = Array.from(map(this.storeDestinationList, 'value'));
      if (factorsStoresLocal.every((value) => value === undefined)) {
        factorsStoresLocal = Array.from(map(this.storeDestinationList))
      }
    }

    // now clean up the stores list -- add new ones and remove ones no longer in the list
    if (factorsStoresLocal && factorsStoresLocal.length > 0) {
      // if an item in the store destination list is not in factor stores, then add it
      factorsStoresLocal.forEach(function (store) {
        if (!factorStores || factorStores.length < 1) {
          factorStores = [];
          factorsStoresLocal.forEach( function(value) {
            factorStores.push({"factorStoreId":0, "code": value});
            });
        } else {
          // previous factor retail stores
          factorsStoresLocal.forEach( function(value) {
            const index = factorStores.findIndex(item => item.code == value);
            if (index < 0) {
              factorStores.push({"factorStoreId":0, "code": value});
            }
          });
        }
      });
    }

    var factorStoresCopy = Array.from(factorStores);
    if (factorStores && factorStores.length > 0) {
      if (!factorsStoresLocal || factorsStoresLocal.length < 1) {
        // no factor stores selected
        factorStoresCopy = [];
      } else if (factorsStoresLocal && factorsStoresLocal.length > 0) {
        // remove factor store not in local list
        if (factorStores && factorStores.length > 0) {
          factorStores.forEach( function(value) {
            const index = factorsStoresLocal.findIndex(item => item == value.code);
            if (index < 0) {
              const storeIndex = factorStoresCopy.findIndex(item => item.code == value.code);
              factorStoresCopy.splice(storeIndex, 1);
            }
          });
        }
      }
    }

    return factorStoresCopy;
  }

  getFactorForSubmit(editFactors: any[], valueCode: string, factorIdentifier: string, controlFactors: any[]): any[] {
    var factorsForSubmit = editFactors ? Array.from(editFactors) : [];

    var factorsLocal = [];
    if(controlFactors) {
      factorsLocal = map(controlFactors, valueCode);
      if (factorsLocal.every((value) => value === undefined)) {
        factorsLocal = map(controlFactors);
      }
    }

    if (factorsLocal && factorsLocal.length > 0) {
      // no previous factor
      if (!factorsForSubmit || factorsForSubmit.length < 1) {
        factorsForSubmit = [];
        factorsLocal.forEach(function(value) {
          factorsForSubmit.push({[factorIdentifier]:0, [valueCode]: value});
        });
      } else if (factorsForSubmit && factorsForSubmit.length > 0) {
        factorsLocal.forEach( function(value) {
          const index = factorsForSubmit.findIndex(item => {
          return item[valueCode] == value;
          });
          if (index < 0) {
            factorsForSubmit.push({[factorIdentifier]:0, [valueCode]: value});
          }
        });
      }
    }

    var factorsForSubmitCopy = Array.from(factorsForSubmit);
    if (factorsForSubmit && factorsForSubmit.length > 0) {
      if (!factorsLocal || factorsLocal.length < 1) {
        factorsForSubmitCopy = [];
      } else if (factorsLocal && factorsLocal.length > 0) {
        if (factorsForSubmit && factorsForSubmit.length > 0) {
          factorsForSubmit.forEach( function(value) {
            const index = factorsLocal.findIndex(item => {
              return item == value[valueCode]
            });
            if (index < 0) {
              const factorIndex = factorsForSubmitCopy.findIndex(item => {
                return item[valueCode] == value[valueCode]
              });
              factorsForSubmitCopy.splice(factorIndex, 1);
            }
          });
        }
      }
    }

    return factorsForSubmitCopy;
  }

  factorCobList: SelectItem[];
  loadCobList(): void {
    this.factorCobList = [{label: '0', value: '0'}, {label: '1', value: '1'}];
      // , {label: '2', value: '2'}, {label: '3', value: '3'}, {label: '4', value: '4'}];
  }

  factorOtcList: SelectItem[];
  loadOtcList(): void {
     this.factorOtcList = [{label: 'O (OTC-Single Source-N)', value: 1}, {label: 'P (OTC-Multiple Source-O,M, or Y)', value: 2},
     {label: 'R (Rx-Single Source-N)', value: 3}, {label: 'S (Rx-Multiple Source-O,M, or Y)', value: 4},
    ];
  }

  factorUsualAndCustomaryList: SelectItem[];
  loadFactorUsualAndCustomaryList(): void {
     this.factorUsualAndCustomaryList = [{label: 'At', value: 'At'}, {label: 'Above', value: 'Above'}, {label: 'Below', value: 'Below'}];
  }

  factorInjectionList: SelectItem[];
  loadFactorInjectionList(): void {
    //IM, IV and IJ
    this.factorInjectionList = [{label: 'IM', value: 'IM'}, {label: 'IV', value: 'IV'}, {label: 'IJ', value: 'IJ'}];
  }

  factorVaccineList: SelectItem[];
  loadFactorVaccineList(): void {
    this.factorVaccineList = [{label: '17', value: '17'}, {label: '18', value: '18'}];
  }

  factorStateList: SelectItem[];
  loadStateList(): void {
    this.factorStateList = [{label: 'AL', value: 'AL'},{label: 'AK', value: 'AK'},{label: 'AZ', value: 'AZ'},{label: 'AR', value: 'AR'},{label: 'CA', value: 'CA'},
    {label: 'CO', value: 'CO'},{label: 'CT', value: 'CT'},{label: 'DC', value: 'DC'},{label: 'DE', value: 'DE'},{label: 'FL', value: 'FL'},{label: 'GA', value: 'GA'},
    {label: 'HI', value: 'HI'},{label: 'ID', value: 'ID'},{label: 'IL', value: 'IL'},{label: 'IN', value: 'IN'},{label: 'IA', value: 'IA'},
    {label: 'KS', value: 'KS'},{label: 'KY', value: 'KY'},{label: 'LA', value: 'LA'},{label: 'ME', value: 'ME'},{label: 'MD', value: 'MD'},
    {label: 'MA', value: 'MA'},{label: 'MI', value: 'MI'},{label: 'MN', value: 'MN'},{label: 'MS', value: 'MS'},{label: 'MO', value: 'MO'},
    {label: 'MT', value: 'MT'},{label: 'NE', value: 'NE'},{label: 'NV', value: 'NV'},{label: 'NH', value: 'NH'},{label: 'NJ', value: 'NJ'},
    {label: 'NM', value: 'NM'},{label: 'NY', value: 'NY'},{label: 'NC', value: 'NC'},{label: 'ND', value: 'ND'},{label: 'OH', value: 'OH'},
    {label: 'OK', value: 'OK'},{label: 'OR', value: 'OR'},{label: 'PA', value: 'PA'},{label: 'RI', value: 'RI'},{label: 'SC', value: 'SC'},
    {label: 'SD', value: 'SD'},{label: 'TN', value: 'TN'},{label: 'TX', value: 'TX'},{label: 'UT', value: 'UT'},{label: 'VT', value: 'VT'},
    {label: 'VA', value: 'VA'},{label: 'WA', value: 'WA'},{label: 'WV', value: 'WV'},{label: 'WI', value: 'WI'},{label: 'WY', value: 'WY'}];
  }

  retailBannerList: SelectItem[];
  loadRetailBannerList(): void {
    this.isLoadingResults = true;
    this.payerSearchService.getRetailBannerList().subscribe(
      (results) => {
        if (results.length < 1) {
          this.retailBannerList = [];
        } else {
          this.retailBannerList = this.transformValue(results);
        }
        this.isLoadingResults = false;
      },
      (err) => {
        this.isLoadingResults = false;
        toastr.error('We are unable to load Retail Banners at this time');
      }
    );
  }

  storeList: Array<any>;
  loadStoreList(): void {

    var searchCriteria = this.factorsForm.controls.factorStoreSearchCtrl.value;
    if (!searchCriteria || searchCriteria.trim().length < 3)  {
      return;
    }
    this.isLoadingResults = true;
    this.payerSearchService.getStoreList(searchCriteria).subscribe(
      (results) => {
        this.storeList = this.transformStoreValue(results);
        this.isLoadingResults = false;
      },
      (err) => {
        this.isLoadingResults = false;
        toastr.error('We are unable to load Stores at this time');
      }
    );
  }

  allDawsList: SelectItem[] = [];
  loadAllDawsList(): void {
    this.allDawsList = [{'label': '0 - No product selection indicated', 'value': 0}, {'label': '1 - Substitution not allowed by prescriber', 'value': 1},
    {'label': '2 - Substitution allowed - patient requested product dispensed', 'value': 2}, {'label': '3 - Substitution allowed - pharmacist selected product dispensed', 'value': 3},
    {'label': '4 - Substitution allowed - generic drug not in stock', 'value': 4}, {'label': '5 - Substitution allowed - brand drug dispensed as generic', 'value': 5},
    {'label': '6 - Override', 'value': 6}, {'label': '7 - Substitution not allowed - brand drug mandated by law', 'value': 7},
    {'label': '8 - Substitution not allowed - generic drug not available in marketplace', 'value': 8}, {'label': '9 - Substitution allowed by prescriber but plan requests brand', 'value': 9}
    ];
  }

  arrayObjects: SelectItem[] = [];
  transformValue(response): SelectItem[] {
    this.arrayObjects = [];
    if (!response) {
      return this.arrayObjects;
    }
    response.forEach(data => {
      if (data && data.length > 0) {
        this.arrayObjects.push({
          label: data,
          value: data
        })
      }
    })
    return this.arrayObjects;
  }

  arrayCobObjects: SelectItem[] = [];
  cobValuesList: SelectItem[] = [];
  transformCobValues(response): SelectItem[] {
    this.arrayCobObjects = [];
    if (!response) {
      return this.arrayCobObjects;
    }
    response.forEach(data => {
      this.arrayObjects.push({
        label: data.CobCode,
        value: data.CobCode
      })
    })
    return this.arrayObjects;
  }

  storeDestinationList: Array<any>;
  storeDestinationArrayObjects: SelectItem[] = [];
  transformStoreDestinationValue(response) {
    this.storeDestinationArrayObjects = [];
    if (!response) {
      return this.storeDestinationArrayObjects;
    }
    response.forEach(data => {
      this.storeDestinationArrayObjects.push({
        label: data.code,
        value: data.code
      });
    });
    return this.storeDestinationArrayObjects;
  }

  storeDestinationEditList: Array<any>;
  storeDestinationEditArrayObjects: SelectItem[] = [];
  transformStoreDestinationEditValue(response) {
    this.storeDestinationEditArrayObjects = [];
    if (!response) {
      return this.storeDestinationEditArrayObjects;
    }
    response.forEach(data => {
      this.storeDestinationEditArrayObjects.push({
        label: data.code,
        value: data.code
      });
    });
    return this.storeDestinationEditArrayObjects;
  }

  storeListArrayObjects: SelectItem[] = [];
  transformStoreValue(response): SelectItem[] {
    this.storeListArrayObjects = [];
    if (!response) {
      return this.storeListArrayObjects;
    }
    response.Items.forEach(data => {
      this.storeListArrayObjects.push({
        label: data.NcpdpProviderId.trim(),
        value: data.NcpdpProviderId.trim()
      })
    })
    this.storeDestinationList.forEach(data => {
      this.storeListArrayObjects.push({
        label: data.label,
        value: data.value
      })
    });
    return this.storeListArrayObjects;
  }

  transformStoreListValueFromDetination() {
    this.storeListArrayObjects = [];
    if (!this.storeDestinationList) {
      return this.storeListArrayObjects;
    }

    this.storeDestinationList.forEach(data => {
      this.storeListArrayObjects.push({
        label: data.label,
        value: data.value
      })
    });
    return this.storeListArrayObjects;
  }

  factorDawArrayObject: SelectItem[] = [];
  transformedBrandDaws: SelectItem[] = [];
  transformedBrandDawsEditList: SelectItem[] = [];
  transformedGenericDaws: SelectItem[] = [];
  transformedGenericDawsEditList: SelectItem[] = []
  transformFactorDawsValues(response): SelectItem[] {
    this.factorDawArrayObject = [];
    if (!response) {
      return this.factorDawArrayObject;
    }
    response.forEach(data => {
      this.factorDawArrayObject.push({
        label: data.daw,
        value: data.daw
      })
    })
    return this.factorDawArrayObject;
  }

  compareCobObjectValues(o1: any, o2: any): boolean {
    return o1 === o2.cobCode;
  }

  compareStateObjectValues(o1: any, o2: any): boolean {
    return o1 == o2.code;
  }

  compareRetailBannerObjectValues(o1: any, o2: any): boolean {
    return o1 == o2.retailBanner;
  }

  compareOtcObjectValues(o1: any, o2: any): boolean {
    return o1 === o2.otcId;
  }

  compareInjectionObjectValues(o1: any, o2: any): boolean {
    return o1 === o2.code;
  }

  compareUsualAndCustomaryObjectValues(o1: any, o2: any): boolean {
    return o1 === o2.code
  }

  compareVaccineObjectValues(o1: any, o2: any): boolean {
    return o1 === o2.code
  }

  saveDisabled:boolean = true;
  checkSaveEnabled(): void{
    this.saveDisabled =
      this.factorsForm.pristine ||
      this.factorsForm.invalid ||
      this.checkValuesChange();
  }


  checkValuesChange(): boolean {
      const isExcludeCobLocal = this.factorsForm.controls.isExcludedCobCtrl.value;
      const isExcludeStateLocal = this.factorsForm.controls.isExcludedStateCtrl.value;
      const isExcludeStoreLocal = this.factorsForm.controls.isExcludedStoreCtrl.value;
      const isExcludeRetailBannerLocal = this.factorsForm.controls.isExcludedRetailBannerCtrl.value;

      const isExcludeOtcLocal = this.factorsForm.controls.isExcludedOtcCtrl.value;
      const isExcludeVaccineLocal = this.factorsForm.controls.isExcludedVaccineCtrl.value;
      const isExcludeInjectionsLocal = this.factorsForm.controls.isExcludedInjectionsCtrl.value;
      const isExcludeUsualAndCustomaryLocal = this.factorsForm.controls.isExcludedUsualAndCustomaryCtrl.value;
      const isARZeroDollarExcludeLocal = this.factorsForm.controls.isARZeroDollarExcludedCtrl.value;

      const isExcludedDawBrandLocal = this.factorsForm.controls.isExcludedDawBrandCtrl.value;
      const isExcludedDawGenericLocal = this.factorsForm.controls.isExcludedDawGenericCtrl.value;

      const hasZeroDollar = this.factorsForm.controls.factorHasARZeroDollarCtrl.value;

      var factorsCobsLocal = [];
      if(this.factorsForm.controls.factorCobsCtrl.value) {
        factorsCobsLocal =  map(this.factorsForm.controls.factorCobsCtrl.value, 'cobCode');
        if (factorsCobsLocal.every((value) => value === undefined)) {
          factorsCobsLocal = map(this.factorsForm.controls.factorCobsCtrl.value);
        }
      }

      var factorsStatesLocal = [];
      if(this.factorsForm.controls.factorStatesCtrl.value) {
        factorsStatesLocal = map(this.factorsForm.controls.factorStatesCtrl.value, 'code');
        if (factorsStatesLocal.every((value) => value === undefined)) {
          factorsStatesLocal = map(this.factorsForm.controls.factorStatesCtrl.value)
        }
      }

      var factorsStoresLocal = [];
      if(this.storeDestinationList.values) {
        factorsStoresLocal = map(this.storeDestinationList, 'value');
        if (factorsStoresLocal.every((value) => value === undefined)) {
          factorsStoresLocal = map(this.storeDestinationList)
        }
      }

      var factorsRetailBannersLocal = [];
      if(this.factorsForm.controls.factorRetailBannersCtrl.value) {
        factorsRetailBannersLocal = map(this.factorsForm.controls.factorRetailBannersCtrl.value, 'retailBanner');
        if (factorsRetailBannersLocal.every((value) => value === undefined)) {
          factorsRetailBannersLocal = map(this.factorsForm.controls.factorRetailBannersCtrl.value)
        }
      }

      var factorsOtcsLocal = [];
      if(this.factorsForm.controls.factorOtcsCtrl.value) {
        factorsOtcsLocal =  map(this.factorsForm.controls.factorOtcsCtrl.value, 'otcId');
        if (factorsOtcsLocal.every((value) => value === undefined)) {
          factorsOtcsLocal = map(this.factorsForm.controls.factorOtcsCtrl.value);
        }
      }

      var factorInjectionsLocal = [];
      if(this.factorsForm.controls.factorInjectionsCtrl.value) {
        factorInjectionsLocal =  map(this.factorsForm.controls.factorInjectionsCtrl.value, 'code');
        if (factorInjectionsLocal.every((value) => value === undefined)) {
          factorInjectionsLocal = map(this.factorsForm.controls.factorInjectionsCtrl.value);
        }
      }

      var factorVaccinesLocal = [];
      if(this.factorsForm.controls.factorVaccinesCtrl.value) {
        factorVaccinesLocal =  map(this.factorsForm.controls.factorVaccinesCtrl.value, 'code');
        if (factorVaccinesLocal.every((value) => value === undefined)) {
          factorVaccinesLocal = map(this.factorsForm.controls.factorVaccinesCtrl.value);
        }
      }

      var factorUsualAndCustomaryLocal = [];
      if(this.factorsForm.controls.factorUsualAndCustomarysCtrl.value) {
        factorUsualAndCustomaryLocal =  map(this.factorsForm.controls.factorUsualAndCustomarysCtrl.value, 'code');
        if (factorUsualAndCustomaryLocal.every((value) => value === undefined)) {
          factorUsualAndCustomaryLocal = map(this.factorsForm.controls.factorUsualAndCustomarysCtrl.value);
        }
      }

      var factorBrandDawsLocal = [];
      if(this.transformedBrandDaws.values) {
        factorBrandDawsLocal = map(this.transformedBrandDaws, 'value');
        if (factorBrandDawsLocal.every((value) => value === undefined)) {
          factorBrandDawsLocal = map(this.transformedBrandDaws)
        }
      }

      var factorGenericDawsLocal = [];
      if(this.transformedGenericDaws.values) {
        factorGenericDawsLocal = map(this.transformedGenericDaws, 'value');
        if (factorGenericDawsLocal.every((value) => value === undefined)) {
          factorGenericDawsLocal = map(this.transformedGenericDaws)
        }
      }


      var valuesHaveNotChanged: boolean = (
        isExcludeCobLocal === (this.factorToEdit.isExcludedCOB ? this.factorToEdit.isExcludedCOB : false) &&
        isExcludeStateLocal === (this.factorToEdit.isExcludedState ? this.factorToEdit.isExcludedState : false) &&
        isExcludeStoreLocal === (this.factorToEdit.isExcludedStore ? this.factorToEdit.isExcludedStore : false) &&
        isExcludeRetailBannerLocal === (this.factorToEdit.isExcludedRetailBanner ? this.factorToEdit.isExcludedRetailBanner : false) &&
        isExcludeOtcLocal === (this.factorToEdit.isExcludedOTC ? this.factorToEdit.isExcludedOTC : false) &&
        isExcludeVaccineLocal === (this.factorToEdit.isExcludedVaccine ? this.factorToEdit.isExcludedVaccine : false) &&
        isExcludeInjectionsLocal === (this.factorToEdit.isExcludedInjections ? this.factorToEdit.isExcludedInjections : false) &&
        isExcludeUsualAndCustomaryLocal === (this.factorToEdit.isExcludedUsualAndCustomary ? this.factorToEdit.isExcludedUsualAndCustomary : false) &&
        isARZeroDollarExcludeLocal === (this.factorToEdit.isARZeroDollarExcluded ? this.factorToEdit.isARZeroDollarExcluded : false) &&
        hasZeroDollar === (this.factorToEdit.hasARZeroDollar ? this.factorToEdit.hasARZeroDollar : false) &&
        isExcludedDawBrandLocal === (this.factorToEdit.isExcludedDawBrand ? this.factorToEdit.isExcludedDawBrand : false) &&
        isExcludedDawGenericLocal === (this.factorToEdit.isExcludedDawGeneric ? this.factorToEdit.isExcludedDawGeneric : false) &&

        this.arraysAreEqual(factorsCobsLocal, this.factorToEdit.factorCOBs ? map(this.factorToEdit.factorCOBs, 'cobCode') : []) &&
        this.arraysAreEqual(factorsStatesLocal,this.factorToEdit.factorStates ? map(this.factorToEdit.factorStates, 'code') : []) &&
        this.arraysAreEqual(factorsStoresLocal, this.storeDestinationEditList ? Array.from(map(this.storeDestinationEditList, 'value')) : []) &&
        this.arraysAreEqual(factorsRetailBannersLocal, this.factorToEdit.factorRetailBanners ? map(this.factorToEdit.factorRetailBanners, 'retailBanner') : []) &&
        this.arraysAreEqual(factorsOtcsLocal, this.factorToEdit.factorOTCs ? map(this.factorToEdit.factorOTCs, 'otcId') : []) &&
        this.arraysAreEqual(factorInjectionsLocal, this.factorToEdit.factorInjections ? map(this.factorToEdit.factorInjections, 'code') : []) &&
        this.arraysAreEqual(factorVaccinesLocal, this.factorToEdit.factorVaccines ? map(this.factorToEdit.factorVaccines, 'code') : []) &&
        this.arraysAreEqual(factorUsualAndCustomaryLocal, this.factorToEdit.factorUsualAndCustomarys ? map(this.factorToEdit.factorUsualAndCustomarys, 'code') : []) &&
        this.arraysAreEqual(factorBrandDawsLocal, this.transformedBrandDawsEditList ? Array.from(map(this.transformedBrandDawsEditList, 'value')) : []) &&
        this.arraysAreEqual(factorGenericDawsLocal, this.transformedGenericDawsEditList ? Array.from(map(this.transformedGenericDawsEditList, 'value')) : [])
      );

      return valuesHaveNotChanged;
  }

  arraysAreEqual(array1: any[], array2: any[]): boolean {

    if (array1.length !== array2.length) {
      return false;
    }
    if (array1.every((value) => value === undefined)) {
      return true;
    }
    array2.sort((a,b) =>  (a > b ? 1 : -1));
    array1.sort((a,b) =>  (a > b ? 1 : -1));
    var equalArray: boolean = array1.every((value, index) => value === array2[index]);
    return equalArray;
  }
}
