import { HttpParams } from '@angular/common/http';

export class Pagination {
  constructor(
    public limit: number,
    public offset: number,
    public sort = '',
    public sortOrder?: string
  ) {}

  public buildSearchParams(): HttpParams {
    let params = new HttpParams();
    params = params.append('Limit', this.limit.toString());
    params = params.append('Offset', this.offset.toString());
    if (this.sort) {
      params = params.append('Sort', this.sort);
    }
    if (this.sortOrder) {
      params = params.append('SortOrder', this.sortOrder.toString());
    }
    return params;
  }

  getSortOrder(order: string): string {
    return order === 'desc' ? '-' : '';
  }
}
