import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { ContractDocument } from 'src/app/models/ContractDocument';
import { Pagination } from 'src/app/models/Pagination';
import { ContractDocumentService } from 'src/app/services/contractDocumentService';
import { parse as uuidParse, v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-documents-grid',
  templateUrl: './documents-grid.component.html',
  styleUrls: ['./documents-grid.component.less']
})
export class DocumentsGridComponent  implements OnInit {

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [];
  isLoadingResults = false;
  sortedData: any;
  local: ContractDocument[];
  Sort = 'contractPdfId';
  sortOrder = 'desc';
  offset = 0;
  limit = 30;
  pageIndex = 0;
  totalItemCount: number;
  contractId: number;
  selection = new SelectionModel<ContractDocument>(true, []);

  title: string;

  public currentToolbar = [];


  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @Output() EditOnClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() set data(value) {
    if (value == null || value == undefined) {
      return;
    }

    this.title = 'Contract Documents';
    this.contractId = +this.route.snapshot.paramMap.get('id');

    this.displayedColumns = ['contractId', 'contractPdfId', 'contractFileName', 'createdBy', 'createdDate', 'contractPdfGuid' ];
    this.sort.direction = "desc";
    this.sort.start = "desc";

    this.dataSource = new MatTableDataSource(value);
    this.totalItemCount = value.count;

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sort.sort({id: 'contractPdfId', start: 'desc', disableClear: false});

  }

  constructor(private contractDocumentService: ContractDocumentService,
    private route: ActivatedRoute){
  }

  ngOnInit() {
    this.contractId = +this.route.snapshot.paramMap.get('id');
  }

  loadContractDocuments(): void {
    if (!this.contractId) {
      return;
    }
    this.isLoadingResults = true;
    this.contractDocumentService.getContractDocumentsForContractId(
      new Pagination(this.limit, this.offset, this.Sort, this.sortOrder),
      this.contractId).subscribe(
        (results) => {
          this.totalItemCount = results.totalItemCount;
          this.local = results.items;
          this.dataSource = new MatTableDataSource(this.local);
          this.isLoadingResults = false;
        },
        (err) => {
          this.isLoadingResults = false;
          toastr.error('We are unable to load Contract Documents at this time');
        }
      )
  }

  onPageChange(event: PageEvent): void {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * event.pageSize;
    this.selection.clear();

    this.loadContractDocuments();
  }

  sortData(sortData: Sort) {
    this.Sort = sortData.active;
    this.sortOrder = sortData.direction;

    if (this.Sort === 'contractPdfId') {
      this.sortOrder = "desc";
    }
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.loadContractDocuments();
  }

  actionOnClick(element: any) {
    this.isLoadingResults = true;
    this.downloadFile(element.contractId, element.contractPdfGuid, element.contractFileName)
  }

  private downloadFile(contractId: number, contractPdfGuid: string, contractFilename: string) {
    this.contractDocumentService.getContractDocumentByGuid(contractId, contractPdfGuid, contractFilename)
      .subscribe((response: ArrayBuffer) => {
        var output = new Blob([response], { type: 'application/pdf'})
        saveAs(output, contractFilename)
        this.isLoadingResults = false;
      },
      (err) => {
        this.isLoadingResults = false;
        toastr.error(`Unable to download the Contract File: ${contractFilename}`)
      }
    )
  }
}
