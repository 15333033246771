import { ContractContractType } from "./ContractContractType";
import { ContractDetail } from "./ContractDetail";

export class ContractByIdResponse {
  contractId: number;
  industryContractTemplateId: number;
  contractStatusId: number;
  contractStatusDesc: string;
  clientId: number;
  payerGroupName: string;
  networkName: string;
  daysSupply: number;
  paymentTermDays: number;
  adminOrTransactionFee: number;
  parentContractId: number;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  createdDate: Date;
  createdBy: string;
  lastModifiedDate: Date;
  lastModifiedBy: string;
  contractContractTypes: ContractContractType[];
  // contractDetails: ContractDetails[] = [];
  trueUp: string;
  trueUpDate: Date;
  contractClassificationId: number;
  contractClassificationDesc: string;
}
