import { Component } from '@angular/core';

@Component({
  selector: 'app-my-contract-management',
  templateUrl: './my-contract-management.component.html',
  styleUrls: ['./my-contract-management.component.less']
})
export class MyContractManagementComponent {

}
