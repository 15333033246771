import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models/User';

@Injectable()
export class AuthorizationService {
  private healthcareTopBar: any;
  private CACHE_INTERVAL = 1000;
  private topBarLoaded = false;
  private features: any;
  private timer: any;

  private healthcareLoadedSubject = new Subject<void>();
  public healthcareLoadedSubject$ = this.healthcareLoadedSubject.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.timer = setInterval(() => {
      this.updateCache();
    }, this.CACHE_INTERVAL);
  }

  public setHealthcareTopBar(topBar: any): void {
    this.healthcareTopBar = topBar;
  }

  public get HealthcareTopBar(): any {
    return this.healthcareTopBar ? this.healthcareTopBar : null;
  }

  public get hasTopBarLoaded(): boolean {
    return this.topBarLoaded;
  }

  private updateCache(): boolean {
    if (!this.HealthcareTopBar || !this.HealthcareTopBar.currentUserDetails) {
      return false;
    }

    this.features = this.HealthcareTopBar.currentUserDetails.Features.filter(
      (obj) =>
        obj.App === environment.applicationNameContractManagement ||
        obj.App === environment.applicationNameContractManagement
    );
    this.topBarLoaded = true;
    this.healthcareLoadedSubject.next();
    clearTimeout(this.timer);
  }

  getSecured(routepath: string): void {
    const prepLink = `${environment.healthcareSignInAddress}?returnUrl=${environment.cmUrlRoot}`;
    window.location.href = prepLink;
  }

  // Do not bind this without using cache to the DOM
  hasFeature(featureName: string, useCache: boolean = false): boolean {
    let collection: any[];
    if (!this.HealthcareTopBar || !this.HealthcareTopBar.currentUserDetails) {
      return false;
    }

    if (useCache) {
      collection = this.features;
    } else {
      collection = this.HealthcareTopBar.currentUserDetails.Features;
    }

    let found = false;

    found = _.find(collection, (features) => {
      if (
        featureName === features.Name &&
        (features.App === environment.applicationNameContractManagement ||
          features.App === environment.applicationNameContractManagement)
      ) {
        return true;
      }
    });

    return found;
  }

  async CurrentUser(): Promise<User> {
    return await this.http
      .get<User>(environment.authZApiRoot + '/CurrentUser')
      .toPromise<User>();
  }

  GetLookerUrl(clientId: string, slugId: string): Observable<any> {
    const authheaders = new HttpHeaders();
    const jwt = this.cookieService.get('id_token');
    if (jwt) {
      authheaders.append('Authorization', 'Bearer ' + jwt);
      authheaders.append('Content-Type', 'application/json');
      authheaders.append('Access-Control-Allow-Origin', '*');
      authheaders.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    }

    return this.http.get<any>(`${environment.authZApiRoot}/LookerUrl/Clients/${clientId}/slugIds/${slugId}`, { headers: authheaders });
  }
}
