import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContractTypeService } from 'src/app/services/contractTypeService';
import { AddContractFromTemplateDialogData } from './add-contract-from-template-dialog-data';
import { ContractService } from 'src/app/services/contractService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-contract-from-template-dialog',
  templateUrl: './add-contract-from-template-dialog.component.html',
  styleUrls: ['./add-contract-from-template-dialog.component.less']
})
export class AddContractFromTemplateDialogComponent implements OnInit {

  templateForm: FormGroup;
  saveDisabled = true;
  editMode = true;
  isLoadingResults: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<AddContractFromTemplateDialogComponent>,
    private fb: FormBuilder,
    private contractService: ContractService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: AddContractFromTemplateDialogData
  ) { 
    if (this.data.action === 'add') {
      this.editMode = false;
    }
  }

  ngOnInit(): void {
    this.templateForm = this.fb.group({
      networkNameCtrl: ['',[
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(1)
      ]],
    });
  }

  saveContractFromTemplate() {
    this.isLoadingResults = true;
    this.contractService.createContractFromTemplate(this.data.template.industryContractTemplateId, this.templateForm.controls.networkNameCtrl.value)
      .subscribe({
        next: (result) => {
          this.isLoadingResults = false;
          this.router.navigate([`/cm/contracts/contractview/${result.contractId}/view`])
          this.dialogRef.close();
        },
        error: (err) => {
          toastr.error('We are unable to create contract from industry contract templates at this time.', err);
          this.isLoadingResults = false;
        }
      });
  }

      /**
   * Check if save is enabled
   */
  checkSaveEnabled(): void {
    this.saveDisabled =
      this.templateForm.pristine ||
      this.templateForm.invalid;
  }

  checkValuesChange(): boolean {
    throw new Error('Method not implemented.');
  }
}
